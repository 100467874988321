import { type FC, type SyntheticEvent, useState } from 'react';

import { Link } from 'react-router-dom';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import type { EmptyFunction } from '../../../models';

import usePathnameMatch from './use-pathname-match';
import useTabsConfig from './use-tabs-config';

interface NavigationTabsProps {
  pathname: string;
  onRefetch: EmptyFunction;
  superAdmin?: boolean;
}

const NavigationTabs: FC<NavigationTabsProps> = ({ pathname, superAdmin, onRefetch }) => {
  const [currentTab, setCurrentTab] = useState<string | boolean>(false);
  const tabsConfig = useTabsConfig(superAdmin || false);

  usePathnameMatch(pathname, setCurrentTab);

  const handleChange = (_event: SyntheticEvent, value: string): void => {
    if (value === '/home') {
      onRefetch();
    }

    setCurrentTab(value);
  };

  return (
    <Tabs value={currentTab || false} onChange={handleChange}>
      {tabsConfig.map(tabConfig => (
        <Tab {...tabConfig} key={tabConfig.label} component={Link} iconPosition="start" />
      ))}
    </Tabs>
  );
};

export default NavigationTabs;
