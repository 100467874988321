import { type FC, type ChangeEventHandler, useState, useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type {
  EmptyFunction,
  FooterElement,
  FunctionWithArg,
  User,
  UserProfileUpdatePayload,
} from '../../../models';

import useFileUploadInput from '../../../hooks/use-file-upload-input';
import { toBase64 } from '../../../utils/to-base-64';

import TextField from '../../common/form-fields/TextField';
import ButtonLoading from '../../common/buttons/ButtonLoading';
import UploadImage from '../../common/upload-image/UploadImage';
import DialogBase from '../base/DialogBase';

import {
  BadgeMembershipPrimary,
  BadgeMembership,
} from '../../../pages/super-admin/styled-components';

interface DialogEditProfileProps {
  user: User;
  avatarLetters: string;
  onConfirm: FunctionWithArg<UserProfileUpdatePayload>;
  onClose: EmptyFunction;
  isLoading: boolean;
  error: string;
}

const footer: FooterElement = options => {
  const { onConfirm, isDisabled, isLoading } = options;

  return (
    <ButtonLoading
      fullWidth
      size="large"
      onClick={onConfirm}
      disabled={isDisabled}
      isLoading={isLoading}
    >
      Save now
    </ButtonLoading>
  );
};

const membershipType = {
  BASIC: 'Basic',
  PRO: 'Pro',
};

const DialogEditProfile: FC<DialogEditProfileProps> = props => {
  const { user, avatarLetters, onConfirm, isLoading, error, onClose } = props;
  const { files, inputProps, openSelection, removeFiles, errorFiles } = useFileUploadInput();

  const [stateForm, setStateForm] = useState<UserProfileUpdatePayload>({
    fullname: user.fullname || '',
    email: user.email,
    description: user.description || '',
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setStateForm({
      ...stateForm,
      [target.name]: target.value,
    });
  };

  const isDisabled = useMemo(() => {
    return (
      isLoading ||
      (stateForm.fullname === user.fullname &&
        stateForm.email === user.email &&
        stateForm.description === user.description &&
        !files)
    );
  }, [isLoading, files, stateForm, user]);

  const handleConfirm = async () => {
    if (files) {
      onConfirm({
        ...stateForm,
        imageUrl: await toBase64(files),
      });

      removeFiles();
    } else {
      onConfirm(stateForm);
    }
  };

  return (
    <DialogBase
      footer={footer({
        onConfirm: handleConfirm,
        onClose,
        isLoading,
        isDisabled,
      })}
      onClose={onClose}
      error={error}
    >
      <Stack spacing={{ xs: '4px', md: 2 }} marginTop={{ xs: 2, md: 3 }}>
        <UploadImage
          src={user.imageUrl || ''}
          alt={`${user.fullname} avatar`}
          avatarLetters={avatarLetters}
          files={files}
          inputProps={inputProps}
          openSelection={openSelection}
          errorFiles={errorFiles}
        />
        <TextField
          label="Name"
          name="fullname"
          value={stateForm.fullname}
          onChange={handleChange}
        />
        <Box paddingLeft="13px">
          <Typography variant="body2" sx={{ fontSize: '0.75em' }}>
            Username
          </Typography>
          @{user.username}
        </Box>
        <TextField
          label="Email address"
          name="email"
          value={stateForm.email}
          onChange={handleChange}
        />
        <TextField
          label="Description"
          name="description"
          value={stateForm.description}
          onChange={handleChange}
          multiline
          rows={3}
        />
        <div>
          <Typography paddingTop="8px">Membership type</Typography>
          <span>
            {user.membershipType === 'PRO' ? (
              <BadgeMembershipPrimary>{membershipType[user.membershipType]}</BadgeMembershipPrimary>
            ) : (
              <BadgeMembership>{membershipType[user.membershipType]}</BadgeMembership>
            )}
          </span>
        </div>
      </Stack>
    </DialogBase>
  );
};

export default DialogEditProfile;
