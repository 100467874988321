import { styled } from '@mui/material/styles';
import { common } from '@mui/material/colors';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';

import type { PaginatedResponseData, SearchItem } from '../../../models';

import { FlexContainer } from '../styled-components/containers';
import { alignItemsCenter } from '../styled-components/primitives';

export const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  padding: '10px 10px 10px 12px',
  border: `1px solid ${theme.palette.textLight.light2}`,
  borderRadius: '8px',
  backgroundColor: '#f5f6f7',
  transition: theme.transitions.create(['border-color']),

  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
    backgroundColor: common.white,
    '.search-icon': {
      color: theme.palette.primary.main,
    },

    input: {
      backgroundColor: common.white,
    },
  },

  [theme.breakpoints.up('md')]: {
    padding: '5px 8px 5px 12px',
  },
}));

export const StyledAutocomplete = styled(Autocomplete<PaginatedResponseData<SearchItem>>)(
  ({ theme }) => ({
    width: '100%',
    zIndex: theme.zIndex.drawer + 2,
    position: 'relative',

    '& input': {
      backgroundColor: '#f5f6f7',
      color: theme.palette.getContrastText('#fcedf2'),
    },
  }),
);

/**
 * @TODO Fix error. It is not critical and doesn't break anything
 *
 * React does not recognize the `listboxShiftX` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `listboxshiftx` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
 */
export const StyledPopperComponent = styled(PopperUnstyled)`
  background-color: ${common.white};
  position: fixed !important;

  &.MuiAutocomplete-popper {
    width: 100% !important;
  }

  & .${autocompleteClasses.paper} {
    box-shadow: none;
    margin: 0;
    color: inherit;
    border-radius: 0;
  }

  .${autocompleteClasses.listbox} {
    width: 390px;

    &.${autocompleteClasses.option} {
      padding: 10px 15px;
      overflow: visible;
    }
  }

  .MuiPickersArrowSwitcher-root {
    opacity: 1 !important;
    transition: none !important;
  }
`;

export const StyledCard = styled(Card)(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',

  '&::after': {
    content: "''",
    position: 'absolute',
    right: 0,
    bottom: '-10px',
    left: 0,
    height: '1px',
    backgroundColor: theme.palette.textLight.light2,
  },
}));

export const StyledCardActions = styled(CardActions)`
  padding: 0;

  button {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: 0;
  margin-left: 1em;
  flex-grow: 1;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const MuiAutocompleteOption = styled('li')(
  ({ theme }) => `
  &.MuiAutocomplete-option {
    padding: 10px 15px;
    display: block;
  }

  &.MuiAutocomplete-option.Mui-focused {
    background-color: ${theme.palette.background.default};
    border-radius: 8px;
  }
`,
);

export const UsernameDateTimeSeparator = styled('div')(({ theme }) => ({
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  margin: '0 6px',
}));

export const UsernameDateTimeContainer = styled(FlexContainer)`
  ${alignItemsCenter}
`;

export const SearchFieldMobileDrawer = styled(Drawer)(({ theme }) => ({
  [`.${drawerClasses.paper}`]: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  [`.${autocompleteClasses.popper}`]: {
    position: 'static !important',
    transform: 'unset !important',
  },
}));

export const TypeFilterContainer = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: '4px',
  padding: '0 10px 0 0',
  height: '32px',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.textLight.light2,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '25px',

  '.MuiCheckbox-root': {
    color: theme.palette.primary.main,
  },

  '&.checked': {
    color: common.white,
    backgroundColor: theme.palette.primary.main,

    '.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root': {
      fill: common.white,
    },
  },
}));

export const AccordionStyled = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',

  '&.Mui-expanded': {
    marginTop: '0',
    boxShadow: `0 0 0 1px ${theme.palette.textLight.light2}`,

    '&:last-of-type': {
      marginBottom: '8px',
    },
  },

  '&:last-of-type, &:first-of-type': {
    borderRadius: '8px',
  },

  '&::before': {
    display: 'none',
  },

  '.MuiAccordionSummary-content': {
    margin: '8px 0',

    '&.Mui-expanded': {
      margin: 'auto',
    },
  },

  '.MuiAccordionSummary-root': {
    minHeight: '40px',

    '&.Mui-expanded': {
      minHeight: '40px',
    },
  },

  '.MuiAccordionDetails-root': {
    padding: 0,
  },

  '.MuiCalendarOrClockPicker-root > div': {
    width: '230px',
    margin: '0 0 0 14px',
  },

  '.MuiCalendarPicker-root': {
    width: '230px',
    maxHeight: '236px',
    margin: '0',
  },

  '.MuiPickerStaticWrapper-content': {
    minWidth: '230px',
    background: 'transparent',
  },

  '.MuiDayPicker-header, .MuiDayPicker-weekContainer': {
    justifyContent: 'flex-start',
  },

  '.MuiPickersCalendarHeader-root': {
    padding: '0 0 0 4px',
    margin: '0 0 6px',
  },

  '.MuiPickersArrowSwitcher-button, .MuiPickersCalendarHeader-switchViewButton': {
    backgroundColor: 'transparent',
  },

  '.MuiPickersArrowSwitcher-spacer': {
    width: '8px',
  },

  '.MuiPickersDay-root': {
    margin: '2px 4px',
    width: '24px',
    height: '24px',
    fontSize: '1em',
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: common.white,
    },
  },

  '.MuiDayPicker-weekDayLabel': {
    width: '28px',
    height: '24px',
    fontSize: '1em',
    color: theme.palette.text.primary,
  },

  '.MuiYearPicker-root': {
    maxHeight: '200px',
  },

  '.PrivatePickersSlideTransition-root': {
    minHeight: '175px',
  },

  '.PrivatePickersYear-yearButton': {
    margin: '4px 0',
    height: '30px',
    width: '48px',
  },

  '.MuiInputBase-root': {
    borderColor: theme.palette.textLight.light1,
  },

  '.MuiSelect-select': {
    paddingTop: '5px',
    color: theme.palette.text.secondary,
  },

  '.MuiFormControl-root': {
    '.MuiFormLabel-root': {
      transform: 'translate(11px, 5px) scale(1)',
    },

    '&.text-filled ': {
      '.MuiFormLabel-root': {
        transform: 'scale(0)',
      },
    },
  },

  '.MuiSelect-icon': {
    fontSize: '1.25em',
    color: theme.palette.text.secondary,
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '250px',
  },
}));
