import type { FC } from 'react';

import format from 'date-fns/format';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import useGetTweetLink from '../../hooks/use-get-tweet-link';
import useMediaFieldsHandleProfileFeedItem from '../../hooks/use-media-fields-handle-profile-feed-item';

import Settings from '../../constants/settings';
import { urlify } from '../../utils/urlify';

import type { EmptyFunction, FunctionWithArg, Tweet } from '../../models';

import ButtonIcon from '../../components/common/buttons/ButtonIcon';
import { FlexContainer } from '../../components/common/styled-components/containers';
import HandleProfileFeedItemMedia from '../handle-profile/HandleProfileFeedItemMedia';

import { ProfileDateTimeContainer, ProfileDateTimeSeparator } from '../styled-components';
import {
  HandleProfilePageActionContainer,
  HandleProfilePageDivider,
  HandleProfilePageIconContainer,
  HandleProfilePageInfoContainer,
  HandleProfilePageTextContainer,
  HandleProfilePageTweetContainer,
  HandleProfilePageTweetInfoContainer,
  HandleProfilePageTweetTypeContainer,
} from '../handle-profile/styled-components';

export interface FeedItemCommonProps {
  data: Array<Tweet>;
  author: string;
  handleName: string;
  avatarSrc: string;
  onOpenExportDialog: FunctionWithArg<Tweet>;
  onOpenFolderDialog: EmptyFunction;
}

interface FeedItemProps extends Omit<FeedItemCommonProps, 'data'> {
  data: Tweet;
  relatedFeed?: boolean;
}

const tweetCssClasses = {
  tweet: 'handle-tweet',
  quoted: 'handle-tweet',
  'quoted,replied_to': 'handle-tweet',
  replied_to: 'handle-reply',
  retweeted: 'handle-retweet',
};

const tweetText = {
  tweet: 'Posted',
  quoted: 'Posted',
  'quoted,replied_to': 'Posted',
  replied_to: 'Replied',
  retweeted: 'Retweeted',
};

const FeedItem: FC<FeedItemProps> = props => {
  const {
    data,
    author,
    handleName,
    avatarSrc,
    relatedFeed,
    onOpenExportDialog,
    // @TODO uncomment when BE is ready
    // onOpenFolderDialog,
  } = props;
  const { tweetFieldsModelDTO, mediaFieldsModelDTO } = data;
  const mediaFields = useMediaFieldsHandleProfileFeedItem(tweetFieldsModelDTO, mediaFieldsModelDTO);
  const tweetLink = useGetTweetLink(author, tweetFieldsModelDTO.conversationId);

  const handleOpenDialog = (): void => {
    onOpenExportDialog(data);
  };

  return (
    <HandleProfilePageInfoContainer>
      <HandleProfilePageTweetTypeContainer>
        <HandleProfilePageIconContainer className="handle-icon-container">
          <Avatar alt={`${handleName} avatar`} src={avatarSrc} />
        </HandleProfilePageIconContainer>

        {relatedFeed && <HandleProfilePageDivider orientation="vertical" />}
      </HandleProfilePageTweetTypeContainer>

      <HandleProfilePageTweetContainer>
        <HandleProfilePageActionContainer>
          <HandleProfilePageTweetInfoContainer>
            <ProfileDateTimeContainer>
              <Typography className="handle-text-container" noWrap>
                {author}
              </Typography>

              <ProfileDateTimeSeparator />

              <Typography variant="body1" sx={{ fontSize: '12px' }}>
                {format(new Date(Number(tweetFieldsModelDTO.createdAt)), Settings.DATE_SHORT)}
              </Typography>

              <ProfileDateTimeSeparator />

              <Typography variant="body1" sx={{ fontSize: '12px', flexShrink: '0' }}>
                {format(new Date(Number(tweetFieldsModelDTO.createdAt)), Settings.TIME_AM_PM)}
              </Typography>
            </ProfileDateTimeContainer>

            <ProfileDateTimeContainer>
              <Typography
                variant="body2"
                sx={{ fontSize: '12px' }}
                className={tweetCssClasses[tweetFieldsModelDTO.referencedTweets.type || 'tweet']}
              >
                {tweetText[tweetFieldsModelDTO.referencedTweets.type || 'tweet']} on Twitter
              </Typography>

              <ProfileDateTimeSeparator />

              <Typography variant="body2" sx={{ fontSize: '12px' }}>
                <Link
                  href={tweetLink}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                  sx={{ fontSize: '12px' }}
                >
                  {tweetFieldsModelDTO.conversationId}
                </Link>
              </Typography>
            </ProfileDateTimeContainer>
          </HandleProfilePageTweetInfoContainer>

          <FlexContainer>
            {/* @TODO uncomment when BE is ready
            <ButtonIcon color="primary" onClick={onOpenFolderDialog}>
              <FolderOpenIcon />
            </ButtonIcon> */}

            <ButtonIcon color="primary" onClick={handleOpenDialog}>
              <FileDownloadOutlinedIcon />
            </ButtonIcon>
          </FlexContainer>
        </HandleProfilePageActionContainer>

        <HandleProfilePageTextContainer variant="body1">
          {urlify(tweetFieldsModelDTO.text)}
        </HandleProfilePageTextContainer>

        <HandleProfileFeedItemMedia data={mediaFields} />
      </HandleProfilePageTweetContainer>
    </HandleProfilePageInfoContainer>
  );
};

export default FeedItem;
