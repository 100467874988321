import {
  type FC,
  type ChangeEventHandler,
  type MouseEventHandler,
  type MutableRefObject,
  type FocusEventHandler,
  type SyntheticEvent,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';

import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';

import type {
  PaginatedResponseData,
  SearchItem,
  FunctionWithArg,
  EmptyFunction,
  ExpandedPanel,
} from '../../../models';

import ButtonClose from '../buttons/ButtonClose';

import RenderOption from './RenderOption';
import PopperComponent from './PopperComponent';
import DateTimeFilter from './DateTimeFilter';
import TypeFilter from './TypeFilter';
import { StyledInput, StyledAutocomplete } from './styled-components';

export interface SearchFieldV2Props {
  options: PaginatedResponseData<SearchItem>;
  isOpen: boolean;
  onOpen: FunctionWithArg<boolean>;
  onChangeDateRange: FunctionWithArg<number | undefined>;
  onChangeFilter: FunctionWithArg<Array<string>>;
  anchorElRef?: MutableRefObject<HTMLDivElement | null>;
  onSearch?: FunctionWithArg<string>;
  dateRange?: number;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: EmptyFunction;
}

const SEARCH_DELAY = 1000;

const startAdornment = (
  <InputAdornment position="start">
    <SearchIcon aria-label="search icon" className="search-icon" />
  </InputAdornment>
);

const SearchFieldV2: FC<SearchFieldV2Props> = props => {
  const {
    options,
    isOpen,
    onOpen,
    onChangeDateRange,
    dateRange,
    onChangeFilter,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    onSearch,
    anchorElRef,
  } = props;

  const container = useRef<HTMLDivElement | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [expandedPanel, setExpandedPanel] = useState<ExpandedPanel>(false);

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
    }
  }, [isOpen]);

  const handleFetchNextPage = (): void => {
    fetchNextPage && fetchNextPage();
  };

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      setInputValue(target.value);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        if (target.value.length > 0) {
          onOpen(true);
        }

        if (onSearch) {
          onSearch(target.value);
        }
      }, SEARCH_DELAY);
    },
    [onOpen, onSearch],
  );

  const handleFocus: FocusEventHandler = useCallback(() => {
    onOpen(true);
  }, [onOpen]);

  const handleClickClose: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    setInputValue('');
    onOpen(false);
    onChangeDateRange(undefined);

    if (onSearch) {
      onSearch('');
    }
  };

  const handleExpandPanel =
    (panel: ExpandedPanel) => (_event: SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : false);
    };

  const renderCloseButton =
    inputValue.length > 0 ? (
      <InputAdornment position="end">
        <ButtonClose size="xsmall" onClick={handleClickClose} sx={{ marginRight: 0 }} />
      </InputAdornment>
    ) : null;

  return (
    <div id="wrapper" ref={container}>
      <StyledAutocomplete
        id="searchAutocompleteInput"
        disableCloseOnSelect
        open={isOpen}
        options={options.pages ? [options] : []}
        renderInput={params => (
          <StyledInput
            fullWidth
            placeholder="Search"
            inputRef={params.InputProps.ref}
            inputProps={{
              ...params.inputProps,
              onChange: handleChangeInput,
              onFocus: handleFocus,
              value: inputValue,
            }}
            startAdornment={startAdornment}
            endAdornment={renderCloseButton}
          />
        )}
        renderOption={(props, renderOptions) => (
          <RenderOption
            key="render-option-wrapper"
            options={renderOptions}
            renderProps={props}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onFetchNextPage={handleFetchNextPage}
          />
        )}
        clearOnBlur={false}
        renderTags={() => null}
        getOptionLabel={() => inputValue}
        PopperComponent={props => (
          <PopperComponent
            {...props}
            anchorEl={anchorElRef?.current || container.current}
            container={container.current}
            dateTimeFilterElement={
              <DateTimeFilter
                onChangeDateTime={onChangeDateRange}
                dateRange={dateRange}
                expandedPanel={expandedPanel}
                onExpandPanel={handleExpandPanel}
              />
            }
            typeFilterElement={
              <TypeFilter
                onSetType={onChangeFilter}
                expandedPanel={expandedPanel}
                onExpandPanel={handleExpandPanel}
              />
            }
          />
        )}
      />
    </div>
  );
};

export default SearchFieldV2;
