import httpBuilder, { headersAuthorization } from '../configs/axios';
import catchError from '../configs/catch-errors';
import { ApiTweets } from '../constants/api';

import Settings from '../constants/settings';

import type {
  AddNewRule,
  Handle,
  PinUnpinPayload,
  HandleDeletePayload,
  HandlesByCompanyIdResponse,
  FeedResponse,
  GetAllTweetsByHandleIdOptions,
  GetFeedEntityIdOptions,
  GetFulltextSearchOptions,
} from '../models';

export const getAllTweetsByHandleId = (options: GetAllTweetsByHandleIdOptions) => {
  const {
    handleId,
    isStockTwits,
    tickerId,
    pageNumber = Settings.DEFAULT_PAGE_NUMBER,
    resultNumber = Settings.DEFAULT_RESULT_NUMBER,
    jwt,
  } = options;

  if (isStockTwits) {
    return httpBuilder
      .get<HandlesByCompanyIdResponse>(ApiTweets.tweetsByHandleId(handleId), {
        params: {
          isStockTwit: true,
          tickerId,

          stockTwitPageNumber: pageNumber,
          stockTwitResultNumber: resultNumber,
        },
        ...headersAuthorization(jwt),
      })
      .then(response => response.data)
      .catch(catchError);
  }

  return httpBuilder
    .get<HandlesByCompanyIdResponse>(ApiTweets.tweetsByHandleId(handleId), {
      params: {
        tweetPageNumber: pageNumber,
        tweetResultNumber: resultNumber,
      },
      ...headersAuthorization(jwt),
    })
    .then(response => response.data);
};

export const addRule = (data: AddNewRule, jwt: string) => {
  return httpBuilder
    .post(ApiTweets.rules, data, headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const getSearchHandles = (id: string, query: string, jwt: string) => {
  return httpBuilder
    .get<Array<Handle>>(ApiTweets.search, {
      params: {
        id,
        searchString: query,
      },
      ...headersAuthorization(jwt),
    })
    .then(response => response.data);
};

export const pinUnpin = (data: PinUnpinPayload, jwt: string) => {
  return httpBuilder
    .post(ApiTweets.pinUnpin, data, headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const deleteHandle = (data: HandleDeletePayload, jwt: string) => {
  return httpBuilder
    .delete(ApiTweets.handles, {
      params: {
        ...data,
      },
      ...headersAuthorization(jwt),
    })
    .then(response => response.data)
    .catch(catchError);
};

export const getRecentHandlesByEntityId = (id: string, jwt: string) => {
  return httpBuilder
    .get<Array<Handle>>(ApiTweets.recentHandlesByEntityId(id), headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const getRecentPinnedHandlesByEntityId = (id: string, jwt: string) => {
  return httpBuilder
    .get<Array<Handle>>(ApiTweets.recentPinnedHandlesByEntityId(id), headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const getHandlesWithoutTwitsByEntityId = (id: string, jwt: string) => {
  return httpBuilder
    .get<Array<Handle>>(ApiTweets.handlesWithoutTwitsByEntityId(id), headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const getFeedEntityId = (options: GetFeedEntityIdOptions) => {
  const {
    id,
    jwt,
    pageNumber = Settings.DEFAULT_PAGE_NUMBER,
    resultNumber = Settings.DEFAULT_RESULT_NUMBER,
  } = options;

  return httpBuilder
    .get<FeedResponse>(ApiTweets.feedByEntityId(id), {
      params: {
        feedPageNumber: pageNumber,
        feedResultNumber: resultNumber,
      },
      ...headersAuthorization(jwt),
    })
    .then(response => response.data)
    .catch(catchError);
};

export const getFulltextSearch = (options: GetFulltextSearchOptions) => {
  const {
    jwt,
    keyword,
    timestamp,
    adminSearch,
    pageNumber = Settings.DEFAULT_PAGE_NUMBER,
    resultNumber = Settings.DEFAULT_RESULT_NUMBER,
  } = options;

  return httpBuilder
    .get<FeedResponse>(ApiTweets.fulltextSearch, {
      params: {
        keyword,
        adminSearch,
        timestamp,
        ignoreTimestamp: timestamp ? false : true,

        feedPageNumber: pageNumber,
        feedResultNumber: resultNumber,

        stockTwitPageNumber: pageNumber,
        stockTwitResultNumber: resultNumber,
      },
      ...headersAuthorization(jwt),
    })
    .then(response => response.data)
    .catch(catchError);
};
