import type { AxiosError } from 'axios';

import { useQuery, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { getUsers, getUserById, updateUserById, deleteUserById } from '../api/users';

import type { User, UserProfileUpdatePayload } from '../models';

export const useQueryUsers = (jwt: string) => {
  return useQuery<Array<User>, AxiosError>(['all-users'], () => getUsers(jwt));
};

export const useQueryUserById = (id: string, jwt: string) => {
  return useQuery(['user-id', id], () => getUserById(id, jwt));
};

export const useMutationUpdateUser = (jwt: string): UseMutationResult<void | User, AxiosError> => {
  const queryClient = useQueryClient();

  return useMutation(
    (
      data: any & {
        id: string;
      },
    ) => {
      const { id, ...restData } = data;

      return updateUserById(id, restData, jwt);
    },
    {
      onSuccess: (_d, variables, _c) => {
        queryClient.refetchQueries(['all-users']);
        queryClient.refetchQueries(['user-id', variables.id]);
        queryClient.refetchQueries(['entity-info', variables.id, 'USER']);
      },
    },
  );
};

export const useMutationUsersUpdateFromTable = (
  jwt: string,
): UseMutationResult<Array<unknown>, AxiosError, { selectedRows: Array<string>; payload: any }> => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { selectedRows: Array<string>; payload: any }) => {
      const updateRequests = new Set();

      data.selectedRows.forEach(id => {
        updateRequests.add(updateUserById(id, data.payload, jwt));
      });

      return Promise.all(Array.from(updateRequests));
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['all-users']);
      },
    },
  );
};

export const useMutationUpdateUserProfile = (
  jwt: string,
): UseMutationResult<
  void | User,
  AxiosError,
  UserProfileUpdatePayload & { userId: string; companyId: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UserProfileUpdatePayload & { userId: string; companyId: string }) => {
      const { userId } = data;

      return updateUserById(userId, data, jwt);
    },
    {
      onSuccess: (_d, variables, _c) => {
        queryClient.refetchQueries(['all-users']);
        queryClient.refetchQueries(['user-id', variables.userId]);
        queryClient.refetchQueries(['entity-info', variables.userId, 'USER']);

        if (variables.companyId) {
          queryClient.refetchQueries(['entity-info', variables.companyId, 'COMPANY']);
        }
      },
    },
  );
};

export const useMutationUsersDeleteFromTable = (
  jwt: string,
): UseMutationResult<Array<void | User>, AxiosError, Array<string>> => {
  const queryClient = useQueryClient();

  return useMutation(
    (ids: Array<string>) => {
      const deleteRequests = ids.map(id => deleteUserById(id, jwt));

      return Promise.all(deleteRequests);
    },
    {
      onSuccess: (_d, variables, _c) => {
        variables.forEach(id => {
          queryClient.removeQueries(['user-id', id]);
        });
        queryClient.refetchQueries(['all-users']);
        queryClient.refetchQueries(['all-company']);
      },
    },
  );
};
