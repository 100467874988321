import type { FC } from 'react';

import InputAdornment from '@mui/material/InputAdornment';

import TextField, { type TextFieldProps } from './TextField';

const TextFieldTwitterId: FC<TextFieldProps> = props => {
  const { value, onChange, ...restProps } = props;

  return (
    <TextField
      {...restProps}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: <InputAdornment position="start">@</InputAdornment>,
      }}
    />
  );
};

export default TextFieldTwitterId;
