import type { FC } from 'react';

import Avatar from '@mui/material/Avatar';

import type { User } from '../../../models';

import { CustomAvatarGroup } from './styled-components';

interface GroupAvatarsProps {
  size: 'small' | 'large';
  usersList: Array<User>;
}

const GroupAvatars: FC<GroupAvatarsProps> = props => {
  const { size, usersList } = props;

  if (usersList.length) {
    const max = size === 'small' ? 6 : 12;

    return (
      <CustomAvatarGroup>
        {usersList.slice(0, max).map((user, userIndex) => (
          <Avatar key={`Avatar-${userIndex}`} alt={user.username} src={user.imageUrl || ''} />
        ))}

        {usersList.length > max ? (
          <Avatar alt="rest of users">{usersList.length - max}</Avatar>
        ) : null}
      </CustomAvatarGroup>
    );
  }

  return null;
};

export default GroupAvatars;
