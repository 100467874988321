import type { FC } from 'react';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const DividerOr: FC = () => {
  return (
    <Divider>
      <Typography variant="body2">or</Typography>
    </Divider>
  );
};

export default DividerOr;
