import { type FC, useState } from 'react';

import { useLocation } from 'react-router-dom';

import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/system/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import type { ZIndex } from '@mui/material/styles/zIndex';

import SearchIcon from '@mui/icons-material/Search';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';

import type {
  EmptyFunction,
  FunctionWithArg,
  Handle,
  PaginatedResponseData,
  SearchItem,
} from '../../../models';

import { Routes } from '../../../constants/routes';

import useFilteredHandlesMobile from '../../../hooks/use-filtered-handles-mobile';

import SearchFieldMobile from '../search-field/SearchFieldMobile';
import DialogAddNewHandles from '../../dialogs/add-new-handles/DialogAddNewHandles';
import TweeterHandlesSidebarComponent from '../tweeter-handles-sidebar/TweeterHandlesSidebar';
import NavigationTabs from './NavigationTabs';
import NavigationMobile from './NavigationMobile';

import { NavigationContainer } from './styled-components';
import { ButtonAddHandles, ButtonAddHandlesMobile } from './TooltipWithButton';

interface NavigationPanelProps {
  profileNickname: string;
  companyName: string;
  entityId: string;
  email: string;
  imageUrl: string;
  onRefetch: EmptyFunction;
  profileClick: EmptyFunction;
  handles: Array<Handle>;
  jwt: string;
  options: PaginatedResponseData<SearchItem>;
  onChangeDateRange: FunctionWithArg<number | undefined>;
  onChangeFilter: FunctionWithArg<Array<string>>;
  disableAddNewHandlesButton?: boolean;
  proBadge?: boolean;
  adminBadge?: boolean;
  superAdmin?: boolean;
  onSearch?: FunctionWithArg<string>;
  onCloseSearch?: EmptyFunction;
  dateRange?: number;
}

export interface onConfirmDialogData {
  handleName: string;
  itemId: string;
}

const NavigationPanel: FC<NavigationPanelProps> = props => {
  const {
    profileNickname,
    proBadge,
    adminBadge,
    superAdmin,
    companyName,
    entityId,
    email,
    imageUrl,
    onRefetch,
    profileClick,
    handles,
    jwt,
    disableAddNewHandlesButton,
    onChangeDateRange,
    onChangeFilter,
    onSearch,
    options,
    onCloseSearch,
    dateRange,
  } = props;

  const [addNewHandlessDialog, setAddNewHandlessDialog] = useState(false);
  const [addNewHandlessDialogMobile, setAddNewHandlessDialogMobile] = useState(false);
  const [openSearchField, setOpenSearchField] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const smallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const handlesWithoutCurrentHandle = useFilteredHandlesMobile(handles);

  const toggleSidebar = (newOpen: boolean) => {
    setOpenSidebar(newOpen);
  };
  const tooltipLimit = (
    <Typography color="inherit">
      You have reached your limit of new profiles, please contact <u>alfonso@blocks.io</u> to be
      upgraded
    </Typography>
  );

  const onOpenDialog = (): void => {
    setAddNewHandlessDialog(true);
  };

  const onCloseDialog = (): void => {
    setAddNewHandlessDialog(false);
  };

  const onOpenDialogMobile = (): void => {
    setAddNewHandlessDialogMobile(true);
  };

  const onCloseDialogMolibe = (): void => {
    setAddNewHandlessDialogMobile(false);
  };

  const handleOpenSearch = (): void => {
    setOpenSearchField(true);
  };

  const handleCloseViaDrawer = (): void => {
    setOpenSearchField(false);

    if (onCloseSearch) {
      onCloseSearch();
    }
  };

  const renderNavigation = (
    <Box
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
        zIndex: theme => (theme.zIndex as ZIndex).drawer + 100,
        position: 'fixed',
        left: 0,
        right: 0,
        top: '66px',
      }}
    >
      <NavigationContainer>
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="center">
            <NavigationTabs
              pathname={location.pathname}
              superAdmin={superAdmin}
              onRefetch={onRefetch}
            />

            <ButtonAddHandles
              tooltipText={tooltipLimit}
              isDisabled={disableAddNewHandlesButton}
              onOpenDialog={onOpenDialog}
              superAdmin={superAdmin}
            />

            {addNewHandlessDialog ? (
              <DialogAddNewHandles
                onClose={onCloseDialog}
                profileNickname={profileNickname}
                companyName={companyName}
                entityId={entityId}
                jwt={jwt}
              />
            ) : null}
          </Grid>
        </Container>
      </NavigationContainer>
    </Box>
  );

  const renderMobileNavigation = (
    <Box
      sx={{
        display: {
          xs: 'block',
          md: 'none',
        },
        zIndex: theme => (theme.zIndex as ZIndex).drawer + 100,
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
      }}
    >
      <NavigationContainer>
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            <NavigationMobile
              profileNickname={profileNickname}
              profileClick={profileClick}
              companyName={companyName}
              email={email}
              imageUrl={imageUrl}
              proBadge={proBadge}
              adminBadge={adminBadge}
              superAdmin={superAdmin}
            />

            <Stack spacing={3} direction="row">
              {location.state?.routeName === Routes.handleById.routeName ||
              location.state?.routeName === Routes.home.routeName ? (
                <IconButton
                  color="primary"
                  onClick={() => toggleSidebar(true)}
                  disabled={handlesWithoutCurrentHandle.length === 0}
                >
                  <Groups3OutlinedIcon fontSize="small" />
                </IconButton>
              ) : null}

              <ButtonAddHandlesMobile
                tooltipText={tooltipLimit}
                isDisabled={disableAddNewHandlesButton}
                onOpenDialog={onOpenDialogMobile}
                superAdmin={superAdmin}
              />

              <IconButton color="default" onClick={handleOpenSearch}>
                <SearchIcon fontSize="small" />
              </IconButton>
            </Stack>

            {smallScreens &&
            openSidebar &&
            handlesWithoutCurrentHandle.length &&
            (location.state?.routeName === Routes.handleById.routeName ||
              location.state?.routeName === Routes.home.routeName) ? (
              <TweeterHandlesSidebarComponent
                hideEntityInfo
                openSidebar={openSidebar}
                toggleDrawer={toggleSidebar}
                handles={handlesWithoutCurrentHandle}
                entityId={entityId}
                companyNickname={profileNickname}
                companyName={companyName}
                imageUrl={imageUrl || ''}
                jwt={jwt}
              />
            ) : null}

            {addNewHandlessDialogMobile ? (
              <DialogAddNewHandles
                onClose={onCloseDialogMolibe}
                profileNickname={profileNickname}
                companyName={companyName}
                entityId={entityId}
                jwt={jwt}
              />
            ) : null}
          </Grid>
        </Container>
      </NavigationContainer>

      <SearchFieldMobile
        options={options}
        isOpen={openSearchField}
        onOpen={setOpenSearchField}
        onCloseViaDrawer={handleCloseViaDrawer}
        onSearch={onSearch}
        onChangeDateRange={onChangeDateRange}
        dateRange={dateRange}
        onChangeFilter={onChangeFilter}
      />
    </Box>
  );

  return (
    <>
      {renderNavigation}
      {renderMobileNavigation}
    </>
  );
};

export default NavigationPanel;
