import type { FC } from 'react';
import format from 'date-fns/format';

import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';

import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';

import type { EmptyFunction, FunctionWithArg } from '../../models';
import { Handle } from '../../models/handle.model';

import Settings from '../../constants/settings';

import Button from '../../components/common/buttons/Button';
import IconsHandleWithColors from '../../components/common/icons-handle/IconsHandleWithColors';

import {
  StatsContainer,
  ProfilePageBoxContainer,
  ProfilePagePaperContainer,
  ProfilePageUserContainer,
  ProfilePageUserInfoContainer,
  ProfilePageDescriptionContainer,
} from '../styled-components';

import { HandleProfilePageButtonsContainer } from './styled-components';

interface HandleProfileCardProps {
  data: Handle;
  onOpenDialogDeleteHandle: EmptyFunction;
  onPinUnpin: FunctionWithArg<Handle>;
}

const HandleProfileCard: FC<HandleProfileCardProps> = props => {
  const { data, onOpenDialogDeleteHandle, onPinUnpin } = props;

  const handlePinUnpin = (): void => {
    onPinUnpin(data);
  };

  const joinedDate = format(
    new Date(Number(data.createdDate)),
    Settings.DATE_DAY_ORDER_MONTH_SHORT,
  );

  return (
    <ProfilePagePaperContainer>
      <ProfilePageBoxContainer>
        <Stack>
          <ProfilePageUserContainer>
            <Badge color="success" badgeContent={0}>
              <Avatar
                alt={`${data.tweetHandleName} avatar`}
                src={data.profileImageUrl || ''}
                sx={{
                  width: '60px',
                  height: '60px',
                }}
              />
            </Badge>

            <ProfilePageUserInfoContainer>
              <Typography variant="h4" sx={{ wordBreak: 'break-word' }}>
                {data.tweetHandleName}
              </Typography>
              <Typography variant="body2" noWrap>
                {data.stockTwits ? `#${data.username}` : `@${data.username}`}
              </Typography>
            </ProfilePageUserInfoContainer>
          </ProfilePageUserContainer>

          <ProfilePageDescriptionContainer variant="body2">
            {data.userDescription}
          </ProfilePageDescriptionContainer>

          <StatsContainer>
            <IconsHandleWithColors
              unread={Number(data.tweetNumber)}
              published={Number(data.retweetNumber)}
              chat={Number(data.replyNumber)}
            />
          </StatsContainer>

          {/* TODO Social icons
          <HandleProfileSocialIcons
            entityId={entityId}
            jwt={jwt}
            companyName={companyName}
            companyNickname={companyNickname}
          /> */}

          <HandleProfilePageButtonsContainer>
            <Button
              fullWidth
              startIcon={data.pinned ? null : <PushPinOutlinedIcon fontSize="small" />}
              onClick={handlePinUnpin}
            >
              {data.pinned ? 'Unpin for favorite' : 'Pin for favorite'}
            </Button>

            <Button
              fullWidth
              variant="outlined"
              color="error"
              startIcon={<PersonRemoveOutlinedIcon fontSize="small" />}
              onClick={onOpenDialogDeleteHandle}
            >
              Remove
            </Button>
          </HandleProfilePageButtonsContainer>

          <Typography variant="body2" align="center" sx={{ fontSize: '12px' }}>
            Joined {joinedDate}
          </Typography>
        </Stack>
      </ProfilePageBoxContainer>
    </ProfilePagePaperContainer>
  );
};

export default HandleProfileCard;
