import { type FC, useState } from 'react';

import Stack from '@mui/material/Stack';

import type { EmptyFunction, JSXElement, FooterElement } from '../../../models';

import Button from '../../common/buttons/Button';
import SelectComponent from '../../common/form-fields/Select';

import DialogBase from '../base/DialogBase';
import DialogAddNewFolder from '../add-new-folder/DialogAddNewFolder';

interface DialogSelectFolderProps {
  onClose: EmptyFunction;
}

const header = (): JSXElement => <>Select the folder</>;

const footer: FooterElement = options => {
  const { onConfirm, isDisabled, openDialog } = options;

  return (
    <Stack spacing={{ xs: 1, md: 2 }} sx={{ flexGrow: 1 }}>
      <Button fullWidth size="large" onClick={onConfirm} disabled={isDisabled}>
        Add now
      </Button>
      <Button fullWidth variant="outlined" size="large" onClick={openDialog}>
        Create a new folder
      </Button>
    </Stack>
  );
};

const DialogSelectFolder: FC<DialogSelectFolderProps> = props => {
  const { onClose } = props;
  const [disabled, setDisabled] = useState(true);
  const [addNewFolderDialog, setAddNewFolderDialog] = useState(false);

  const onOpenDialog = () => {
    setAddNewFolderDialog(true);
  };

  const onCloseDialog = (): void => {
    setAddNewFolderDialog(false);
  };

  const onSelectOption = (value: unknown): void => {
    setDisabled((value as string).length === 0);
  };

  const handleConfirm = () => {
    console.warn('handleConfirm');
  };

  // TODO: change options to real data
  const options = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'BradleyBradleyBradleyBradley WilkersonWilkersonWilkersonWilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

  return (
    <>
      <DialogBase
        header={header()}
        footer={footer({
          onConfirm: handleConfirm,
          isDisabled: disabled,
          openDialog: onOpenDialog,
        })}
        onClose={onClose}
        error={/* TODO  addFolderMutation.error?.message ||  */ ''}
      >
        <SelectComponent label="Folder name" options={options} onChange={onSelectOption} />
      </DialogBase>

      {addNewFolderDialog ? <DialogAddNewFolder onClose={onCloseDialog} /> : null}
    </>
  );
};

export default DialogSelectFolder;
