import type { FC } from 'react';

import type { IconButtonProps } from '@mui/material/IconButton';

import DeleteIcon from '../icon-delete/DeleteIcon';
import ButtonIconComponent from './ButtonIcon';

const ButtonDeleteComponent: FC<IconButtonProps> = props => {
  const { 'aria-label': ariaLabel, ...restProps } = props;

  return (
    <ButtonIconComponent {...restProps} aria-label="delete">
      <DeleteIcon aria-label={ariaLabel || 'delete'} sx={{ fontSize: '1.25rem', padding: '2px' }} />
    </ButtonIconComponent>
  );
};

export default ButtonDeleteComponent;
