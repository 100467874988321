import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

export const AgencyIconContainer = styled(Avatar)`
  width: 40px;
  height: 40px;
  text-transform: uppercase;

  .agency-box-small & {
    width: 32px;
    height: 32px;
  }
`;

export const AgencyTypography = styled(Typography)`
  line-height: 1.25em;
  text-align: left;

  .agency-box-small & {
    line-height: 1.1em;
  }
`;

export const AgencyHandleInfoIconsContainer = styled('div')`
  margin: 13px 0 15px;
  max-width: 250px;
`;

export const AgencyButtonWrapper = styled('div')`
  white-space: nowrap;

  .agency-box-small & {
    display: none;
  }
`;
