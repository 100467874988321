import { type FC, type MouseEventHandler, useState } from 'react';

import CardMedia from '@mui/material/CardMedia';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import type { Tweet } from '../../models';

import DialogHandleProfileShowImage, {
  DialogImageData,
} from '../../components/dialogs/handle-profile-show-image/DialogHandleProfileShowImage';
import { ProfilePageMediaContainer } from '../styled-components';

interface HandleProfileFeedItemMediaProps {
  data: Tweet['mediaFieldsModelDTO'];
}

const HandleProfileFeedItemMedia: FC<HandleProfileFeedItemMediaProps> = ({ data }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [imageData, setImageData] = useState<DialogImageData | null>(null);
  const theme = useTheme();
  const smallScreens = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick: MouseEventHandler = ({ target }) => {
    const imageIndex = (target as HTMLElement).dataset.itemIndex;

    setShowDialog(true);
    setImageData({
      imageTitle: `Image ${Number(imageIndex) + 1}`,
      imageUrl:
        data[Number(imageIndex)].type === 'photo'
          ? data[Number(imageIndex)].url
          : String(data[Number(imageIndex)].previewImageUrl),
    });
  };

  if (data.length) {
    if (data.length > 1) {
      return (
        <>
          <ImageList
            gap={10}
            sx={{
              height: smallScreens ? 'auto' : 500,
              userSelect: 'none',
            }}
            cols={smallScreens ? 1 : 2}
            onClick={handleClick}
          >
            {data.map((dataItem, dataItemIndex) => (
              <ImageListItem
                key={`${
                  dataItem.type === 'photo' ? dataItem.url : String(dataItem.previewImageUrl)
                }-${dataItemIndex}`}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={dataItem.type === 'photo' ? dataItem.url : String(dataItem.previewImageUrl)}
                  alt={`media item ${dataItemIndex + 1}`}
                  loading="lazy"
                  data-item-index={dataItemIndex}
                />
              </ImageListItem>
            ))}
          </ImageList>

          {showDialog && !smallScreens ? (
            <DialogHandleProfileShowImage
              onClose={() => setShowDialog(false)}
              imageTitle={imageData?.imageTitle || ''}
              imageUrl={imageData?.imageUrl || ''}
            />
          ) : null}
        </>
      );
    }

    return (
      <>
        <ProfilePageMediaContainer
          key={`${data[0].type === 'photo' ? data[0].url : String(data[0].previewImageUrl)}-0`}
          onClick={handleClick}
        >
          <CardMedia
            component="img"
            alt="media item 1"
            loading="lazy"
            image={data[0].type === 'photo' ? data[0].url : String(data[0].previewImageUrl)}
            sx={{ objectFit: 'contain', cursor: 'pointer' }}
            data-item-index={0}
          />
        </ProfilePageMediaContainer>

        {showDialog && !smallScreens ? (
          <DialogHandleProfileShowImage
            onClose={() => setShowDialog(false)}
            imageTitle={imageData?.imageTitle || ''}
            imageUrl={imageData?.imageUrl || ''}
          />
        ) : null}
      </>
    );
  }

  return null;
};

export default HandleProfileFeedItemMedia;
