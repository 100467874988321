import { type ChangeEvent, useRef, useState } from 'react';

function useFileUploadInput() {
  const ref = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File | null>(null);
  const [errorFiles, setErrorFiles] = useState('');

  const addFiles = (added: File) => {
    setFiles(added);
  };

  const removeFiles = () => {
    setFiles(null);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const added = e.target.files || null;

    const fileFilter =
      added &&
      Math.round(added[0].size / 1024) < 2048 &&
      (added[0].type === 'image/png' ||
        added[0].type === 'image/jpg' ||
        added[0].type === 'image/jpeg');

    const fileFilterSize = added && Math.round(added[0].size / 1024) >= 2048;

    const fileFilterType =
      added &&
      added[0].type !== 'image/png' &&
      added[0].type !== 'image/jpg' &&
      added[0].type !== 'image/jpeg';

    if (fileFilterSize) {
      setErrorFiles('The maximum size for a profile image is 2Mb');
      setFiles(null);
    }
    if (fileFilterType) {
      setErrorFiles('The file type is not allowed');
      setFiles(null);
    }
    if (fileFilter) {
      setErrorFiles('');
      addFiles(added[0]);
    }
  };

  const openSelection = () => {
    // Redirect the click event onto the hidden input element
    ref.current?.click();
  };

  return {
    files,
    openSelection,
    addFiles,
    removeFiles,
    errorFiles,
    inputProps: {
      onChange,
      ref,
    },
  };
}

export default useFileUploadInput;
