import { type FC, useState } from 'react';

import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import format from 'date-fns/format';

import Settings from '../../constants/settings';

import { useAuth } from '../../context/auth.context';
import { useAvatarLettersCompany, useAvatarLettersUser } from '../../hooks/use-avatar-letters';

import type { User, HandlesStats, UserProfileUpdatePayload } from '../../models';
import { useMutationUpdateUserProfile } from '../../queries/users';
import { useMutationUpdateCompanyProfile } from '../../queries/company';

import Button from '../../components/common/buttons/Button';
import IconsHandleWithColors from '../../components/common/icons-handle/IconsHandleWithColors';
import AgencyCardProfile from '../../components/common/card-agency-handle/AgencyCardProfile';
import ErrorMessage from '../../components/common/error-message/ErrorMessage';
import DialogEditProfile from '../../components/dialogs/edit-profile/DialogEditProfile';
import DialogEditCompany, {
  EditCompanyInfoFields,
} from '../../components/dialogs/edit-company/DialogEditCompany';
import InProgress from '../../components/common/in-progress/InProgress';

import {
  StatsContainer,
  ProfilePagePaperContainer,
  ProfilePageButtonsContainer,
  ProfilePageBoxContainer,
  ProfilePageUserContainer,
  ProfilePageUserInfoContainer,
  ProfilePageDescriptionContainer,
  HomePageBasicContainer,
  ProfilePageWrapper,
  ProfilePageMainContainer,
  ProfilePageButtonCog,
} from '../styled-components';

interface ProfilePageProps {
  user: User;
  companyName: string;
  companyNickname: string;
  companyEmail: string;
  imageUrl: string | null;
  handlesStats: HandlesStats;
}

const ProfilePage: FC<ProfilePageProps> = props => {
  const { user, companyName, companyNickname, companyEmail, imageUrl, handlesStats } = props;

  const { signout, signoutError, authInfo } = useAuth();
  const joinedDate = format(new Date(user.joinDate), Settings.DATE_DAY_ORDER_MONTH_SHORT);
  const avatarLetters = useAvatarLettersUser(user.username);
  const avatarLettersCompany = useAvatarLettersCompany(companyName);
  const [editProfileDialog, setEditProfileDialog] = useState(false);
  const [editCompanyDialog, setEditCompanyDialog] = useState(false);
  const updateUserProfile = useMutationUpdateUserProfile(authInfo.jwt);
  const updateCompanyProfile = useMutationUpdateCompanyProfile(user.companyId, authInfo.jwt);

  const handleUpdateUser = (data: UserProfileUpdatePayload): void => {
    updateUserProfile.mutate(
      {
        ...data,
        userId: user.id,
        companyId: user.companyId,
      },
      {
        onSuccess: () => {
          setEditProfileDialog(false);
        },
      },
    );
  };

  const handleUpdateCompany = (data: EditCompanyInfoFields): void => {
    updateCompanyProfile.mutate(data, {
      onSuccess: () => {
        setEditCompanyDialog(false);
      },
    });
  };

  const handleOpenEditProfileDialog = (): void => {
    setEditProfileDialog(true);
  };

  const onCloseEditProfileDialog = (): void => {
    setEditProfileDialog(false);
  };

  const onOpenEditCompanyDialog = (): void => {
    setEditCompanyDialog(true);
  };

  const onCloseEditCompanyDialog = (): void => {
    setEditCompanyDialog(false);
  };

  if (updateUserProfile.isLoading || updateCompanyProfile.isLoading) {
    return <InProgress isOpen={updateUserProfile.isLoading || updateCompanyProfile.isLoading} />;
  }

  if (user) {
    const handleClick = (): void => {
      signout({
        email: user.email,
        username: user.username,
      });
    };

    return (
      <HomePageBasicContainer>
        <Container maxWidth="lg">
          <ProfilePageWrapper>
            <ProfilePagePaperContainer>
              <ProfilePageBoxContainer>
                <Stack>
                  <ProfilePageUserContainer>
                    <Avatar
                      alt={`${user.fullname} avatar`}
                      src={imageUrl || ''}
                      sx={{ textTransform: 'uppercase', width: '60px', height: '60px' }}
                    >
                      {avatarLetters}
                    </Avatar>
                    <ProfilePageUserInfoContainer>
                      <Typography variant="h4" noWrap>
                        {user.fullname}
                      </Typography>
                      <Typography variant="body2" noWrap>
                        @{user.username}
                      </Typography>
                    </ProfilePageUserInfoContainer>
                    <ProfilePageButtonCog
                      aria-label="Edit button"
                      color="primary"
                      onClick={handleOpenEditProfileDialog}
                    >
                      <SettingsOutlinedIcon aria-label="Edit icon" sx={{ fontSize: '1em' }} />
                    </ProfilePageButtonCog>
                  </ProfilePageUserContainer>

                  <ProfilePageDescriptionContainer variant="body2">
                    {user.description}
                  </ProfilePageDescriptionContainer>

                  <StatsContainer>
                    <IconsHandleWithColors
                      unread={handlesStats.tweetNumber}
                      published={handlesStats.retweetNumber}
                      chat={handlesStats.replyNumber}
                    />
                  </StatsContainer>

                  {user.companyId ? (
                    <Box
                      sx={{
                        marginBottom: {
                          xs: '16px',
                          md: '40px',
                        },
                      }}
                    >
                      <AgencyCardProfile
                        companyName={companyName}
                        companyNickname={companyNickname}
                        imageUrl={imageUrl || ''}
                        editButton={user.authority === 'ROLE_ADMIN'}
                        onEditProfile={onOpenEditCompanyDialog}
                      />
                    </Box>
                  ) : null}

                  <ProfilePageButtonsContainer>
                    <Button
                      fullWidth
                      onClick={handleOpenEditProfileDialog}
                      startIcon={<EditIcon fontSize="small" />}
                    >
                      Edit profile
                    </Button>

                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={handleClick}
                      startIcon={<LogoutOutlinedIcon fontSize="small" />}
                    >
                      Logout
                    </Button>
                  </ProfilePageButtonsContainer>

                  <Typography variant="body2" align="center" sx={{ fontSize: '12px' }}>
                    Joined {joinedDate}
                  </Typography>

                  <ErrorMessage>{signoutError}</ErrorMessage>
                </Stack>

                {editProfileDialog ? (
                  <DialogEditProfile
                    user={user}
                    avatarLetters={avatarLetters}
                    onClose={onCloseEditProfileDialog}
                    onConfirm={handleUpdateUser}
                    isLoading={updateUserProfile.isLoading}
                    error={updateUserProfile.error?.message || ''}
                  />
                ) : null}

                {editCompanyDialog ? (
                  <DialogEditCompany
                    companyName={companyName}
                    companyNickname={companyNickname}
                    companyEmail={companyEmail}
                    companyImageUrl={imageUrl || ''}
                    avatarLetters={avatarLettersCompany}
                    onClose={onCloseEditCompanyDialog}
                    onConfirm={handleUpdateCompany}
                    isLoading={updateCompanyProfile.isLoading}
                    error={updateCompanyProfile.error?.message || ''}
                  />
                ) : null}
              </ProfilePageBoxContainer>
            </ProfilePagePaperContainer>
            <ProfilePageMainContainer></ProfilePageMainContainer>
          </ProfilePageWrapper>
        </Container>
      </HomePageBasicContainer>
    );
  }

  return null;
};

export default ProfilePage;
