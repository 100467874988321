import type { FC } from 'react';

import type { ButtonProps } from '@mui/material/Button';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';

import Button from './Button';

type ButtonBackToDashboardProps = Omit<ButtonProps, 'startIcon'>;

const ButtonBackToDashboard: FC<ButtonBackToDashboardProps> = ({ onClick, ...restProps }) => {
  return (
    <Button {...restProps} startIcon={<DashboardCustomizeOutlinedIcon />} onClick={onClick}>
      Back to dashboard
    </Button>
  );
};

export default ButtonBackToDashboard;
