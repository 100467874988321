import { useMemo } from 'react';

import type { Tweet } from '../models';

const useMediaFieldsHandleProfileFeedItem = (
  tweetFieldsModelDTO: Tweet['tweetFieldsModelDTO'],
  mediaFieldsModelDTO: Tweet['mediaFieldsModelDTO'],
) => {
  return useMemo(() => {
    const media: Tweet['mediaFieldsModelDTO'] = [];

    if (tweetFieldsModelDTO.attachmentsModel.pollIds !== null) {
      const pollIds = tweetFieldsModelDTO.attachmentsModel.pollIds.split(',');

      if (pollIds.length) {
        pollIds.forEach(pollId => {
          const item = mediaFieldsModelDTO.find(mediaField => mediaField.mediaKey === pollId);

          if (item) {
            media.push(item);
          }
        });

        return media;
      }
    } else {
      return mediaFieldsModelDTO;
    }

    return media;
  }, [tweetFieldsModelDTO, mediaFieldsModelDTO]);
};

export default useMediaFieldsHandleProfileFeedItem;
