export const ApiAuth = {
  register: '/auth/register',
  login: '/auth/login',
  logout: '/auth/logout',
  healthcheck: '/auth/health-check',
} as const;

export const ApiCompany = {
  all: '/companies',
  byId: (id: string) => `/companies/${id}`,
} as const;

export const ApiTweets = {
  all: '/tweets',
  rules: '/tweets/rules',
  handles: '/tweets/handles',
  handlesWithoutTwitsByEntityId: (id: string) => `/tweets/handles-clean/${id}`,
  recentHandlesByEntityId: (id: string) => `/tweets/handles/recent/${id}`,
  recentPinnedHandlesByEntityId: (id: string) => `/tweets/handles/recent/pinned/${id}`,
  handlesByCompanyId: (id: string) => `/tweets/handles/${id}`,
  tweetsByHandleId: (id: number) => `/tweets/handles/handle/${id}`,
  search: '/tweets/search',
  pinUnpin: '/tweets/pin',
  feedByEntityId: (id: string) => `/tweets/handles/feed/${id}`,
  fulltextSearch: '/tweets/fulltext-search',
} as const;

export const ApiUsers = {
  all: '/users',
  byId: (id: string) => `/users/${id}`,
} as const;

export const ApiStocktwits = {
  all: '/stocktwits/all-ids',
  current: '/stocktwits/current-ids',
  search: '/stocktwits/search',
} as const;
