import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { justifyContentFlexStart } from '../styled-components/primitives';

export const TweeterHandlesTitleContainer = styled('div')`
  margin: 22px 0;
`;

export const TweeterHandlesList = styled(List)(({ theme }) => ({
  padding: '0',

  '.MuiDivider-root': {
    borderColor: theme.palette.textLight.light2,
  },

  '.MuiListItem-root': {
    '& > .MuiListItemButton-root': {
      padding: '16px 112px 16px 24px',
    },
  },

  '.MuiListItemButton-root': {
    margin: '0 -24px',
  },

  '.MuiListItemSecondaryAction-root': {
    right: '0',
  },

  '.MuiIconButton-root': {
    marginLeft: '16px',
  },

  [theme.breakpoints.up('md')]: {
    '.MuiIconButton-colorError': {
      display: 'none',
    },

    '.MuiListItem-root': {
      '&:hover': {
        '.MuiIconButton-colorError': {
          display: 'inline-flex',
        },
      },
    },

    '.MuiListItemButton-root': {
      '&:hover': {
        backgroundColor: theme.palette.textLight.light3,
      },
    },
  },
}));

export const TweeterHandlesSidebarAgencyWrapper = styled('div')(({ theme }) => ({
  margin: ' 0 -24px',
  padding: '16px 24px',
  backgroundColor: theme.palette.textLight.light2,
}));

export const TweeterHandlesSidebarCloseWrapper = styled('div')`
  position: absolute;
  top: 20px;
  right: 24px;

  .MuiIconButton-root {
    font-size: 1em;
  }
`;

export const TweeterHandlesSidebarSwipeableDrawer = styled(SwipeableDrawer)`
  flex: 0 0 auto;
  width: 341px;

  & .MuiDrawer-paper {
    ${justifyContentFlexStart}
    top: 60px;
    width: 341px;
    padding: 0 24px 24px;
    height: calc(100% - 60px);
    border: none;
  }

  @media (min-width: 900px) {
    & .MuiDrawer-paper {
      top: 116px;
      height: calc(100% - 116px);
    }
  }
`;
