import { useMemo } from 'react';

import { isToday } from '../utils/date';

function useRowsNew<T>(rowsAll: Array<T & { date: number }>) {
  return useMemo(() => {
    if (rowsAll.length) {
      return rowsAll.filter(row => isToday(row.date));
    }

    return [];
  }, [rowsAll]);
}

export default useRowsNew;
