import { type FC, useState, type FocusEvent, type ChangeEventHandler } from 'react';

import type { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import Fade from '@mui/material/Fade';

import EndAdornmentComponent from './EndAdornmentComponent';

import { HelperText, StyledMUITextField } from './styled-components';

export interface TextFieldProps extends Omit<MUITextFieldProps, 'variant' | 'size' | 'error'> {
  error?: boolean;
  success?: boolean;
  optional?: boolean;
  helperText?: string;
}

let fieldId = 0;

const TextField: FC<TextFieldProps> = props => {
  const {
    label,
    onChange,
    onBlur,
    error,
    success,
    optional,
    name,
    id,
    required,
    helperText,
    type,
    value,
    InputProps,
    ...restProps
  } = props;

  const [filled, setFilled] = useState(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }

    if (Boolean(event.target.value)) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  };

  const helperTextElement = (
    <Fade in={error || false}>
      <HelperText id={`textFieldErrorText${fieldId}`} error={error || false}>
        {helperText || 'Oho! You missed this state.'}
      </HelperText>
    </Fade>
  );

  return (
    <StyledMUITextField
      {...restProps}
      variant="filled"
      size="small"
      type={type || 'text'}
      error={error || false}
      label={label}
      value={value}
      required={required || false}
      onChange={handleChange}
      onBlur={handleBlur}
      id={id || `textField${fieldId++}`}
      name={name || `text-field-${fieldId}`}
      classes={filled ? { root: 'text-filled' } : {}}
      InputProps={{
        ...InputProps,
        endAdornment: <EndAdornmentComponent success={success} optional={optional} />,
      }}
      helperText={helperTextElement}
    />
  );
};

export default TextField;
