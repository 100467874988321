import type { FC } from 'react';

import { Route } from 'react-router-dom';

import { SentryRoutes } from '../../sentry-init';

import type { AuthInfo, User, CompanyInfo, HandlesStats } from '../../models';

import useImageUrl from '../../hooks/use-image-url';

import ProfilePage from '../profile/ProfilePage';
import SuperAdminPage from '../super-admin/SuperAdminPage';

interface HomeRoutesSuperAdminProps {
  data: {
    entityInfo?: CompanyInfo | User;
  };
  authInfo: AuthInfo;
  handlesStats: HandlesStats;
  profilePageUser: User;
}

const HomeRoutesSuperAdmin: FC<HomeRoutesSuperAdminProps> = props => {
  const {
    data: { entityInfo },
    handlesStats,
    authInfo,
    profilePageUser,
  } = props;

  const imageUrl = useImageUrl(
    authInfo.userId,
    (entityInfo as CompanyInfo | User)?.accountType === 'COMPANY'
      ? (entityInfo as CompanyInfo)?.usersList
      : [],
  );

  if (entityInfo) {
    return (
      <SentryRoutes>
        <Route index element={<SuperAdminPage />} />
        <Route
          path="/profile"
          element={
            <ProfilePage
              user={profilePageUser}
              companyEmail={
                (entityInfo as CompanyInfo).accountType === 'COMPANY' ? entityInfo.email : ''
              }
              companyName={
                entityInfo.accountType === 'COMPANY' ? (entityInfo as CompanyInfo).companyName : ''
              }
              companyNickname={
                entityInfo.accountType === 'COMPANY'
                  ? (entityInfo as CompanyInfo).name
                  : (entityInfo as User).username
              }
              imageUrl={
                entityInfo.accountType === 'COMPANY' ? imageUrl : (entityInfo as User).imageUrl
              }
              handlesStats={handlesStats}
            />
          }
        />
      </SentryRoutes>
    );
  }

  return null;
};

export default HomeRoutesSuperAdmin;
