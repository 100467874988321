import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isBefore from 'date-fns/isBefore';
import format from 'date-fns/format';
import isTodayDateFns from 'date-fns/isToday';

import Settings from '../constants/settings';

const getDifferenceInCalendarDays = (date: number) => {
  return differenceInCalendarDays(new Date(), date);
};

export const getDifferenceInDaysWithText = (date: number) => {
  const days = getDifferenceInCalendarDays(date);

  if (days > 1) {
    return days + ' days ago';
  }

  if (days === 1) {
    return days + ' day ago';
  }

  return 'today';
};

export const isBeforeToday = (dateToCompare: Date | number) => {
  const today = Date.now();

  return isBefore(dateToCompare, today);
};

export const getJoinedDate = (date: number) => {
  return format(new Date(date), Settings.DATE_SHORT);
};

export const isToday = (date: Date | number) => isTodayDateFns(date);
