import type { FC } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { Routes } from '../../constants/routes';

import ButtonBackToDashboard from '../../components/common/buttons/ButtonBackToDashboard';

import { PageErrorWithToolbalContainer } from '../styled-components';

import ErrorPage from './ErrorPage';

const ErrorPageBackToDashboard: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (): void => {
    navigate(Routes.home.route, {
      state: {
        from: location,
        routeName: Routes.home.routeName,
      },
      replace: true,
    });
  };

  return (
    <PageErrorWithToolbalContainer>
      <ErrorPage backToElement={<ButtonBackToDashboard onClick={handleClick} />} />
    </PageErrorWithToolbalContainer>
  );
};

export default ErrorPageBackToDashboard;
