import { useEffect } from 'react';
import { FunctionWithArg } from '../../../models';

import { useTabsRoutes } from './use-tabs-routes';

const usePathnameMatch = (pathname: string, setCurrentTab: FunctionWithArg<boolean | string>) => {
  const tabsRoutes = useTabsRoutes();

  useEffect(() => {
    const pathnameMatch = tabsRoutes.find(tabRoute => tabRoute === pathname);

    if (!pathnameMatch) {
      setCurrentTab(false);
    } else {
      setCurrentTab(pathnameMatch);
    }
  }, [tabsRoutes, pathname, setCurrentTab]);
};

export default usePathnameMatch;
