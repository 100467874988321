import { styled } from '@mui/material/styles';

export const UploadImageContainer = styled('div')`
  position: relative;
  margin: 0 auto 12px;
  width: 90px;

  .MuiIconButton-root {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
