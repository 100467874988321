import type { FC } from 'react';

import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import MUISelect, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ExpandMoreOutlined';

import type { FunctionWithArg } from '../../../models';

import { StyledFormControlSelect } from './styled-components';

let fieldId = 0;

export interface SelectComponentProps extends SelectProps {
  options: Array<string>;
  label?: string;
  value?: string;
  onChange?: FunctionWithArg<unknown>;
}

const SelectComponent: FC<SelectComponentProps> = props => {
  const { label, labelId, id, options, value, onChange, ...restProps } = props;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    if (onChange) {
      onChange(event.target.value as string);
    }
  };

  return (
    <StyledFormControlSelect
      fullWidth
      variant="filled"
      size="small"
      classes={value ? { root: 'text-filled' } : {}}
    >
      <InputLabel id={labelId || `selectLabel${fieldId++}`}>{label}</InputLabel>
      <MUISelect
        {...restProps}
        labelId={labelId || `selectLabel${fieldId}`}
        id={id || `select${fieldId}`}
        value={value}
        label={label}
        onChange={handleChange}
        IconComponent={ArrowDropDownIcon}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </MUISelect>
    </StyledFormControlSelect>
  );
};

export default SelectComponent;
