import type { FC } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import theme from '../../../theme';

interface EndAdornmentComponentProps {
  success?: boolean;
  optional?: boolean;
}

const EndAdornmentComponent: FC<EndAdornmentComponentProps> = props => {
  const { success, optional } = props;

  if (success) {
    return (
      <InputAdornment position="end">
        <CheckCircleOutlineOutlinedIcon color="success" />
      </InputAdornment>
    );
  }

  if (optional) {
    return (
      <InputAdornment position="end" disableTypography sx={{ fontSize: '12px', color: theme.palette.text.disabled }}>
        Optional
      </InputAdornment>
    );
  }

  return null;
};

export default EndAdornmentComponent;
