import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import type { AxiosError } from 'axios';

import type { StockTwitsInfo, GetStockTwitInfoSearchOptions } from '../models';

import { getStockTwitInfoSearch } from '../api/stocktwits';

export const useQueryStockTwitsInfoSearch = (
  options: GetStockTwitInfoSearchOptions,
): UseQueryResult<Array<StockTwitsInfo>, AxiosError> => {
  return useQuery(['stock-twits-search', options.keyword], () => getStockTwitInfoSearch(options), {
    enabled: Boolean(options.keyword),
  });
};
