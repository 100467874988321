import type { FC } from 'react';

import { CustomSwitch } from './styled-components';

const RenderStatus: FC = () => {
  return (
    <CustomSwitch
      disableRipple
      focusVisibleClassName=".Mui-focusVisible"
      defaultChecked
      //checked={params.value}
      //onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};

export default RenderStatus;
