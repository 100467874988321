import { useMemo } from 'react';

import type { User } from '../models';

const useImageUrl = (userId: string, usersList?: Array<User>) => {
  return useMemo(() => {
    if (usersList && usersList.length > 1) {
      const user = usersList.find(listItem => listItem.id === userId);

      if (user) {
        return user.imageUrl;
      }

      return '';
    }

    if (usersList && usersList[0]) {
      return usersList[0].imageUrl;
    }

    return '';
  }, [usersList, userId]);
};

export default useImageUrl;
