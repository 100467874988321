import { type ReactElement, useMemo } from 'react';

const useItemData = (children: Array<ReactElement | string | number>) => {
  return useMemo(() => {
    const itemData: Array<ReactElement | string | number> = [];

    (children as Array<ReactElement | string | number>).forEach(
      (item: any & { children?: Array<ReactElement | string | number> }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );

    return itemData;
  }, [children]);
};

export default useItemData;
