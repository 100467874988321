import type { FC } from 'react';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import type { Handle } from '../../../models';

const TwitterHandleAvatar: FC<{ handle: Handle }> = ({ handle }) => {
  return (
    <Badge color="success" badgeContent={0}>
      <Avatar
        alt={`${handle.username} avatar`}
        src={handle.profileImageUrl}
        sx={{ width: 48, height: 48 }}
      />
    </Badge>
  );
};

export default TwitterHandleAvatar;
