import useLocalStorageState from 'use-local-storage-state';

import Settings from '../constants/settings';
import { SigninResponse } from '../models';

const useAuthInfoLocalStorage = () => {
  const [authInfo, setAuthInfo, { removeItem }] = useLocalStorageState<SigninResponse>(
    `${Settings.LOCAL_STORAGE_NAME_SPACE}.authInfo`,
    {
      defaultValue: {
        accountType: 'COMPANY',
        jwt: '',
        companyId: '',
        userId: '',
      },
    },
  );

  return {
    authInfo,
    setAuthInfo,
    removeJwtToken: removeItem,
  };
};

export default useAuthInfoLocalStorage;
