import reactStringReplace from 'react-string-replace';
import Link from '@mui/material/Link';

export const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return reactStringReplace(text, urlRegex, (match, i) => (
    <Link key={i} href={match} underline="hover" target="_blank" rel="noopener">
      {match}
    </Link>
  ));
};
