import { type FC, useMemo } from 'react';

import RedirectAuth from '../../components/common/redirect-auth/RedirectAuth';
import Navbar from '../../components/common/navigation/Navbar';
import InProgress from '../../components/common/in-progress/InProgress';
import { useAuth } from '../../context/auth.context';
import useHomePageData from '../../hooks/use-home-page-data';
import useHandlesStats from '../../hooks/use-handles-stats';
import useImageUrl from '../../hooks/use-image-url';
import type { CompanyInfo, User } from '../../models';

import ErrorPageRequest from '../error/ErrorPageRequest';
import HomeRoutes from './HomeRoutes';
import HomeRoutesSuperAdmin from './HomeRoutesSuperAdmin';

const HomePage: FC = () => {
  const { authInfo } = useAuth();
  const { data, recentHandles, recentPinnedHandles, handlesWithoutTwits, feed } =
    useHomePageData(authInfo);
  const handlesStats = useHandlesStats(handlesWithoutTwits.data || []);
  const userAdmin = useMemo(() => {
    if (data.entityInfo) {
      return data.entityInfo.accountType === 'COMPANY'
        ? (data.entityInfo as CompanyInfo).usersList?.filter(
            user => user.authority === 'ROLE_ADMIN',
          )[0]
        : (data.entityInfo as User);
    }

    return {} as User;
  }, [data.entityInfo]);
  const isSuperAdmin = useMemo(() => {
    if (data.entityInfo) {
      return data.entityInfo.accountType === 'COMPANY'
        ? (data.entityInfo as CompanyInfo).usersList?.filter(user => user.isSuperAdmin)[0]
            ?.isSuperAdmin
        : (data.entityInfo as User).isSuperAdmin;
    }

    return false;
  }, [data.entityInfo]);
  const imageUrl = useImageUrl(
    authInfo.userId,
    (data.entityInfo as CompanyInfo | User)?.accountType === 'COMPANY'
      ? (data.entityInfo as CompanyInfo)?.usersList
      : [],
  );

  if (data.isLoading) {
    return (
      <RedirectAuth>
        <InProgress isOpen={data.isLoading} />
      </RedirectAuth>
    );
  }

  if (data.isError) {
    return (
      <RedirectAuth>
        <ErrorPageRequest onRefetch={data.refetch} />
      </RedirectAuth>
    );
  }

  if (
    data.entityInfo &&
    handlesWithoutTwits.data &&
    recentHandles.data &&
    recentPinnedHandles.data
  ) {
    return (
      <RedirectAuth>
        <Navbar
          profileNickname={
            data.entityInfo.accountType === 'COMPANY'
              ? (data.entityInfo as CompanyInfo).name
              : (data.entityInfo as User).username
          }
          companyName={
            data.entityInfo.accountType === 'COMPANY'
              ? (data.entityInfo as CompanyInfo).companyName
              : ''
          }
          email={data.entityInfo.email}
          entityId={
            data.entityInfo.accountType === 'COMPANY' ? authInfo.companyId : authInfo.userId
          }
          imageUrl={
            data.entityInfo.accountType === 'COMPANY'
              ? imageUrl
              : (data.entityInfo as User).imageUrl
          }
          handles={handlesWithoutTwits.data || []}
          disableAddNewHandlesButton={
            handlesWithoutTwits.data &&
            handlesWithoutTwits.data?.length >= data.entityInfo.trackHandlesAmount
          }
          superAdmin={isSuperAdmin}
          onRefetch={() => {
            data.refetch();
            recentHandles.refetch();
            feed.refetch();
          }}
        />
        {isSuperAdmin ? (
          <HomeRoutesSuperAdmin
            data={data}
            authInfo={authInfo}
            handlesStats={handlesStats}
            profilePageUser={userAdmin}
          />
        ) : (
          <HomeRoutes
            data={data}
            recentHandles={recentHandles}
            recentPinnedHandles={recentPinnedHandles}
            handlesWithoutTwits={handlesWithoutTwits}
            authInfo={authInfo}
            handlesStats={handlesStats}
            profilePageUser={userAdmin}
            feed={feed}
          />
        )}
      </RedirectAuth>
    );
  }

  return null;
};

export default HomePage;
