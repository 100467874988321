import { useMemo } from 'react';

import type { User, CompanyInfoTable, CompanyInfo } from '../models';

const useAdminUsersTableData = (
  data: Array<User> | undefined,
  dataCompanies: Array<CompanyInfo> | undefined,
) => {
  return useMemo<Array<CompanyInfoTable>>(() => {
    if (data?.length) {
      return data?.map(
        ({
          id,
          username,
          email,
          imageUrl,
          fullname,
          joinDate,
          membershipType,
          trackHandlesAmount,
          authority,
          companyId,
          accountType,
        }) => {
          const company: CompanyInfo | undefined = dataCompanies?.find(
            company => company.id === companyId,
          );

          return {
            id,
            username: {
              avatar: imageUrl,
              name: username,
            },
            fullName: fullname,
            email,
            agencyName: company?.companyName || 'N/A',
            date: joinDate,
            membership: membershipType,
            trackAmount: trackHandlesAmount,
            expires: '34 days',
            status: true,
            admin: authority === 'ROLE_ADMIN',
            companyId,
            accountType,
          };
        },
      );
    }

    return [];
  }, [data, dataCompanies]);
};

export default useAdminUsersTableData;
