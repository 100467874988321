import type { FC } from 'react';

import { common } from '@mui/material/colors';

import type { FunctionWithArg, Handle } from '../../../models';

import AgencyComponent from '../card-agency-handle/Agency';
import ButtonClose from '../buttons/ButtonClose';
import { useMutationPinUnpin, useMutationDeleteHandle } from '../../../queries/tweets';

import TweeterHandlesListComponent from './TweeterHandlesListComponent';

import {
  TweeterHandlesSidebarAgencyWrapper,
  TweeterHandlesSidebarCloseWrapper,
  TweeterHandlesSidebarSwipeableDrawer,
} from './styled-components';

interface TweeterHandlesSidebarComponentProps {
  handles: Array<Handle>;
  entityId: string;
  companyNickname: string;
  companyName: string;
  imageUrl: string;
  openSidebar: boolean;
  toggleDrawer: FunctionWithArg<boolean>;
  jwt: string;
  hideEntityInfo?: boolean;
}

const TweeterHandlesSidebarComponent: FC<TweeterHandlesSidebarComponentProps> = props => {
  const {
    handles,
    entityId,
    companyNickname,
    companyName,
    imageUrl,
    openSidebar,
    toggleDrawer,
    jwt,
    hideEntityInfo,
  } = props;

  const pinUnpin = useMutationPinUnpin(jwt);
  const deleteHandle = useMutationDeleteHandle(entityId, jwt);

  const handlePinUnpin = (handle: Handle) => {
    pinUnpin.mutate({
      pinned: !handle.pinned,
      handleId: handle.id,
      companyId: entityId,
    });
  };

  const handleDelete = (handle: Handle) => {
    deleteHandle.mutate({
      authorNames: handle.username,
      companyId: handle.companyDTO ? entityId : undefined,
      handleId: handle.id,
      userId: !handle.companyDTO ? handle.userDTO?.id : undefined,
    });
  };

  return (
    <TweeterHandlesSidebarSwipeableDrawer
      anchor="right"
      open={openSidebar}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <TweeterHandlesSidebarCloseWrapper>
        <ButtonClose onClick={() => toggleDrawer(false)} sx={{ backgroundColor: common.white }} />
      </TweeterHandlesSidebarCloseWrapper>

      {hideEntityInfo ? null : (
        <TweeterHandlesSidebarAgencyWrapper>
          <AgencyComponent
            companyName={companyName}
            companyNickname={companyNickname}
            imageUrl={imageUrl}
          />
        </TweeterHandlesSidebarAgencyWrapper>
      )}

      <TweeterHandlesListComponent
        handles={handles}
        onDelete={handleDelete}
        onPinUnpin={handlePinUnpin}
        isLoading={deleteHandle.isLoading || pinUnpin.isLoading}
      />
    </TweeterHandlesSidebarSwipeableDrawer>
  );
};

export default TweeterHandlesSidebarComponent;
