import type { FC } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const InProgressInline: FC<{ size?: number }> = ({ size }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress color="inherit" size={size || 40} />
    </Box>
  );
};

export default InProgressInline;
