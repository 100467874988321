import type { FC } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import Container from '@mui/system/Container';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useMutationDeleteHandle, useMutationPinUnpin } from '../../queries/tweets';
import type {
  User,
  Handle,
  HandlesStats,
  FeedDataWithPagination,
  RecentHandlesHome,
} from '../../models';

import { Routes } from '../../constants/routes';

import TwitterHandleAvatar from '../../components/common/twitter-handle-avatar/TwitterHandleAvatar';
import TwitterHandleAvatarToPin from '../../components/common/twitter-handle-avatar/TwitterHandleAvatarToPin';
import AgencyHandleCard from '../../components/common/card-agency-handle/AgencyHandleCard';
import TweeterHandlesListComponent from '../../components/common/tweeter-handles-sidebar/TweeterHandlesListComponent';
import theme from '../../theme';
import Feed from './Feed';

import {
  HomePageBasicContainer,
  ProfilePageWrapper,
  TwitterHandlesSidebar,
} from '../styled-components';
import {
  HomePageColumnTitle,
  HomePageColumnCard,
  HomePageBox,
  HomePageColumnContainer,
  HomePageMainContainer,
} from './styled-components';

interface DashboardPage2Props {
  usersList: Array<User>;
  entityId: string;
  accountType: string;
  companyName: string;
  companyNickname: string;
  handles: Array<Handle>;
  recentHandles: RecentHandlesHome;
  recentPinnedHandles: RecentHandlesHome;
  handlesWithoutTwits: RecentHandlesHome;
  feed: FeedDataWithPagination;
  handlesStats: HandlesStats;
  imageUrl: string | null;
  jwt: string;
}

const DashboardPage2: FC<DashboardPage2Props> = props => {
  const {
    usersList,
    accountType,
    companyName,
    companyNickname,
    handles,
    recentHandles,
    recentPinnedHandles,
    handlesWithoutTwits,
    handlesStats,
    entityId,
    imageUrl,
    feed,
    jwt,
  } = props;
  const pinUnpin = useMutationPinUnpin(jwt);
  const deleteHandle = useMutationDeleteHandle(entityId, jwt);
  const smallScreens = useMediaQuery(theme.breakpoints.down('md'));

  const isAllowSidebar = handles.length;

  const handlePinUnpin = (handle: Handle) => {
    pinUnpin.mutate({
      pinned: !handle.pinned,
      handleId: handle.id,
      companyId: entityId,
    });
  };

  const handleDelete = (handle: Handle) => {
    deleteHandle.mutate({
      authorNames: handle.username,
      companyId: handle.companyDTO ? entityId : undefined,
      handleId: handle.id,
      userId: !handle.companyDTO ? handle.userDTO?.id : undefined,
    });
  };

  return (
    <HomePageBasicContainer>
      <Container maxWidth="lg" sx={{ display: 'flex' }}>
        <ProfilePageWrapper sx={{ flexGrow: 1 }}>
          <HomePageColumnContainer
            className={!recentPinnedHandles.data?.length ? 'empty-pinned-handles' : ''}
          >
            {!smallScreens ? (
              <HomePageBox>
                <HomePageColumnTitle>
                  {accountType === 'COMPANY' ? 'Agency handles' : 'Activity'}
                </HomePageColumnTitle>

                <AgencyHandleCard
                  usersList={usersList}
                  companyName={companyName}
                  companyNickname={companyNickname}
                  handlesStats={handlesStats}
                  imageUrl={imageUrl}
                />
              </HomePageBox>
            ) : null}

            {recentPinnedHandles.data?.length ? (
              <HomePageBox>
                <HomePageColumnTitle>Pinned handles</HomePageColumnTitle>

                <HomePageColumnCard>
                  <Stack direction="row" spacing={2} padding={{ xs: 0, md: 2 }}>
                    {recentPinnedHandles.data.map((pinnedHandle, pinnedHandleIndex) => (
                      <div key={`${pinnedHandleIndex}-${pinnedHandle.id}`}>
                        <Link
                          to={Routes.handleById.route(pinnedHandle.id)}
                          underline="none"
                          component={RouterLink}
                          state={{
                            routeName: Routes.handleById.routeName,
                            handleId: pinnedHandle.id,
                          }}
                        >
                          <TwitterHandleAvatar handle={pinnedHandle} />
                        </Link>
                      </div>
                    ))}
                    <TwitterHandleAvatarToPin />
                  </Stack>
                </HomePageColumnCard>
              </HomePageBox>
            ) : null}

            {!smallScreens && recentHandles.data?.length ? (
              <HomePageBox>
                <HomePageColumnTitle>Most recent activity handles</HomePageColumnTitle>

                <Card>
                  <Stack direction="row" spacing={2} padding={2}>
                    {recentHandles.data.map((recentHandle, recentHandleIndex) => (
                      <div key={`${recentHandleIndex}-${recentHandle.id}`}>
                        <Link
                          to={Routes.handleById.route(recentHandle.id)}
                          underline="none"
                          component={RouterLink}
                          state={{
                            routeName: Routes.handleById.routeName,
                            handleId: recentHandle.id,
                          }}
                        >
                          <TwitterHandleAvatar handle={recentHandle} />
                        </Link>
                      </div>
                    ))}
                  </Stack>
                </Card>
              </HomePageBox>
            ) : null}
          </HomePageColumnContainer>

          <HomePageMainContainer
            className={!recentPinnedHandles.data?.length ? 'empty-pinned-handles' : ''}
          >
            {smallScreens ? (
              <Typography variant="h4" sx={{ marginBottom: 2 }}>
                Most recent feeds
              </Typography>
            ) : null}

            <Feed data={feed} />
          </HomePageMainContainer>
        </ProfilePageWrapper>

        {isAllowSidebar ? (
          <TwitterHandlesSidebar variant="permanent" anchor="right">
            <TweeterHandlesListComponent
              handles={handlesWithoutTwits.data || []}
              onDelete={handleDelete}
              onPinUnpin={handlePinUnpin}
              isLoading={
                handlesWithoutTwits.isLoading || deleteHandle.isLoading || pinUnpin.isLoading
              }
            />
          </TwitterHandlesSidebar>
        ) : null}
      </Container>
    </HomePageBasicContainer>
  );
};

export default DashboardPage2;
