import type { FC, MutableRefObject } from 'react';

import Grid from '@mui/material/Grid';
import Container from '@mui/system/Container';
import Box from '@mui/system/Box';
import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import Logo from '../logo/Logo';
import BadgePromo from './BadgePromo';
import SearchFieldV2 from '../search-field/SearchFieldV2';

import type {
  EmptyFunction,
  FunctionWithArg,
  PaginatedResponseData,
  SearchItem,
  Handle,
} from '../../../models';

import { useAvatarLettersUser } from '../../../hooks/use-avatar-letters';

import { TopBarContainer, LogoButtonContainer } from './styled-components';

interface TopBarProps {
  imageUrl: string;
  profileNickname: string;
  profileClick: EmptyFunction;
  dashboardClick: EmptyFunction;
  companyName: string;
  onOpen: FunctionWithArg<boolean>;
  isOpen: boolean;
  options: PaginatedResponseData<SearchItem>;
  onChangeDateRange: FunctionWithArg<number | undefined>;
  onChangeFilter: FunctionWithArg<Array<string>>;
  handles: Array<Handle>;
  proBadge?: boolean;
  adminBadge?: boolean;
  superAdminBadge?: boolean;
  anchorElRef?: MutableRefObject<HTMLDivElement | null>;
  onSearch?: FunctionWithArg<string>;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: EmptyFunction;
  dateRange?: number;
}

const TopBar: FC<TopBarProps> = props => {
  const {
    imageUrl,
    profileNickname,
    proBadge,
    adminBadge,
    superAdminBadge,
    profileClick,
    dashboardClick,
    companyName,
    anchorElRef,
    onSearch,
    onOpen,
    isOpen,
    options,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    onChangeDateRange,
    onChangeFilter,
    dateRange,
  } = props;

  const avatarLetters = useAvatarLettersUser(profileNickname);

  return (
    <TopBarContainer>
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          <LogoButtonContainer disableRipple disableTouchRipple onClick={dashboardClick}>
            <Logo />
          </LogoButtonContainer>

          <Box sx={{ width: '390px' }}>
            <SearchFieldV2
              anchorElRef={anchorElRef}
              options={options}
              onOpen={onOpen}
              isOpen={isOpen}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              onChangeDateRange={onChangeDateRange}
              dateRange={dateRange}
              onChangeFilter={onChangeFilter}
              onSearch={onSearch}
            />
          </Box>

          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            <Stack alignItems="center" direction="row">
              {proBadge ? <BadgePromo title="Yearly pro" /> : null}
              {adminBadge ? <BadgePromo title="Admin" /> : null}
              {superAdminBadge ? <BadgePromo title="Super admin" superAdmin /> : null}

              <ButtonBase disableRipple disableTouchRipple onClick={profileClick}>
                <Avatar
                  alt={`${companyName} profile avatar`}
                  src={imageUrl}
                  sx={{ textTransform: 'uppercase', width: 36, height: 36 }}
                >
                  {avatarLetters}
                </Avatar>
              </ButtonBase>
            </Stack>
          </Box>
        </Grid>
      </Container>
    </TopBarContainer>
  );
};

export default TopBar;
