import { useMemo } from 'react';

import { User } from '../../models';

const useGetAllAdminUsers = (dataUsers: Array<User>, selectedUsersRows: Array<string>) => {
  return useMemo(() => {
    if (selectedUsersRows.length) {
      return selectedUsersRows.map(id => {
        const selectedUser = dataUsers?.find(user => user.id === id);

        if (selectedUser) {
          return selectedUser.authority === 'ROLE_ADMIN';
        }

        return false;
      });
    }

    return [];
  }, [dataUsers, selectedUsersRows]);
};

const useAdminUsers = (dataUsers: Array<User>, selectedUsersRows: Array<string>) => {
  const allUsers = useGetAllAdminUsers(dataUsers, selectedUsersRows);
  const isAllAdmins = useMemo(() => {
    return Boolean(allUsers.length) && allUsers.every(item => Boolean(item));
  }, [allUsers]);
  const isAllNonAdmins = useMemo(() => {
    return Boolean(allUsers.length) && allUsers.every(item => !Boolean(item));
  }, [allUsers]);
  const isMixedAdmins = useMemo(() => {
    return !isAllAdmins && !isAllNonAdmins;
  }, [isAllAdmins, isAllNonAdmins]);

  return {
    isAllAdmins,
    isAllNonAdmins,
    isMixedAdmins,
  };
};

export default useAdminUsers;
