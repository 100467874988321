import { useMemo } from 'react';

import type { Handle, HandlesTable } from '../models';

const useHandlesTableData = (data: Array<Handle>) => {
  return useMemo<Array<HandlesTable>>(() => {
    return data.map(
      ({ id, companyDTO, createdDate, profileImageUrl, username, userDTO, stockTwits }) => ({
        id,
        twitterHandle: {
          id,
          avatar: profileImageUrl,
          username,
          isStockTwits: stockTwits,
        },
        email: companyDTO?.email || userDTO?.email || '',
        date: createdDate,
        /* @TODO: hidden for alfa release
        type: {
          classname: 'retweet',
          name: 'Liked a tweet',
        }, */
      }),
    );
  }, [data]);
};

export default useHandlesTableData;
