import { Link as RouterLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import type { GridColDef } from '@mui/x-data-grid';

import { getDifferenceInDaysWithText } from '../../utils/date';
import { Routes } from '../../constants/routes';

import { TableAvatarContainer } from './styled-components';

export const columns: Array<GridColDef> = [
  {
    field: 'twitterHandle',
    headerName: 'Handle',
    sortable: false,
    width: 170,
    renderCell: ({ value, row }) => {
      if (value) {
        return (
          <Link
            to={Routes.handleById.route(value.id)}
            underline="none"
            component={RouterLink}
            state={{
              routeName: Routes.handleById.routeName,
              handleId: value.id,
            }}
          >
            <TableAvatarContainer src={value.avatar} />
            <Typography noWrap>
              {row.twitterHandle.isStockTwits ? '#' : '@'}{value.username}
            </Typography>
          </Link>
        );
      }

      return null;
    },
  },
  { field: 'email', headerName: 'Email address', sortable: false, width: 160 },
  {
    field: 'date',
    headerName: 'Created date',
    sortable: false,
    width: 150,
    renderCell: params => {
      return getDifferenceInDaysWithText(params.value);
    },
  },
  /* TODO: hidden for alfa release
   {
    field: 'type',
    headerName: 'Account type',
    sortable: false,
    width: 160,
    renderCell: params => {
      if (params.value && params.value.classname === 'retweet') {
        return (
          <Typography noWrap color="retweet.main">
            {params.value.name}
          </Typography>
        );
      }

      if (params.value && params.value.classname === 'tweet') {
        return (
          <Typography noWrap color="tweet.main">
            {params.value.name}
          </Typography>
        );
      }

      if (params.value && params.value.classname === 'comment') {
        return (
          <Typography noWrap color="comment.main">
            {params.value.name}
          </Typography>
        );
      }

      return null;
    },
  }, */
];
