import type { CSSProperties } from 'react';

import { createTheme } from '@mui/material/styles';
import { lighten } from '@mui/system';

import ProductSansWoff2 from './fonts/ProductSansRegular.woff2';
import ProductSansTtf from './fonts/ProductSansRegular.ttf';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    xsmall: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    xsmall: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  export interface PaletteOptions {
    primaryLight: {
      light1: string;
      light2: string;
      light3: string;
    };
    secondaryLight: {
      light1: string;
      light2: string;
      light3: string;
    };
    textLight: {
      light1: string;
      light2: string;
      light3: string;
    };
    tweet: {
      main: string;
    };
    yellow: {
      main: string;
    };
    comment: {
      main: string;
    };
    retweet: {
      main: string;
    };
  }

  export interface Palette {
    primaryLight: {
      light1: CSSProperties['color'];
      light2: CSSProperties['color'];
      light3: CSSProperties['color'];
    };
    secondaryLight: {
      light1: CSSProperties['color'];
      light2: CSSProperties['color'];
      light3: CSSProperties['color'];
    };
    textLight: {
      light1: CSSProperties['color'];
      light2: CSSProperties['color'];
      light3: CSSProperties['color'];
    };
    tweet: {
      main: CSSProperties['color'];
    };
    yellow: {
      main: CSSProperties['color'];
    };
    comment: {
      main: CSSProperties['color'];
    };
    retweet: {
      main: CSSProperties['color'];
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#dd2763',
      light: lighten('#dd2763', 0.3),
    },
    primaryLight: {
      light1: lighten('#dd2763', 0.5),
      light2: lighten('#dd2763', 0.8),
      light3: lighten('#dd2763', 0.9),
    },

    secondary: {
      main: '#f6c000',
      light: '#fbe080',
    },
    secondaryLight: {
      light1: '#fdecb3',
      light2: '#fef9e5',
      light3: '#fffdf5',
    },

    error: {
      main: '#f34d16',
      light: '#fff8f6',
    },
    success: {
      main: '#30cf57',
    },
    info: {
      main: '#009ee3',
    },

    tweet: {
      main: '#2e79ea',
    },
    yellow: {
      main: '#ffc300',
    },
    comment: {
      main: '#ff7f3f',
    },
    retweet: {
      main: '#36ae7c',
    },

    text: {
      primary: '#0f1e35',
      secondary: '#878f9a',
      disabled: '#b7bcc2',
    },
    textLight: {
      light1: '#e7e8eb',
      light2: '#fcedf2',
      light3: '#fef6f8',
    },

    background: {
      default: '#f8f8f8',
    },
  },
  typography: {
    fontFamily: 'Product Sans, Arial, sans-serif',
    h1: {
      fontSize: '3.5em',
      fontWeight: '400',
      lineHeight: '1.1',
    },
    h2: {
      fontSize: '2.5em',
      fontWeight: '400',
      lineHeight: '1',
    },
    h3: {
      fontSize: '2em',
      lineHeight: '1.2',
    },
    h4: {
      fontSize: '24px',
      lineHeight: '1.2',
    },
    h5: {
      fontSize: '1em',
    },
    h6: {
      fontSize: '0.75em',
      fontWeight: '400',
      lineHeight: '1.4',
    },
    body2: {
      color: '#878f9a',
      fontSize: '1em',
    },
    subtitle1: {
      color: '#878f9a',
    },
    subtitle2: {
      fontSize: '0.75em',
    },
  },

  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Product Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('ProductSans'), url(${ProductSansWoff2}) format('woff2');
          src: local("ProductSans"), url(${ProductSansTtf}) format('truetype');
        }
      `,
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '48px',
          fontSize: '1em',
          textTransform: 'none',
          flexDirection: 'row',
          color: '#0f1e35',

          '.MuiSvgIcon-root': {
            fontSize: '1.25em',
          },

          '&.Mui-selected': {
            color: '#dd2763',
          },

          '.MuiTab-iconWrapper': {
            margin: '0 8px 0 0',
            width: '24px',
            height: '24px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: '11px',
          paddingBottom: '11px',
          textTransform: 'none',
          fontSize: '1em',
          borderRadius: '25px',
          boxShadow: 'none',
          '&.MuiButton-sizeMedium': {
            paddingTop: '4px',
            paddingBottom: '4px',
          },
          '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: '#b7bcc2',
          },
        },
        startIcon: {
          marginLeft: '0',
        },

        text: {
          color: '#0f1e35',
        },
        textPrimary: {
          color: '#dd2763',
        },

        contained: {
          backgroundColor: '#dd2763',
          '&:hover': {
            backgroundColor: '#dd2763',
            boxShadow: '0 8px 8px rgba(57, 154, 205, 0.1)',
          },
        },
        containedSecondary: {
          color: '#dd2763',
          backgroundColor: '#fef6f8',
          '&:hover': {
            color: '#fff',
          },
        },
        containedError: {
          backgroundColor: '#f34d16',
          '&:hover': {
            backgroundColor: '#f34d16',
          },
        },

        outlined: {
          borderColor: '#dd2763',
          '&:hover': {
            color: '#fff',
            backgroundColor: '#dd2763',
          },
        },
        outlinedError: {
          borderColor: '#f34d16',
          '&:hover': {
            borderColor: '#f34d16',
            backgroundColor: '#f34d16',
          },
        },
        outlinedSecondary: {
          color: '#dd2763',
          backgroundColor: '#fef6f8',
          '&:hover': {
            color: '#fff',
            borderColor: '#dd2763',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        edgeEnd: {
          marginRight: '-8px',
        },
      },
      variants: [
        {
          props: { color: 'default' },
          style: {
            color: '#878f9a',
            backgroundColor: '#f5f6f7',
            '&:hover': {
              backgroundColor: lighten('#878f9a', 0.8),
            },
          },
        },
        {
          props: { color: 'info' },
          style: {
            color: '#b7bcc2',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: lighten('#878f9a', 0.9),
            },
          },
        },
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: '#fcedf2',

            '&:hover': {
              color: '#fff',
              backgroundColor: '#dd2763',
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: '#fff',
            backgroundColor: '#dd2763',

            '&:hover': {
              color: '#dd2763',
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            backgroundColor: '#fff8f6',
            color: '#f34d16',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#f34d16',
            },
          },
        },
        {
          props: { color: 'warning' },
          style: {
            backgroundColor: '#fef9e5',
            color: '#ffaa21',
            '&:hover': {
              color: '#fef9e5',
              backgroundColor: '#ffaa21',
            },
          },
        },
        {
          props: { size: 'xsmall' },
          style: {
            fontSize: '1rem',
            padding: '3px',
          },
        },
      ],
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'medium' },
          style: {
            fontSize: '1.25rem',
          },
        },
        {
          props: { fontSize: 'xsmall' },
          style: {
            fontSize: '1rem',
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused, &.Mui-error': {
            color: '#878f9a',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: '1px solid #e7e8eb',
          borderRadius: '8px',
          backgroundColor: '#fff',
          '&::before, &::after': {
            display: 'none',
          },
          input: {
            borderRadius: '8px',
            backgroundColor: '#fff',
          },
          '.text-filled &': {
            borderColor: '#878f9a',
          },
          '&:hover, &.Mui-focused': {
            borderColor: '#dd2763',
            backgroundColor: '#fff',
            boxShadow: '0 8px 8px rgba(57, 154, 205, 0.1)',
          },
          '&.Mui-error': {
            borderColor: '#f34d16',
          },
          '&.Mui-disabled': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          boxShadow: 'none',
          width: '470px',
        },
        root: {
          '.MuiBackdrop-root': {
            backgroundColor: 'rgba(15, 30, 53, .3)',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '34px 34px 26px',
          fontSize: '24px',
          textAlign: 'center',
          '@media (min-width: 900px)': {
            paddingLeft: '50px',
            paddingRight: '50px',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
          '@media (min-width: 900px)': {
            padding: '16px 50px',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
          '@media (min-width: 900px)': {
            padding: '16px 50px 50px',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 1px 1px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover .MuiCardActionArea-focusHighlight': {
            opacity: '0',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          top: '12px',
          right: '12px',
          height: '24px',
          minWidth: '24px',
          padding: '0 5px',
          color: '#fff',
          borderRadius: '15px',
          boxShadow: '0 0 0 1px #fff',
        },
        dot: {
          top: '5px',
          right: '5px',
          height: '10px',
          minWidth: '10px',
          padding: '0',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          justifyContent: 'space-between',
          padding: '66px 24px',
          width: '338px',
        },
        root: {
          '.MuiBackdrop-root': {
            backgroundColor: 'rgba(15, 30, 53, .3)',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '.MuiTypography-root': {
            lineHeight: '1.25em',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&.Mui-selected, &.Mui-selected:hover, &:hover': {
            background: 'transparent',
            color: '#dd2763',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#ee93b1',
            '.MuiSvgIcon-root': {
              fill: '#dd2763',
            },
          },

          '.MuiSvgIcon-root': {
            fontSize: '18px',
          },
        },
      },
    },
  },
});

export default theme;
