import type { FC } from 'react';

import Typography from '@mui/material/Typography';

import { getDifferenceInDaysWithText } from '../../../utils/date';

interface FeedTimeAgoDividerProps {
  show: boolean;
  timestamp: number;
}

const FeedTimeAgoDivider: FC<FeedTimeAgoDividerProps> = ({ show, timestamp }) => {
  if (show) {
    return (
      <Typography
        variant="body2"
        sx={{
          fontSize: '12px',
          marginBottom: '14px',
          textTransform: 'capitalize',
        }}
      >
        {getDifferenceInDaysWithText(Number(timestamp))}
      </Typography>
    );
  }

  return null;
};

export default FeedTimeAgoDivider;
