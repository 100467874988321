import type { FC } from 'react';

import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import ButtonIcon from '../../components/common/buttons/ButtonIcon';
import { FlexContainer } from '../../components/common/styled-components/containers';

const HandlesPagination: FC = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const handlePrevPage = () => {
    if (page !== 0) {
      apiRef.current.setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < pageCount) {
      apiRef.current.setPage(page + 1);
    }
  };

  return (
    <FlexContainer sx={{ width: '100%' }}>
      <FlexContainer sx={{ alignItems: 'center', flexGrow: 1 }}>
        <Typography variant="subtitle1">Page</Typography>
        <Typography variant="body1">
          &nbsp;{page + 1} of {pageCount}
        </Typography>
      </FlexContainer>

      <FlexContainer>
        <ButtonIcon color="primary" onClick={handlePrevPage} disabled={page === 0}>
          <KeyboardArrowLeft aria-label="page left" />
        </ButtonIcon>

        <Box sx={{ width: '1rem' }} />

        <ButtonIcon color="primary" onClick={handleNextPage} disabled={page + 1 === pageCount}>
          <KeyboardArrowRight aria-label="page right" />
        </ButtonIcon>
      </FlexContainer>
    </FlexContainer>
  );
};

export default HandlesPagination;
