import {
  type FC,
  type ChangeEventHandler,
  type FocusEventHandler,
  useMemo,
  type FormEventHandler,
} from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import type { AccountType } from '../../models';

import { useAuth } from '../../context/auth.context';

import { Routes } from '../../constants/routes';

import useSignupPageReducer, { InitialStateKeys } from './SignupPage.state';

import ErrorMessage from '../../components/common/error-message/ErrorMessage';
import PasswordInput from '../../components/common/form-fields/PasswordInput';
import TextField from '../../components/common/form-fields/TextField';
import DividerOr from '../../components/common/divider-or/DividerOr';

import { LoginButtonContainer, FormContainer } from '../styled-components';

interface SignupFormComponentProps {
  accountType: AccountType;
  optional?: boolean;
}

const SignupFormComponent: FC<SignupFormComponentProps> = props => {
  const { optional, accountType } = props;

  const { state, dispatchEvent } = useSignupPageReducer();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const disableCreate = useMemo(() => {
    const fields = Object.keys(state) as Array<InitialStateKeys>;

    return optional
      ? fields.filter(field => field !== 'companyName').some(field => !state[field].value)
      : fields.some(field => !state[field].value);
  }, [state, optional]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    dispatchEvent({
      type: 'change',
      fieldName: target.name,
      fieldValue: target.value,
    });
  };

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({ target }) => {
    dispatchEvent({
      type: 'blur',
      fieldName: target.name,
      fieldValue: target.value,
    });
  };

  const handleLogin = (): void => {
    auth.resetError('signup');

    navigate(Routes.login.route, {
      state: {
        from: location,
        routeName: Routes.login.routeName,
      },
    });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    auth.signup(
      {
        accountType,
        username: state.username.value,
        password: state.password.value,
        companyName: state.companyName.value,
        email: state.email.value,
      },
      () => {
        navigate(Routes.login.route, {
          state: {
            from: location,
            routeName: Routes.login.routeName,
          },
        });
      },
    );
  };

  return (
    <form method="POST" noValidate onSubmit={handleSubmit}>
      <FormContainer>
        <TextField
          name="username"
          label="Name"
          helperText="Required field"
          error={state.username.error}
          success={state.username.success}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          type="email"
          name="email"
          label="Email address"
          helperText="Required field"
          error={state.email.error}
          success={state.email.success}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          name="companyName"
          label="Company name"
          helperText={optional ? undefined : 'Required field'}
          error={optional ? undefined : state.companyName.error}
          success={state.companyName.success}
          onChange={handleChange}
          onBlur={handleBlur}
          optional={optional}
        />
        <PasswordInput
          name="password"
          label="Password"
          helperText="Required field"
          error={state.password.error}
          success={state.password.success}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <ErrorMessage margin="0">{auth.signupError}</ErrorMessage>
      </FormContainer>

      <Stack spacing={2}>
        <Button variant="contained" size="large" type="submit" disabled={disableCreate}>
          Create now
        </Button>

        <div>
          <DividerOr />
        </div>

        <LoginButtonContainer
          variant="outlined"
          size="large"
          color="secondary"
          endIcon={<ArrowForwardIcon fontSize="small" />}
          onClick={handleLogin}
        >
          Log in
        </LoginButtonContainer>
      </Stack>
    </form>
  );
};

export default SignupFormComponent;
