import type { FC } from 'react';

import { useLocation, Navigate } from 'react-router-dom';

import type { JSXElement } from '../../../models';

import { useAuth } from '../../../context/auth.context';
import { Routes } from '../../../constants/routes';

interface RedirectAuthProps {
  children: JSXElement | Array<JSXElement>;
}

const RedirectAuth: FC<RedirectAuthProps> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.authInfo.jwt) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={Routes.login.route} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default RedirectAuth;
