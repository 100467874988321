import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';

import type { AxiosError } from 'axios';

import type { Company, CompanyInfo, Signin, Signout } from '../models';

import {
  registerCompany,
  login,
  logout,
  getCompanyInfoById,
  getCompanyInfoAll,
  deleteCompanyById,
  updateCompanyInfoById,
} from '../api/company';

export const useMutationRegisterCompany = (): UseMutationResult<
  void | Company,
  AxiosError,
  Company
> => {
  return useMutation((data: Company) => registerCompany(data));
};

export const useMutationLogin = (): UseMutationResult<any, AxiosError, Signin> => {
  return useMutation((data: Signin) => login(data));
};

export const useMutationLogout = (
  jwt: string,
): UseMutationResult<void | Signout, AxiosError, Signout> => {
  return useMutation((data: Signout) => logout(data, jwt));
};

export const useQueryCompanyInfoAll = (jwt: string) => {
  return useQuery(['all-company'], () => getCompanyInfoAll(jwt));
};

export const useQueryCompanyInfoById = (id: string, jwt: string) => {
  return useQuery(['company-id', id], () => getCompanyInfoById(id, jwt), {
    enabled: Boolean(id),
  });
};

export const useMutationUpdateCompanyInfo = (
  jwt: string,
): UseMutationResult<void | CompanyInfo, AxiosError> => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: any & { id: string }) => {
      const { id, ...restData } = data;

      return updateCompanyInfoById(id, restData, jwt);
    },
    {
      onSuccess: (_d, variables, _c) => {
        queryClient.refetchQueries(['all-company']);
        queryClient.refetchQueries(['company-id', variables.id]);
        queryClient.refetchQueries(['entity-info', variables.id, 'COMPANY']);
      },
    },
  );
};

export const useMutationUpdateCompanyProfile = (
  companyId: string,
  jwt: string,
): UseMutationResult<void | CompanyInfo, AxiosError> => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => updateCompanyInfoById(companyId, data, jwt), {
    onSuccess: () => {
      queryClient.refetchQueries(['all-company']);
      queryClient.refetchQueries(['company-id', companyId]);
      queryClient.refetchQueries(['entity-info', companyId, 'COMPANY']);
    },
  });
};

export const useMutationCompaniesDeleteFromTable = (
  jwt: string,
): UseMutationResult<Array<void | CompanyInfo>, AxiosError, Array<string>> => {
  const queryClient = useQueryClient();

  return useMutation(
    (ids: Array<string>) => {
      const deleteRequests = ids.map(id => deleteCompanyById(id, jwt));

      return Promise.all(deleteRequests);
    },
    {
      onSuccess: (_d, variables, _c) => {
        variables.forEach(id => {
          queryClient.removeQueries(['company-id', id]);
        });
        queryClient.refetchQueries(['all-company']);
        queryClient.refetchQueries(['all-users']);
      },
    },
  );
};
