import { type FC, useMemo, useState, Fragment } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Container from '@mui/system/Container';

import {
  useMutationPinUnpin,
  useMutationDeleteHandle,
  useInfiniteQueryAllTweetsByHandleId,
} from '../../queries/tweets';

import { Routes } from '../../constants/routes';

import type { Handle, StockTwit } from '../../models';

import Settings from '../../constants/settings';
import { HttpStatusCode } from '../../configs/catch-errors';

import { getDifferenceInDaysWithText } from '../../utils/date';
import generatePdf, { convertStockTwitsToHandleItem } from '../../utils/generate-pdf';

import ErrorPageRequest from '../error/ErrorPageRequest';
import ErrorPageBackToDashboard from '../error/ErrorPageBackToDashboard';

import InProgress from '../../components/common/in-progress/InProgress';
import TweeterHandlesListComponent from '../../components/common/tweeter-handles-sidebar/TweeterHandlesListComponent';
import DialogExportHandlesInfo, {
  ExportHandlesFields,
} from '../../components/dialogs/export-handles-info/DialogExportHandlesInfo';
import DialogSelectFolder from '../../components/dialogs/select-folder/DialogSelectFolder';
import DialogDelete from '../../components/dialogs/delete/DialogDelete';
import ButtonLoading from '../../components/common/buttons/ButtonLoading';
import FeedTimeAgoDivider from '../../components/common/feed-time-ago-divider/FeedTimeAgoDivider';

import {
  HomePageBasicContainer,
  ProfilePageMainContainer,
  ProfilePageWrapper,
  TwitterHandlesSidebar,
} from '../styled-components';

import HandleProfileCard from './HandleProfileCard';
import HandleProfileFeedItem from './HandleProfileFeedItem';
import HandleProfileFeedItemStockTwits from './HandleProfileFeedItemStockTwits';

interface HandleProfilePageProps {
  entityId: string;
  handles: Array<Handle>;
  jwt: string;
}

const HandleProfilePage: FC<HandleProfilePageProps> = ({ entityId, handles, jwt }) => {
  const routerParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const stockTwit = useMemo(() => {
    let handle: Handle | undefined;

    if (handles.length) {
      handle = handles.find(handle => handle.id === Number(routerParams.id));
    } else {
      handle = handles[0];
    }

    return handle;
  }, [handles, routerParams.id]);

  const {
    data,
    isError,
    error,
    isLoading,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQueryAllTweetsByHandleId({
    handleId: Number(routerParams.id),
    isStockTwits: stockTwit?.stockTwits || false,
    tickerId: stockTwit?.username || '',
    pageNumberInitial: Settings.DEFAULT_PAGE_NUMBER,
    jwt,
  });
  const pinUnpin = useMutationPinUnpin(jwt);
  const deleteHandle = useMutationDeleteHandle(entityId, jwt);

  const [exportHandlesInfoDialog, setExportHandlesInfoDialog] = useState(false);
  const [handleItemId, setHandleItemId] = useState<number>();
  const [stockTwitItem, setStockTwitItem] = useState<StockTwit>();
  const [isStockTwits, setIsStockTwits] = useState(false);
  const [selectFolderDialog, setSelectFolderialog] = useState(false);
  const [deleteHandleDialog, setDeleteHandleDialog] = useState(false);

  const handlesWithoutCurrentHandle = useMemo(() => {
    if (data) {
      return handles.filter(handle => handle.id !== Number(routerParams.id));
    }

    return [];
  }, [handles, data, routerParams.id]);

  const handleOpenExportDialog = (id: number): void => {
    setExportHandlesInfoDialog(true);
    setHandleItemId(id);
    setIsStockTwits(false);
  };

  const handleOpenExportDialogStockTwits = (data: StockTwit): void => {
    setExportHandlesInfoDialog(true);
    setStockTwitItem(data);
    setIsStockTwits(true);
  };

  const handleCloseExportDialog = (): void => {
    setExportHandlesInfoDialog(false);
  };

  const handleConfirmExportDialog = (exportData: ExportHandlesFields): void => {
    const handleItem = data?.pages[0]?.tweetHandleResponseDTOList[0]?.tweetModelDTOS.find(
      item => item.id === handleItemId,
    );

    if (isStockTwits) {
      if (stockTwitItem) {
        generatePdf({
          exportData,
          handleItem: convertStockTwitsToHandleItem(stockTwitItem),
          username: 'StockTwits',
          isStockTwits: true,
          cb: () => setExportHandlesInfoDialog(false),
        });
      }
    } else {
      if (handleItem) {
        generatePdf({
          exportData,
          handleItem,
          username: `${data?.pages[0].tweetHandleResponseDTOList[0].username}`,
          cb: () => setExportHandlesInfoDialog(false),
        });
      }
    }
  };

  const handleOpenFolderDialog = (): void => {
    setSelectFolderialog(true);
  };

  const handleCloseFolderDialog = (): void => {
    setSelectFolderialog(false);
  };

  const onOpenDialogDeleteHandle = (): void => {
    setDeleteHandleDialog(true);
  };

  const onCloseDialogDeleteHandle = (): void => {
    setDeleteHandleDialog(false);
  };

  const handlePinUnpin = (handle: Handle): void => {
    pinUnpin.mutate({
      companyId: entityId,
      pinned: !handle.pinned,
      handleId: handle.id,
    });
  };

  const handleDelete = (handle: Handle): void => {
    deleteHandle.mutate({
      authorNames: handle.username,
      companyId: handle.companyDTO ? entityId : undefined,
      handleId: handle.id,
      userId: !handle.companyDTO ? handle.userDTO?.id : undefined,
    });
  };

  const handleConfirmDialogDeleteHandle = (): void => {
    deleteHandle.mutate(
      {
        authorNames: String(data?.pages[0]?.tweetHandleResponseDTOList[0]?.username),
        companyId: data?.pages[0]?.tweetHandleResponseDTOList[0]?.companyDTO ? entityId : undefined,
        handleId: Number(routerParams.id),
        userId: !data?.pages[0]?.tweetHandleResponseDTOList[0]?.companyDTO
          ? data?.pages[0]?.tweetHandleResponseDTOList[0]?.userDTO?.id
          : undefined,
      },
      {
        onSuccess: () => {
          navigate(Routes.home.route, {
            state: {
              from: location,
              routeName: Routes.home.routeName,
            },
            replace: true,
          });
        },
      },
    );
  };

  const handleFetchNextPage = (): void => {
    fetchNextPage();
  };

  if (isLoading || deleteHandle.isLoading || pinUnpin.isLoading) {
    return <InProgress isOpen={isLoading || deleteHandle.isLoading || pinUnpin.isLoading} />;
  }

  if (
    isError &&
    (error.response?.status === HttpStatusCode.FORBIDDEN ||
      error.response?.status === HttpStatusCode.BAD_REQUEST)
  ) {
    return <ErrorPageBackToDashboard />;
  }

  if (isError && error.response?.status === HttpStatusCode.NOT_FOUND) {
    return <ErrorPageRequest onRefetch={refetch} />;
  }

  if (data) {
    const isAllowSidebar = handles.length > 1;

    return (
      <HomePageBasicContainer>
        <Container maxWidth="lg" sx={{ display: 'flex' }}>
          <ProfilePageWrapper sx={{ flexGrow: 1 }}>
            <HandleProfileCard
              data={data.pages[0]?.tweetHandleResponseDTOList[0]}
              onOpenDialogDeleteHandle={onOpenDialogDeleteHandle}
              onPinUnpin={handlePinUnpin}
            />

            <ProfilePageMainContainer>
              {data.pages.map((page, pageIndex) => {
                return (
                  <Fragment key={`page-${pageIndex}`}>
                    {page.tweetHandleResponseDTOList.map(handleItem => {
                      const avatarSrc = handleItem.profileImageUrl;

                      if (handleItem.stockTwits && handleItem.stockTwitResponsesDTO?.length) {
                        return handleItem.stockTwitResponsesDTO.map(
                          (stockTwit, stockTwitIndex, stockTwitArray) => {
                            const prevFeed = stockTwitArray[stockTwitIndex - 1];
                            const prevFeedDiff = prevFeed
                              ? getDifferenceInDaysWithText(Number(prevFeed.stockTwitTimestamp))
                              : undefined;
                            const currDiff = getDifferenceInDaysWithText(
                              Number(stockTwit.stockTwitTimestamp),
                            );

                            return (
                              <Fragment
                                key={`fragment-${stockTwit.stockTwitTimestamp}-${stockTwitIndex}`}
                              >
                                <FeedTimeAgoDivider
                                  show={prevFeedDiff !== currDiff}
                                  timestamp={stockTwit.stockTwitTimestamp}
                                />

                                <HandleProfileFeedItemStockTwits
                                  key={`${stockTwit.stockTwitTimestamp}-${stockTwitIndex}`}
                                  data={stockTwit}
                                  avatarSrc={avatarSrc}
                                  onOpenExportDialog={handleOpenExportDialogStockTwits}
                                  onOpenFolderDialog={handleOpenFolderDialog}
                                />
                              </Fragment>
                            );
                          },
                        );
                      }

                      if (handleItem.tweetModelDTOS) {
                        return handleItem.tweetModelDTOS.map(
                          (tweetModelDTO, tweetModelDTOIndex, tweetModelDTOArray) => {
                            const prevFeed = tweetModelDTOArray[tweetModelDTOIndex - 1];
                            const nextFeed = tweetModelDTOArray[tweetModelDTOIndex + 1];
                            const relatedFeed =
                              nextFeed &&
                              tweetModelDTO.tweetFieldsModelDTO.conversationId ===
                                nextFeed.tweetFieldsModelDTO.conversationId;
                            const prevFeedDiff = prevFeed
                              ? getDifferenceInDaysWithText(
                                  Number(prevFeed.tweetFieldsModelDTO.createdAt),
                                )
                              : undefined;
                            const currDiff = getDifferenceInDaysWithText(
                              Number(tweetModelDTO.tweetFieldsModelDTO.createdAt),
                            );

                            return (
                              <Fragment
                                key={`fragment-${tweetModelDTO.tweetFieldsModelDTO.createdAt}-${tweetModelDTOIndex}`}
                              >
                                <FeedTimeAgoDivider
                                  show={prevFeedDiff !== currDiff}
                                  timestamp={tweetModelDTO.tweetFieldsModelDTO.createdAt}
                                />

                                <HandleProfileFeedItem
                                  key={`${tweetModelDTO.tweetFieldsModelDTO.createdAt}-${tweetModelDTOIndex}`}
                                  data={tweetModelDTO}
                                  relatedFeed={relatedFeed}
                                  username={handleItem.username}
                                  onOpenExportDialog={handleOpenExportDialog}
                                  onOpenFolderDialog={handleOpenFolderDialog}
                                />
                              </Fragment>
                            );
                          },
                        );
                      }

                      return null;
                    })}
                  </Fragment>
                );
              })}

              {hasNextPage ? (
                <ButtonLoading
                  fullWidth
                  onClick={handleFetchNextPage}
                  disabled={!hasNextPage || isFetchingNextPage}
                  isLoading={isFetchingNextPage}
                >
                  Load more
                </ButtonLoading>
              ) : null}
            </ProfilePageMainContainer>
          </ProfilePageWrapper>

          {isAllowSidebar ? (
            <TwitterHandlesSidebar variant="permanent" anchor="right">
              <TweeterHandlesListComponent
                handles={handlesWithoutCurrentHandle}
                onDelete={handleDelete}
                onPinUnpin={handlePinUnpin}
                isLoading={deleteHandle.isLoading || pinUnpin.isLoading}
              />
            </TwitterHandlesSidebar>
          ) : null}

          {selectFolderDialog ? <DialogSelectFolder onClose={handleCloseFolderDialog} /> : null}

          {exportHandlesInfoDialog ? (
            <DialogExportHandlesInfo
              onClose={handleCloseExportDialog}
              onConfirm={handleConfirmExportDialog}
            />
          ) : null}

          {deleteHandleDialog ? (
            <DialogDelete
              title="Do you want to delete the handle?"
              onClose={onCloseDialogDeleteHandle}
              onConfirm={handleConfirmDialogDeleteHandle}
              isLoading={deleteHandle.isLoading}
              error={deleteHandle.error?.message || ''}
            />
          ) : null}
        </Container>
      </HomePageBasicContainer>
    );
  }

  return null;
};

export default HandleProfilePage;
