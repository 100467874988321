import { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { Routes } from '../constants/routes';

const useEffectAuthNavigateToDashboard = (jwt: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (jwt) {
      navigate(Routes.home.route, {
        state: {
          from: location,
          routeName: Routes.home.routeName,
        },
        replace: true,
      });
    }
  }, [jwt, location, navigate]);
};

export default useEffectAuthNavigateToDashboard;
