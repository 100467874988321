import httpBuilder, { headersAuthorization } from '../configs/axios';
import catchError from '../configs/catch-errors';
import { ApiStocktwits } from '../constants/api';

import type { StockTwitsInfo, GetStockTwitInfoSearchOptions } from '../models';

export const getStockTwitInfoSearch = (options: GetStockTwitInfoSearchOptions) => {
  return httpBuilder
    .get<Array<StockTwitsInfo>>(ApiStocktwits.search, {
      params: {
        ...options,
      },
      ...headersAuthorization(options.jwt),
    })
    .then(response => response.data)
    .catch(catchError);
};
