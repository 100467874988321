import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Route } from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import SignupPage from './pages/signup/SignupPage';
import SigninPageLogin from './pages/signin/SigninPageLogin';
import HomePage from './pages/home/HomePage';
import ErrorPageBackToIndex from './pages/error/ErrorPageBackToIndex';

import theme from './theme';
import reportWebVitals from './reportWebVitals';
import queryClient from './query-client';
import AuthProvider from './context/auth.context';
import sentryInit, { SentryRoutes } from './sentry-init';

if (process.env.NODE_ENV === 'production') {
  sentryInit();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <BrowserRouter>
            <SentryRoutes>
              <Route index element={<SignupPage />} />
              <Route path="/login" element={<SigninPageLogin />} />
              <Route path="/home/*" element={<HomePage />} />
              <Route path="*" element={<ErrorPageBackToIndex />} />
            </SentryRoutes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
