import { useState, type FC, type SyntheticEvent } from 'react';

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BrowseGalleryOutlinedIcon from '@mui/icons-material/BrowseGalleryOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import type { FunctionWithArg, ExpandedPanel, FunctionWithTwoArgs } from '../../../models';

import useGetTimeRange from '../../../hooks/use-get-time-range';

import theme from '../../../theme';
import TimeTabsComponent from './TimeTabsComponent';
import { AccordionStyled } from './styled-components';

interface DateTimeFilterProps {
  onChangeDateTime: FunctionWithArg<number>;
  expandedPanel: ExpandedPanel;
  onExpandPanel: FunctionWithArg<ExpandedPanel, FunctionWithTwoArgs<SyntheticEvent, boolean>>;
  dateRange?: number;
}

const DateTimeFilter: FC<DateTimeFilterProps> = props => {
  const { onChangeDateTime, expandedPanel, onExpandPanel, dateRange } = props;

  const smallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const timeRange = useGetTimeRange(dateRange);
  const [dateInPicker, setDateInPicker] = useState((dateRange && new Date(dateRange)) || null);
  const [subDate, setSubDate] = useState((dateRange && new Date(dateRange)) || new Date());

  const handleChangeDatePicker = (newValue: Date | null) => {
    setDateInPicker(newValue);

    if (newValue) {
      onChangeDateTime(newValue.getTime());
      setSubDate(newValue);
    }
  };

  const handleChangeTimePicker = (newDateTime: Date | null): void => {
    if (newDateTime) {
      onChangeDateTime(newDateTime.getTime());
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <Typography variant="body2" sx={{ fontSize: '0.75em', margin: '0 0 8px 16px' }}>
          Filter by
        </Typography>

        <Stack width={{ md: '253px' }}>
          <AccordionStyled expanded={expandedPanel === 'panel1'} onChange={onExpandPanel('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="date-filter"
              id="date-filter"
            >
              <CalendarMonthOutlinedIcon sx={{ marginRight: 1 }} />

              <Typography>{smallScreens ? 'Select date' : 'Date and time'}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={dateInPicker}
                onChange={handleChangeDatePicker}
                renderInput={({ InputProps }) => <>{InputProps?.endAdornment}</>}
              />

              {!smallScreens ? (
                <TimeTabsComponent
                  onSetDatestamp={handleChangeTimePicker}
                  subDate={subDate}
                  timeRange={timeRange}
                />
              ) : null}
            </AccordionDetails>
          </AccordionStyled>

          {smallScreens ? (
            <AccordionStyled
              expanded={expandedPanel === 'panel2'}
              onChange={onExpandPanel('panel2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-filter"
                id="time-filter"
              >
                <BrowseGalleryOutlinedIcon sx={{ marginRight: 1 }} />
                <Typography>Select time</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <TimeTabsComponent
                  onSetDatestamp={handleChangeTimePicker}
                  subDate={subDate}
                  timeRange={timeRange}
                />
              </AccordionDetails>
            </AccordionStyled>
          ) : null}
        </Stack>
      </div>
    </LocalizationProvider>
  );
};

export default DateTimeFilter;
