import { type ReactElement, useMemo } from 'react';

import type { SxProps, Theme } from '@mui/system';

import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';

import { Routes } from '../../../constants/routes';

interface TabConfigState {
  routeName: string;
}

interface TabConfig {
  label: string;
  value: string;
  to: string;
  icon: ReactElement;
  state: TabConfigState;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

const tabsConfig: Array<TabConfig> = [
  {
    label: Routes.home.routeName,
    value: Routes.home.route,
    to: Routes.home.route,
    icon: <DashboardCustomizeOutlinedIcon />,
    sx: { paddingLeft: 0 },
    state: { routeName: Routes.home.routeName },
  },
  {
    label: Routes.handles.routeName,
    value: Routes.handles.route,
    to: Routes.handles.route,
    icon: <Groups3OutlinedIcon />,
    state: { routeName: Routes.handles.routeName },
  },
  {
    label: Routes.settings.routeName,
    value: Routes.settings.route,
    to: Routes.settings.route,
    icon: <SettingsOutlinedIcon />,
    state: { routeName: Routes.settings.routeName },
    disabled: true,
  },
  {
    label: Routes.support.routeName,
    value: Routes.support.route,
    to: Routes.support.route,
    icon: <PrivacyTipOutlinedIcon />,
    state: { routeName: Routes.support.routeName },
    disabled: true,
  },
];

const tabsConfigSuperAdmin: Array<TabConfig> = [
  {
    label: Routes.home.routeName,
    value: Routes.home.route,
    to: Routes.home.route,
    icon: <DashboardCustomizeOutlinedIcon />,
    sx: { paddingLeft: 0 },
    state: { routeName: Routes.home.routeName },
  },
  {
    label: Routes.settings.routeName,
    value: Routes.settings.route,
    to: Routes.settings.route,
    icon: <SettingsOutlinedIcon />,
    state: { routeName: Routes.settings.routeName },
    disabled: true,
  },
  {
    label: Routes.support.routeName,
    value: Routes.support.route,
    to: Routes.support.route,
    icon: <PrivacyTipOutlinedIcon />,
    state: { routeName: Routes.support.routeName },
    disabled: true,
  },
];

const useTabsConfig = (isSuperAdmin: boolean) => {
  return useMemo(() => {
    if (isSuperAdmin) {
      return tabsConfigSuperAdmin;
    }

    return tabsConfig;
  }, [isSuperAdmin]);
};

export default useTabsConfig;
