import { styled } from '@mui/material/styles';
import { common } from '@mui/material/colors';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ButtonBase from '@mui/material/ButtonBase';

import { alignItemsCenter } from '../styled-components/primitives';

const NavigationBasicContainer = styled('div')`
  background-color: ${common.white};
`;

export const TopBarContainer = styled(NavigationBasicContainer)(({ theme }) => ({
  paddingTop: '11px',
  paddingBottom: '11px',
  height: '66px',
  position: 'fixed',
  left: '0',
  right: '0',
  zIndex: theme.zIndex.drawer + 100,

  '.MuiBackdrop-root': {
    zIndex: -1,
  },
}));

export const NavigationContainer = styled(NavigationBasicContainer)(({ theme }) => ({
  height: '60px',

  '.MuiGrid-root': {
    height: '60px',
  },

  [theme.breakpoints.up('md')]: {
    height: 'auto',
    borderTop: `1px solid ${theme.palette.textLight.light2}`,
    borderBottom: `1px solid ${theme.palette.textLight.light2}`,

    '.MuiGrid-root': {
      height: 'auto',
    },
  },
}));

export const NavigationAgencyWrapper = styled('div')(
  ({ theme }) => `
  padding: 14px 16px;
  background-color:  ${theme.palette.textLight.light2};
  border-radius: 8px;
`,
);

export const NavigationSwipeableDrawerContainer = styled(SwipeableDrawer)`
  .MuiListItemIcon-root {
    min-width: 35px;
    color: inherit;
  }

  .MuiListItemText-primary {
    font-size: 1.5em;
  }

  .MuiSvgIcon-root {
    font-size: 1.25em;
  }

  .MuiListItemButton-root,
  .MuiListItem-root {
    padding: 0;
  }

  .MuiList-root {
    margin: 35px 0;
  }
`;

export const NavigationSwipeableDrawerCloseWrapper = styled('div')`
  position: absolute;
  top: 56px;
  right: 24px;

  .MuiIconButton-root {
    font-size: 1em;
  }
`;

export const PromoContainer = styled('div')`
  ${alignItemsCenter}
  display: inline-flex;
  margin: 0 6px;
  padding: 2px 6px;
  color: ${common.white};
  font-size: 16px;
  border-radius: 4px;
  background: #ffaa21;

  .MuiSvgIcon-root {
    margin-right: 5px;
  }

  @media (min-width: 900px) {
    padding: 4px 6px;
  }
`;

export const LogoButtonContainer = styled(ButtonBase)`
  width: 138px;
  height: 28px;
  margin-right: 125px;
`;
