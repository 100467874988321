import catchError from '../configs/catch-errors';

import httpBuilder, { headersAuthorization } from '../configs/axios';

import type { CompanyInfo, AccountType, User } from '../models';

import { ApiCompany, ApiUsers } from '../constants/api';

export const getEntityInfo = (
  id: string,
  accountType: AccountType,
  jwt: string,
): Promise<void | CompanyInfo | User> => {
  if (accountType === 'COMPANY') {
    return httpBuilder
      .get<CompanyInfo>(ApiCompany.byId(id), headersAuthorization(jwt))
      .then(response => response.data)
      .catch(catchError);
  }

  return httpBuilder
    .get<User>(ApiUsers.byId(id), headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};
