import type { FC } from 'react';

import type { EmptyFunction } from '../../models';

import Button from '../../components/common/buttons/Button';

import { PageErrorContainer } from '../styled-components';

import ErrorPage from './ErrorPage';

interface ErrorPageRequestProps {
  onRefetch: EmptyFunction;
}

const ErrorPageRequest: FC<ErrorPageRequestProps> = ({ onRefetch }) => {
  const handleClick = (): void => {
    onRefetch();
  };

  return (
    <PageErrorContainer>
      <ErrorPage
        backToElement={<Button onClick={handleClick}>Refetch</Button>}
        infoMessage="Ooops, something went wrong."
      />
    </PageErrorContainer>
  );
};

export default ErrorPageRequest;
