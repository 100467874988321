import { css } from '@mui/material/styles';

export const displayFlex = css`
  display: flex;
`;

export const flexColumn = css`
  flex-direction: column;
`;

export const flexRow = css`
  flex-direction: row;
`;

export const alignItemsCenter = css`
  align-items: center;
`;

export const alignItemsStart = css`
  align-items: flex-start;
`;

export const alignItemsEnd = css`
  align-items: flex-end;
`;

export const justifyContentCenter = css`
  justify-content: center;
`;

export const justifyContentSpaceBetween = css`
  justify-content: space-between;
`;

export const justifyContentSpaceAround = css`
  justify-content: space-around;
`;

export const justifyContentFlexStart = css`
  justify-content: flex-start;
`;

export const justifyContentEnd = css`
  justify-content: flex-end;
`;

export const flexGrow1 = css`
  flex-grow: 1;
`;

export const flexShrink0 = css`
  flex-shrink: 0;
`;
