import type { GridColDef } from '@mui/x-data-grid';

import RenderAdminIcon from './RenderAdminIcon';
import RenderMembership from './RenderMembership';
import RenderStatus from './RenderStatus';
import RenderJoinedDate from './RenderJoinedDate';
import RenderTrackNumber from './RenderTrackNumber';
import { RenderCellCompanyAvatar, RenderCellUserAvatar } from './RenderUsername';

const sortable = false;
const columnsCommon: Array<GridColDef> = [
  { field: 'email', headerName: 'Email address', sortable, width: 160 },
  { field: 'agencyName', headerName: 'Agency name', sortable, width: 140 },
  {
    field: 'date',
    headerName: 'Joined',
    sortable,
    width: 120,
    renderCell: RenderJoinedDate,
  },
  {
    field: 'membership',
    headerName: 'Membership type',
    sortable,
    width: 120,
    renderCell: RenderMembership,
  },
  // @TODO unhide after backend is ready
  { field: 'expires', headerName: 'Expires in', sortable, width: 100, hide: true },
  // @TODO unhide after backend is ready
  {
    field: 'status',
    headerName: 'Status',
    sortable,
    width: 60,
    renderCell: RenderStatus,
    hide: true,
  },
];
const columnsCompany: Array<GridColDef> = [
  {
    field: 'username',
    headerName: 'Agency name',
    sortable,
    width: 170,
    renderCell: RenderCellCompanyAvatar,
  },
  { field: 'fullName', headerName: 'User name', sortable, width: 160 },
];
const columnsUser: Array<GridColDef> = [
  {
    field: 'username',
    headerName: 'Usernames',
    sortable,
    width: 170,
    renderCell: RenderCellUserAvatar,
  },
  // @TODO unhide after backend is ready
  { field: 'fullName', headerName: 'Full Name', sortable, width: 160, hide: true },
];

export const columnsUserAll: Array<GridColDef> = [
  ...columnsUser,
  ...columnsCommon,
  {
    field: 'trackAmount',
    headerName: 'Track number',
    sortable,
    width: 100,
    renderCell: RenderTrackNumber,
  },
  {
    field: 'admin',
    headerName: 'Admin',
    sortable,
    width: 60,
    renderCell: RenderAdminIcon,
  },
];

export const columnsCompanyAll: Array<GridColDef> = [
  ...columnsCompany,
  ...columnsCommon,
  {
    field: 'trackAmount',
    headerName: 'Track number',
    sortable,
    width: 100,
    renderCell: RenderTrackNumber,
  },
];
