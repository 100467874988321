import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';

import ChatAddIcon from '../icon-chat-add/ChatAddIcon';

export const IconHandleContainer = styled('div')`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.2em;

  svg {
    margin-right: 5px;
    font-size: 1em;
  }
`;

export const MarkunreadMailboxIconContainer = styled(MarkunreadMailboxOutlinedIcon)(
  ({ theme }) => `
    color: ${theme.palette.tweet.main};

    .MuiCard-root:hover & {
      color: ${theme.palette.tweet.main};
    }

    ${theme.breakpoints.up('md')} {
      color: ${theme.palette.text.secondary};
    },
  `,
);

export const PublishedWithChangesIconContainer = styled(PublishedWithChangesOutlinedIcon)(
  ({ theme }) => `
    color: ${theme.palette.retweet.main};

    .MuiCard-root:hover & {
      color: ${theme.palette.retweet.main};
    }

    ${theme.breakpoints.up('md')} {
      color: ${theme.palette.text.secondary};
    },
  `,
);

export const ChatAddIconContainer = styled(ChatAddIcon)(
  ({ theme }) => `
    color: ${theme.palette.comment.main};

    .MuiCard-root:hover & {
      color: ${theme.palette.comment.main};
    }

    ${theme.breakpoints.up('md')} {
      color: ${theme.palette.text.secondary};
    },
  `,
);

export const MarkunreadMailboxIconColorContainer = styled(MarkunreadMailboxOutlinedIcon)(
  ({ theme }) => `
    color: ${theme.palette.tweet.main};
  `,
);

export const PublishedWithChangesIconColorContainer = styled(PublishedWithChangesOutlinedIcon)(
  ({ theme }) => `
    color: ${theme.palette.retweet.main};
  `,
);

export const ChatAddIconColorContainer = styled(ChatAddIcon)(
  ({ theme }) => `
    color: ${theme.palette.comment.main};
  `,
);

export const IconHandleTextContainer = styled(Typography)`
  margin: 5px 0 0 22px;
`;

export const IconHandleTotalContainer = styled('span')`
  display: none;

  @media (min-width: 900px) {
    display: inline;
    margin-right: 5px;
  }
`;
