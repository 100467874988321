import type { FC } from 'react';

import { ButtonProps as MUIButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Button from './Button';

interface ButtonLoadingProps extends MUIButtonProps {
  isLoading?: boolean;
}

const ButtonLoading: FC<ButtonLoadingProps> = props => {
  const { children, isLoading, ...restProps } = props;

  return (
    <Button
      {...restProps}
      endIcon={
        isLoading ? <CircularProgress color="inherit" aria-label="Loading..." size="28px" /> : null
      }
    >
      {children}
    </Button>
  );
};

export default ButtonLoading;
