import { type FC, type HTMLAttributes, Fragment } from 'react';

import type { EmptyFunction, PaginatedResponseData, SearchItem } from '../../../models';

import ButtonLoading from '../buttons/ButtonLoading';

import HandlesOption from './HandlesOption';

import { MuiAutocompleteOption } from './styled-components';

interface RenderOptionProps {
  options: PaginatedResponseData<SearchItem>;
  renderProps: HTMLAttributes<HTMLLIElement>;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onFetchNextPage: EmptyFunction;
}

const RenderOption: FC<RenderOptionProps> = props => {
  const { options, renderProps, hasNextPage, isFetchingNextPage, onFetchNextPage } = props;

  if (options?.pages) {
    const { 'aria-selected': _as, ...restProps } = renderProps;

    return (
      <Fragment>
        {options.pages.map((page, pageIndex) => {
          return (
            <Fragment key={`page-${pageIndex}-${page.currFeedPage}`}>
              {page.feedDTOS.map((feedDTO, feedDTOIndex) => {
                return (
                  <MuiAutocompleteOption
                    {...restProps}
                    key={`${feedDTO.timestamp}-${feedDTOIndex}-${feedDTO.tweetId}`}
                    data-option-index={feedDTOIndex}
                  >
                    <HandlesOption data={feedDTO} />
                  </MuiAutocompleteOption>
                );
              })}
            </Fragment>
          );
        })}

        {hasNextPage ? (
          <ButtonLoading
            fullWidth
            onClick={onFetchNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
            isLoading={isFetchingNextPage}
          >
            Load more
          </ButtonLoading>
        ) : null}
      </Fragment>
    );
  }

  return null;
};

export default RenderOption;
