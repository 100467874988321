import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { Handle } from '../models';

const useFilteredHandlesMobile = (handles: Array<Handle>) => {
  const { state, pathname } = useLocation();
  const skippedHandleId = useMemo(() => {
    if (state?.handleId) {
      return state.handleId;
    }

    const pathnameParts = pathname.split('/');

    return Number(pathnameParts[pathnameParts.length - 1]);
  }, [state?.handleId, pathname]);

  return useMemo(
    () => handles.filter(handle => handle.id !== skippedHandleId),
    [handles, skippedHandleId],
  );
};

export default useFilteredHandlesMobile;
