import type { FC } from 'react';

import format from 'date-fns/format';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import useGetTweetLink from '../../hooks/use-get-tweet-link';
import useMediaFieldsHandleProfileFeedItem from '../../hooks/use-media-fields-handle-profile-feed-item';

import { urlify } from '../../utils/urlify';
import Settings from '../../constants/settings';

import type { EmptyFunction, FunctionWithArg, Tweet } from '../../models';

import ChatAddIcon from '../../components/common/icon-chat-add/ChatAddIcon';
// @TODO uncomment when BE is ready
// import FolderOpenIcon from '../../components/common/icon-folder-open/FolderOpenIcon';
import ButtonIcon from '../../components/common/buttons/ButtonIcon';
import { FlexContainer } from '../../components/common/styled-components/containers';

import HandleProfileFeedItemMedia from './HandleProfileFeedItemMedia';

import { ProfileDateTimeContainer, ProfileDateTimeSeparator } from '../styled-components';

import {
  HandleProfilePageTweetContainer,
  HandleProfilePageTweetTypeContainer,
  HandleProfilePageActionContainer,
  HandleProfilePageTweetInfoContainer,
  HandleProfilePageIconContainer,
  HandleProfilePageDivider,
  HandleProfilePageInfoContainer,
  HandleProfilePageTextContainer,
} from './styled-components';

interface HandleProfileFeedItemProps {
  data: Tweet;
  relatedFeed: boolean;
  username: string;
  onOpenExportDialog: FunctionWithArg<number>;
  onOpenFolderDialog: EmptyFunction;
}

const tweetCssClasses = {
  tweet: 'handle-tweet',
  quoted: 'handle-tweet',
  'quoted,replied_to': 'handle-tweet',
  replied_to: 'handle-reply',
  retweeted: 'handle-retweet',
};

const tweetText = {
  tweet: 'Tweet',
  quoted: 'Tweet',
  'quoted,replied_to': 'Tweet',
  replied_to: 'Reply',
  retweeted: 'Retweet',
};

export const tweetIcon = {
  tweet: <MarkunreadMailboxOutlinedIcon />,
  quoted: <MarkunreadMailboxOutlinedIcon />,
  replied_to: <ChatAddIcon />,
  retweeted: <PublishedWithChangesOutlinedIcon />,
};

const HandleProfileFeedItem: FC<HandleProfileFeedItemProps> = props => {
  const {
    data,
    relatedFeed,
    username,
    onOpenExportDialog,
    // @TODO uncomment when BE is ready
    // onOpenFolderDialog,
  } = props;
  const { tweetFieldsModelDTO, mediaFieldsModelDTO } = data;

  const mediaFields = useMediaFieldsHandleProfileFeedItem(tweetFieldsModelDTO, mediaFieldsModelDTO);
  const tweetLink = useGetTweetLink(username, tweetFieldsModelDTO.conversationId);

  const handleOpenDialog = () => {
    onOpenExportDialog(data.id);
  };

  return (
    <HandleProfilePageInfoContainer
      className={tweetCssClasses[tweetFieldsModelDTO.referencedTweets.type || 'tweet']}
    >
      <HandleProfilePageTweetTypeContainer>
        <HandleProfilePageIconContainer className="handle-icon-container">
          {tweetIcon[tweetFieldsModelDTO.referencedTweets.type || 'tweet']}
        </HandleProfilePageIconContainer>

        {relatedFeed && <HandleProfilePageDivider orientation="vertical" />}
      </HandleProfilePageTweetTypeContainer>

      <HandleProfilePageTweetContainer>
        <HandleProfilePageActionContainer>
          <HandleProfilePageTweetInfoContainer>
            <ProfileDateTimeContainer>
              <Typography className="handle-text-container" noWrap>
                {tweetText[tweetFieldsModelDTO.referencedTweets.type || 'tweet']}
              </Typography>

              <ProfileDateTimeSeparator />

              <Typography variant="body1" sx={{ fontSize: '12px' }}>
                {format(new Date(Number(tweetFieldsModelDTO.createdAt)), Settings.DATE_SHORT)}
              </Typography>

              <ProfileDateTimeSeparator />

              <Typography variant="body1" sx={{ fontSize: '12px', flexShrink: '0' }}>
                {format(new Date(Number(tweetFieldsModelDTO.createdAt)), Settings.TIME_AM_PM)}
              </Typography>
            </ProfileDateTimeContainer>

            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <span>Tweet ID:&nbsp;</span>

              <Link
                href={tweetLink}
                underline="hover"
                target="_blank"
                rel="noopener"
                sx={{ fontSize: '12px' }}
              >
                {tweetFieldsModelDTO.conversationId}
              </Link>
            </Typography>
          </HandleProfilePageTweetInfoContainer>

          <FlexContainer>
            {/* @TODO uncomment when BE is ready
            <ButtonIcon color="primary" onClick={onOpenFolderDialog}>
              <FolderOpenIcon />
            </ButtonIcon> */}

            <ButtonIcon color="primary" onClick={handleOpenDialog}>
              <FileDownloadOutlinedIcon />
            </ButtonIcon>
          </FlexContainer>
        </HandleProfilePageActionContainer>

        <HandleProfilePageTextContainer variant="body1">
          {urlify(tweetFieldsModelDTO.text)}
        </HandleProfilePageTextContainer>

        <HandleProfileFeedItemMedia data={mediaFields} />
      </HandleProfilePageTweetContainer>
    </HandleProfilePageInfoContainer>
  );
};

export default HandleProfileFeedItem;
