import { useMemo } from 'react';

interface UseTabPropsReturnValue {
  id: string;
  'aria-controls': string;
}

const useTabProps = (size: number): Array<UseTabPropsReturnValue> => {
  return useMemo(() => {
    let tabProps: Array<UseTabPropsReturnValue> = [];

    for (let i = 0; i < size; i++) {
      tabProps = [
        ...tabProps,
        {
          id: `secondary-tab-${i}`,
          'aria-controls': `secondary-tabpanel-${i}`,
        },
      ];
    }

    return tabProps;
  }, [size]);
};

export default useTabProps;
