import { styled } from '@mui/material/styles';
import MUITextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import common from '@mui/material/colors/common';

export const HelperText = styled('span')<{ error: boolean }>`
  display: inline-block;
  height: ${({ error }) => (error ? 'auto' : 0)};
`;

export const StyledFormHelperText = styled(FormHelperText)<{ error: boolean }>`
  height: ${({ error }) => (error ? 'auto' : 0)};
`;

export const StyledMUITextField = styled(MUITextField)`
  & .MuiFormHelperText-root {
    height: ${({ error }) => (error ? 'auto' : 0)};
    line-height: ${({ error }) => (error ? 'auto' : 0)};
  }
`;

export const StyledFormControlSelect = styled(FormControl)(({ theme }) => ({
  '.MuiInputBase-formControl': {
    borderRadius: '8px',

    '&.text-filled': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.text.secondary,
      },
    },

    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.textLight.light1,
    },

    ' &:hover, &.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
        boxShadow: '0 8px 8px rgba(57, 154, 205, 0.1)',
      },
    },

    '.MuiSelect-select': {
      backgroundColor: common.white,
      borderRadius: '8px',
    },

    '.MuiSvgIcon-root': {
      right: '14px',
      fontSize: '1.5rem',
      color: theme.palette.text.primary,
    },
  },
}));

export const StyledColorsFormControl = styled(FormControl)(({ theme }) => ({
  '.MuiFormLabel-root, .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.text.primary,
    marginBottom: '2px',
  },

  '.MuiFormGroup-root': {
    marginRight: '-15px',
    marginBottom: '-8px',
  },

  '.MuiFormControlLabel-root': {
    marginRight: '7px',
    marginLeft: '-9px',
  },
}));

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${common.white};
  }

  .MuiSvgIcon-root {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 6.4px;
  }
`;
