import type { FC } from 'react';

import type { GridColDef, GridRowParams, GridRowIdGetter } from '@mui/x-data-grid';

import type { FunctionWithArg, EmptyFunction, CompanyInfoTable } from '../../models';

import Checkbox from '../../components/common/form-fields/Checkbox';
import ErrorPageRequest from '../error/ErrorPageRequest';
import { TableDataGridContainer } from './styled-components';
import HandlesPagination from './HandlesPagination';

const PAGE_SIZE = 10;
const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 32;

interface HandlesTableProps {
  rows: Array<any>;
  columns: Array<GridColDef>;
  setSelectedRowsIds: FunctionWithArg<Array<string>>;
  isLoading?: boolean;
  isError?: boolean;
  onRefetch?: EmptyFunction;
  onRowClick?: FunctionWithArg<CompanyInfoTable>;
  onGetRowId?: GridRowIdGetter;
}

const HandlesTable: FC<HandlesTableProps> = props => {
  const {
    rows,
    columns,
    setSelectedRowsIds,
    isLoading,
    isError,
    onRefetch,
    onRowClick,
    onGetRowId,
  } = props;

  const onRowsSelectionHandler = (ids: Array<string | number>) => {
    setSelectedRowsIds(ids as Array<string>);
  };

  const rowClickHandler = (params: GridRowParams<CompanyInfoTable>) => {
    if (onRowClick) {
      onRowClick(params.row);
    }
  };

  const getRowId: GridRowIdGetter = row => {
    if (onGetRowId) {
      return onGetRowId(row);
    }

    return row.id;
  };

  if (isError && onRefetch) {
    return <ErrorPageRequest onRefetch={onRefetch} />;
  }

  return (
    <TableDataGridContainer
      components={{
        BaseCheckbox: Checkbox,
        Pagination: HandlesPagination,
      }}
      rows={rows}
      columns={columns}
      checkboxSelection
      hideFooterSelectedRowCount
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      headerHeight={HEADER_HEIGHT}
      rowHeight={ROW_HEIGHT}
      pagination
      pageSize={PAGE_SIZE}
      rowsPerPageOptions={[PAGE_SIZE]}
      onSelectionModelChange={onRowsSelectionHandler}
      onRowClick={rowClickHandler}
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      loading={isLoading || false}
      getRowId={getRowId}
    />
  );
};

export default HandlesTable;
