const Settings = {
  DATE_SHORT: 'MM/dd/yyyy',
  DATE_DAY_ORDER_MONTH_SHORT: 'do MMM yyyy',
  TIME_AM_PM: 'p',
  LOCAL_STORAGE_NAME_SPACE: 'aartwt',
  REFETCH_INTERVAL_HANDLES: 5 * 60 * 1000, // 5 minutes
  DEFAULT_RESULT_NUMBER: 25,
  DEFAULT_PAGE_NUMBER: 1,
} as const;

export default Settings;
