import { styled } from '@mui/material/styles';
import { common } from '@mui/material/colors';

export const LabelContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  margin: '0 4px',
  padding: '0 6px',
  fontSize: '12px',
  lineHeight: '16px',
  color: common.white,
  borderRadius: '4px',
  backgroundColor: theme.palette.success.main,
}));
