import { type FC, type ReactNode, createContext, useContext } from 'react';

import type { FunctionWithArg, CompanyInfoTable } from '../../models';

import { useAuth } from '../../context/auth.context';

interface SuperAdminPage {
  jwt: string;
  setClickRow: FunctionWithArg<CompanyInfoTable>;
  showEditTrackNumberDialog: FunctionWithArg<boolean>;
  setTrackAmount: FunctionWithArg<number>;
  setRowData: FunctionWithArg<CompanyInfoTable>;
}

interface SuperAdminPageProviderProps {
  children: ReactNode;
  onClickRow: FunctionWithArg<CompanyInfoTable>;
  onShowEditTrackNumberDialog: FunctionWithArg<boolean>;
  onChangeTrackAmount: FunctionWithArg<number>;
  onRowData: FunctionWithArg<CompanyInfoTable>;
}

const SuperAdminPageContext = createContext({} as SuperAdminPage);

const SuperAdminPageProvider: FC<SuperAdminPageProviderProps> = props => {
  const { children, onClickRow, onShowEditTrackNumberDialog, onChangeTrackAmount, onRowData } =
    props;

  const { authInfo } = useAuth();

  const value: SuperAdminPage = {
    jwt: authInfo.jwt,
    setClickRow: onClickRow,
    showEditTrackNumberDialog: onShowEditTrackNumberDialog,
    setTrackAmount: onChangeTrackAmount,
    setRowData: onRowData,
  };

  return <SuperAdminPageContext.Provider value={value}>{children}</SuperAdminPageContext.Provider>;
};

export const useSuperAdminPageContext = (): SuperAdminPage => {
  return useContext(SuperAdminPageContext);
};

export default SuperAdminPageProvider;
