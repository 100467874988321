import type { FC, ChangeEventHandler } from 'react';

import Checkbox from '../form-fields/Checkbox';

import { TypeFilterContainer } from './styled-components';

export const checkList = [
  { title: 'Handle', checked: true },
  // @TODO Enable when backend is ready
  // { title: 'User', checked: false },
  // { title: 'Company', checked: false },
];

interface TypeFilterCheckListProps {
  stateForm: typeof checkList;
  handleChange: ChangeEventHandler<HTMLInputElement>;
}

const TypeFilterCheckList: FC<TypeFilterCheckListProps> = ({
  stateForm /* , @TODO Enable when backend is ready handleChange */,
}) => {
  return (
    <>
      {checkList.map((item, itemIndex) => (
        <TypeFilterContainer
          key={`${itemIndex}-${item.title}`}
          className={stateForm[itemIndex].checked ? 'checked' : ''}
          checked={stateForm[itemIndex].checked}
          control={
            <Checkbox
              checked={stateForm[itemIndex].checked}
              name={item.title}
              // @TODO Enable when backend is ready
              // onChange={handleChange}
            />
          }
          label={item.title}
        />
      ))}
    </>
  );
};

export default TypeFilterCheckList;
