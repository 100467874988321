import type { FC } from 'react';

import Stack from '@mui/material/Stack';

import type { EmptyFunction, FooterElement } from '../../../models';

import ButtonLoading from '../../common/buttons/ButtonLoading';
import Button from '../../common/buttons/Button';
import DialogBase from '../base/DialogBase';

interface DialogAdminProps {
  title: string;
  buttonText: string;
  onClose: EmptyFunction;
  onConfirm: EmptyFunction;
  isLoading: boolean;
  error: string;
}

const footer: FooterElement = options => {
  const { onConfirm, isDisabled, isLoading, onClose, buttonText } = options;

  return (
    <Stack spacing={{ xs: 1, md: 2 }} sx={{ flexGrow: 1, marginTop: '-28px' }}>
      <ButtonLoading
        fullWidth
        size="large"
        onClick={onConfirm}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        {buttonText}
      </ButtonLoading>
      <Button fullWidth variant="outlined" size="large" onClick={onClose}>
        No
      </Button>
    </Stack>
  );
};

const DialogAdmin: FC<DialogAdminProps> = props => {
  const { title, buttonText, onClose, onConfirm, isLoading, error } = props;

  return (
    <DialogBase
      header={title}
      footer={footer({
        onConfirm,
        isDisabled: isLoading,
        isLoading,
        onClose,
        buttonText,
      })}
      onClose={onClose}
      error={error}
    />
  );
};

export default DialogAdmin;
