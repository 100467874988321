import { type FC, type ChangeEventHandler, useState } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import type { EmptyFunction, JSXElement, FooterElement, FunctionWithArg } from '../../../models';
import TextField from '../../common/form-fields/TextField';
import ButtonLoading from '../../common/buttons/ButtonLoading';
import DialogBase from '../base/DialogBase';

interface DialogEditTrackNumberProps {
  onConfirm: FunctionWithArg<number>;
  onClose: EmptyFunction;
  isLoading: boolean;
  error: string;
  trackNumber: number;
}

const MIN_VALUE = 0;
const MAX_VALUE = 1000;

const header = (): JSXElement => <>Edit track handles number</>;

const footer: FooterElement = options => {
  const { onConfirm, isDisabled, isLoading, onClose } = options;

  return (
    <Stack spacing={{ xs: 1, md: 2 }} sx={{ flexGrow: 1 }}>
      <ButtonLoading
        fullWidth
        size="large"
        onClick={onConfirm}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        Edit now
      </ButtonLoading>
      <Button fullWidth variant="outlined" size="large" onClick={onClose}>
        Cancel
      </Button>
    </Stack>
  );
};

const DialogEditTrackNumber: FC<DialogEditTrackNumberProps> = props => {
  const { onClose, onConfirm, isLoading, error, trackNumber } = props;
  const [trackNumberValue, setTrackNumberValue] = useState(trackNumber);

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }): void => {
    if (target.validity.valid) {
      setTrackNumberValue(Number(target.value));
    }
  };

  const onConfirmWrapped = (): void => {
    onConfirm(trackNumberValue);
  };

  return (
    <DialogBase
      header={header()}
      footer={footer({
        onConfirm: onConfirmWrapped,
        isDisabled:
          isLoading ||
          trackNumberValue === trackNumber ||
          Number(trackNumberValue) < MIN_VALUE ||
          Number(trackNumberValue) > MAX_VALUE,
        isLoading,
        onClose,
      })}
      onClose={onClose}
      error={error}
    >
      <Stack>
        <TextField
          label="Track handles number"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            max: MAX_VALUE,
            min: MIN_VALUE,
          }}
          value={trackNumberValue}
          onChange={handleChange}
          error={Number(trackNumberValue) < MIN_VALUE || Number(trackNumberValue) > MAX_VALUE}
          helperText={`The value should be betweet ${MIN_VALUE} and ${MAX_VALUE}`}
        />
      </Stack>
    </DialogBase>
  );
};

export default DialogEditTrackNumber;
