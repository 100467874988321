import { useMutationUpdateCompanyInfo } from '../../queries/company';
import { useMutationUpdateUser } from '../../queries/users';

import type { CompanyInfoTable, FunctionWithArg } from '../../models';

const useHandleTrackAmount = (
  jwt: string,
  rowData: CompanyInfoTable | null,
  setShowEditTrackNumberDialog: FunctionWithArg<boolean>,
) => {
  const updateUser = useMutationUpdateUser(jwt);
  const updateCompanyInfo = useMutationUpdateCompanyInfo(jwt);

  const handleTrackAmount = (value: number) => {
    if (rowData) {
      const { id, companyId, accountType } = rowData;

      if (accountType === 'COMPANY' && id) {
        updateUser.mutate(
          {
            id,
            handlesAmount: value,
          },
          {
            onSuccess: () => {
              updateCompanyInfo.mutate(
                {
                  id: companyId,
                  handlesAmount: value,
                },
                {
                  onSuccess: () => {
                    setShowEditTrackNumberDialog(false);
                  },
                },
              );
            },
          },
        );

        return;
      }

      if (accountType === 'USER' && id) {
        updateUser.mutate(
          {
            id,
            handlesAmount: value,
          },
          {
            onSuccess: () => {
              setShowEditTrackNumberDialog(false);
            },
          },
        );
      }
    }
  };

  return handleTrackAmount;
};

export default useHandleTrackAmount;
