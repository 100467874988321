import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import FormGroup from '@mui/material/FormGroup';

import Checkbox from '../../common/form-fields/Checkbox';
import { FlexContainer } from '../../common/styled-components/containers';
import { alignItemsCenter, flexColumn } from '../../common/styled-components/primitives';

export const DialogButtonCloseWrapper = styled('div')`
  position: absolute;
  top: 16px;
  right: 16px;
  transform: translate3d(0, 0, 0);
  z-index: 10;
`;

export const StyledMuiDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
    },
  },

  '.MuiDialogContent-root': {
    position: 'relative',
    zIndex: '1',
  },

  '.MuiTabs-scroller': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const DialogFormGroupContainer = styled(FormGroup)(({ theme }) => ({
  flexWrap: 'nowrap',
  maxHeight: '210px',
  overflowY: 'auto',
  padding: '8px 16px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '8px',
}));

export const CheckboxCustom = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },

  '&.Mui-checked': {
    color: theme.palette.success.main,

    '.MuiSvgIcon-root': {
      fill: theme.palette.success.main,
    },
  },
}));

export const DialogProfileContainer = styled(FlexContainer)`
  ${alignItemsCenter};
  ${flexColumn}
  margin: 16px 0;
`;

export const DialogProfileInfoContainer = styled('div')`
  width: 100%;
  margin: 10px 0 0;
  text-align: center;
`;
