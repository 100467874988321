import type { FC } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { Routes } from '../../constants/routes';

import Button from '../../components/common/buttons/Button';

import { PageErrorContainer } from '../styled-components';

import ErrorPage from './ErrorPage';

const ErrorPageBackToIndex: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (): void => {
    navigate(Routes.root.route, {
      state: {
        from: location,
      },
      replace: true,
    });
  };

  return (
    <PageErrorContainer>
      <ErrorPage backToElement={<Button onClick={handleClick}>Back to registration</Button>} />
    </PageErrorContainer>
  );
};

export default ErrorPageBackToIndex;
