import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

export const AvatarToPinContainer = styled(Avatar)(({ theme }) => ({
  width: '48px',
  height: '48px',
  color: theme.palette.primary.main,
  background: theme.palette.textLight.light2,

  svg: {
    fontSize: '1em',
  },
}));
