import httpBuilder, { headersAuthorization } from '../configs/axios';
import catchError from '../configs/catch-errors';

import { ApiAuth, ApiCompany } from '../constants/api';
import { Company, Signin, Signout, SigninResponse, CompanyInfo } from '../models';

export const registerCompany = (data: Company) => {
  return httpBuilder
    .post<Company>(ApiAuth.register, data)
    .then(response => response.data)
    .catch(catchError);
};

export const login = (loginData: Signin) => {
  return httpBuilder
    .post<SigninResponse>(ApiAuth.login, loginData)
    .then(response => response.data)
    .catch(catchError);
};

export const logout = (data: Signout, jwt: string) => {
  return httpBuilder
    .post<Signout>(ApiAuth.logout, data, headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const getCompanyInfoAll = (jwt: string) => {
  return httpBuilder
    .get<Array<CompanyInfo>>(ApiCompany.all, headersAuthorization(jwt))
    .then(response => response.data);
};

export const getCompanyInfoById = (id: string, jwt: string) => {
  return httpBuilder
    .get<CompanyInfo>(ApiCompany.byId(id), headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const updateCompanyInfoById = (id: string, data: CompanyInfo, jwt: string) => {
  return httpBuilder
    .put<CompanyInfo>(ApiCompany.byId(id), data, headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const deleteCompanyById = (id: string, jwt: string) => {
  return httpBuilder
    .delete<CompanyInfo>(ApiCompany.byId(id), headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};
