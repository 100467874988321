import { useMemo } from 'react';

import { getMinutes, getHours } from 'date-fns';

const TWELVE_HOURS = 12;
const TEN_HOURS = 10;
const ZERO_VALUE = 0;
const THIRTY_MINUTES = 30;

const useGetTimeRange = (date: number | undefined) => {
  return useMemo(() => {
    if (date) {
      const hoursRange = getHours(new Date(date));
      const hoursRangeAM = hoursRange >= TWELVE_HOURS ? hoursRange - TWELVE_HOURS : hoursRange;
      const minutesRange = getMinutes(new Date(date));

      const hoursRangeFormat =
        hoursRangeAM < TEN_HOURS ? `${ZERO_VALUE}${hoursRangeAM}` : hoursRangeAM;
      const minutesRangeFormat =
        minutesRange < TEN_HOURS ? `${ZERO_VALUE}${minutesRange}` : THIRTY_MINUTES;

      const timeRange = hoursRangeFormat + ':' + minutesRangeFormat;

      const timeAMRange = hoursRange < TWELVE_HOURS ? timeRange : '';
      const timePMRange = hoursRange >= TWELVE_HOURS ? timeRange : '';

      return [timeAMRange, timePMRange];
    }

    return ['', ''];
  }, [date]);
};

export default useGetTimeRange;
