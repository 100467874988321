import { styled } from '@mui/material/styles';

export const CustomAvatarGroup = styled('div')(({ theme }) => ({
  display: 'flex',

  '.MuiAvatar-root': {
    marginRight: `-${theme.spacing(1)}`,
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.primary.main,
    fontSize: '1em',
    backgroundColor: theme.palette.textLight.light2,
    border: '2px solid #fff',
  },
}));
