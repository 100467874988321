import type { FC } from 'react';

import type { IconButtonProps } from '@mui/material/IconButton';
import common from '@mui/material/colors/common';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import ButtonIconComponent from './ButtonIcon';

const ButtonEditComponent: FC<IconButtonProps> = props => {
  const { 'aria-label': ariaLabel, ...restProps } = props;

  return (
    <ButtonIconComponent
      {...restProps}
      aria-label="edit"
      color="primary"
      sx={{ backgroundColor: common.white, borderRadius: '4px', padding: '4px' }}
    >
      <EditOutlinedIcon aria-label={ariaLabel || 'edit'} sx={{ fontSize: '1rem' }} />
    </ButtonIconComponent>
  );
};

export default ButtonEditComponent;
