import { type FC, type SyntheticEvent, useState } from 'react';

import type { GridRowIdGetter } from '@mui/x-data-grid';

import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import useTabProps from '../../hooks/use-tab-props';
import useAdminCompaniesTableData from '../../hooks/use-admin-companies-table-data';
import useRowsNew from '../../hooks/use-rows-new';

import type { CompanyInfo, EmptyFunction, FunctionWithArg } from '../../models';

import LabelCount from '../../components/common/label-count/LabelCount';
import TabPanel from '../../components/common/tabs/TabPanelComponent';

import HandlesTable from '../handles/HandlesTable';
import { TabsContainer } from '../styled-components';

import { useSuperAdminPageContext } from './SuperAdminPageProvider';
import { columnsCompanyAll } from './columns';

interface SuperAdminTabCompanyProps {
  setSelectedRows: FunctionWithArg<Array<string>>;
  data: Array<CompanyInfo> | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: EmptyFunction;
}

const SuperAdminTabCompany: FC<SuperAdminTabCompanyProps> = props => {
  const { setSelectedRows, data, isLoading, isError, refetch } = props;

  const [tab, setTab] = useState(0);
  const { setClickRow } = useSuperAdminPageContext();
  const tabProps = useTabProps(3);
  const rowsAll = useAdminCompaniesTableData(data);
  const rowsNew = useRowsNew(rowsAll);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setSelectedRows([]);
  };

  const handleGetRowId: GridRowIdGetter = row => {
    return row.companyId;
  };

  if (data) {
    return (
      <Box>
        <TabsContainer value={tab} onChange={handleChange} aria-label="handles tabs">
          <Tab label="All" {...tabProps[0]} />

          {rowsNew.length ? (
            <Tab
              label="New"
              icon={<LabelCount amount={rowsNew.length} />}
              iconPosition="end"
              {...tabProps[1]}
            />
          ) : null}

          <Tab disabled label="Admin" {...tabProps[2]} />
        </TabsContainer>

        <TabPanel value={tab} index={0}>
          <HandlesTable
            rows={rowsAll}
            columns={columnsCompanyAll}
            setSelectedRowsIds={setSelectedRows}
            onRowClick={setClickRow}
            isLoading={isLoading}
            isError={isError}
            onRefetch={refetch}
            onGetRowId={handleGetRowId}
          />
        </TabPanel>

        {rowsNew.length ? (
          <TabPanel value={tab} index={1}>
            <HandlesTable
              rows={rowsNew}
              columns={columnsCompanyAll}
              setSelectedRowsIds={setSelectedRows}
              onRowClick={setClickRow}
              isLoading={isLoading}
              isError={isError}
              onRefetch={refetch}
              onGetRowId={handleGetRowId}
            />
          </TabPanel>
        ) : null}
      </Box>
    );
  }

  return <Typography p={3}>Is empty</Typography>;
};

export default SuperAdminTabCompany;
