import type { FC } from 'react';

import Grid from '@mui/material/Grid';

import {
  IconHandleContainer,
  MarkunreadMailboxIconContainer,
  PublishedWithChangesIconContainer,
  ChatAddIconContainer,
} from './styled-components';

export interface IconsHandleComponentProps {
  unread: number;
  published: number;
  chat: number;
}

const IconsHandleComponent: FC<IconsHandleComponentProps> = props => {
  const { unread, published, chat } = props;

  return (
    <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <IconHandleContainer>
          <MarkunreadMailboxIconContainer /> {unread}
        </IconHandleContainer>
      </Grid>
      <Grid item>
        <IconHandleContainer>
          <PublishedWithChangesIconContainer /> {published}
        </IconHandleContainer>
      </Grid>
      <Grid item>
        <IconHandleContainer>
          <ChatAddIconContainer /> {chat}
        </IconHandleContainer>
      </Grid>
    </Grid>
  );
};

export default IconsHandleComponent;
