import { Reducer, useReducer } from 'react';

import type { CompanyInfoTable } from '../../models';

interface AdminPageState {
  currentTab: number;
  trackAmount: number;
  rowData: CompanyInfoTable | null;
  clickRow: CompanyInfoTable | null;
  selectedUsersRows: Array<string>;
  selectedCompaniesRows: Array<string>;
  showAdminDialog: boolean;
  showDeleteCompaniesDialog: boolean;
  showDeleteUsersDialog: boolean;
  showEditTrackNumberDialog: boolean;
}

const initialState: AdminPageState = {
  currentTab: 0,
  trackAmount: 3,
  rowData: null,
  clickRow: null,
  selectedUsersRows: [],
  selectedCompaniesRows: [],
  showAdminDialog: false,
  showDeleteCompaniesDialog: false,
  showDeleteUsersDialog: false,
  showEditTrackNumberDialog: false,
};

interface ActionChangeTab {
  type: 'changeTab';
  data: {
    currentTab: number;
  };
}

interface ActionClickRow {
  type: 'clickRow';
  data: {
    clickRow: CompanyInfoTable | null;
  };
}

interface ActionSelectUserRows {
  type: 'selectUserRows';
  data: {
    selectedUsersRows: Array<string>;
  };
}

interface ActionSelectCompaniesRows {
  type: 'selectCompanieRows';
  data: {
    selectedCompaniesRows: Array<string>;
  };
}

interface ActionShowAdminDialog {
  type: 'showAdminDialog';
  data: {
    showAdminDialog: boolean;
  };
}

interface ActionShowDeleteCompaniesDialog {
  type: 'showDeleteCompaniesDialog';
  data: {
    showDeleteCompaniesDialog: boolean;
  };
}

interface ActionShowDeleteUsersDialog {
  type: 'showDeleteUsersDialog';
  data: {
    showDeleteUsersDialog: boolean;
  };
}

interface ActionShowEditTrackNumberDialog {
  type: 'showEditTrackNumberDialog';
  data: {
    showEditTrackNumberDialog: boolean;
  };
}

interface ActionSetTrackAmount {
  type: 'setTrackAmount';
  data: {
    trackAmount: number;
  };
}

interface ActionSetRowData {
  type: 'setRowData';
  data: {
    rowData: CompanyInfoTable;
  };
}

type Action =
  | ActionChangeTab
  | ActionSelectUserRows
  | ActionSelectCompaniesRows
  | ActionShowAdminDialog
  | ActionShowDeleteCompaniesDialog
  | ActionShowDeleteUsersDialog
  | ActionClickRow
  | ActionShowEditTrackNumberDialog
  | ActionSetTrackAmount
  | ActionSetRowData;

const reducer: Reducer<AdminPageState, Action> = (state, { type, data }) => {
  switch (type) {
    case 'changeTab': {
      return {
        ...state,
        currentTab: data.currentTab,
      };
    }

    case 'clickRow': {
      return {
        ...state,
        clickRow: data.clickRow,
      };
    }

    case 'selectUserRows': {
      return {
        ...state,
        selectedUsersRows: data.selectedUsersRows,
      };
    }

    case 'selectCompanieRows': {
      return {
        ...state,
        selectedCompaniesRows: data.selectedCompaniesRows,
      };
    }

    case 'showAdminDialog': {
      return {
        ...state,
        showAdminDialog: data.showAdminDialog,
      };
    }

    case 'showDeleteCompaniesDialog': {
      return {
        ...state,
        showDeleteCompaniesDialog: data.showDeleteCompaniesDialog,
      };
    }

    case 'showDeleteUsersDialog': {
      return {
        ...state,
        showDeleteUsersDialog: data.showDeleteUsersDialog,
      };
    }

    case 'showEditTrackNumberDialog': {
      return {
        ...state,
        showEditTrackNumberDialog: data.showEditTrackNumberDialog,
      };
    }

    case 'setTrackAmount': {
      return {
        ...state,
        trackAmount: data.trackAmount,
      };
    }

    case 'setRowData': {
      return {
        ...state,
        rowData: data.rowData,
      };
    }
  }
};

const useAdminPageState = () => {
  const [state, setState] = useReducer(reducer, initialState);

  const setSelectedUsersRows = (rows: Array<string>) => {
    setState({
      type: 'selectUserRows',
      data: { selectedUsersRows: rows },
    });
  };

  const setSelectedCompaniesRows = (rows: Array<string>) => {
    setState({
      type: 'selectCompanieRows',
      data: { selectedCompaniesRows: rows },
    });
  };

  const setCurrentTab = (value: number) => {
    setState({
      type: 'changeTab',
      data: { currentTab: value },
    });
  };

  const setShowDeleteUsersDialog = (value: boolean) => {
    setState({
      type: 'showDeleteUsersDialog',
      data: { showDeleteUsersDialog: value },
    });
  };

  const setShowDeleteCompaniesDialog = (value: boolean) => {
    setState({
      type: 'showDeleteCompaniesDialog',
      data: { showDeleteCompaniesDialog: value },
    });
  };

  const setShowAdminDialog = (value: boolean) => {
    setState({
      type: 'showAdminDialog',
      data: { showAdminDialog: value },
    });
  };

  const setClickRow = (row: CompanyInfoTable | null) => {
    setState({
      type: 'clickRow',
      data: { clickRow: row },
    });
  };

  const setShowEditTrackNumberDialog = (value: boolean) => {
    setState({
      type: 'showEditTrackNumberDialog',
      data: { showEditTrackNumberDialog: value },
    });
  };

  const setTrackAmount = (value: number) => {
    setState({
      type: 'setTrackAmount',
      data: { trackAmount: value },
    });
  };

  const setRowData = (value: CompanyInfoTable) => {
    setState({
      type: 'setRowData',
      data: { rowData: value },
    });
  };

  return {
    state,
    setSelectedUsersRows,
    setSelectedCompaniesRows,
    setCurrentTab,
    setShowDeleteUsersDialog,
    setShowDeleteCompaniesDialog,
    setShowAdminDialog,
    setClickRow,
    setShowEditTrackNumberDialog,
    setTrackAmount,
    setRowData,
  };
};

export default useAdminPageState;
