import type { FC } from 'react';

import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

const ChatAddIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path d="M6 42V11q0-1.2.9-2.1Q7.8 8 9 8h26q1.2 0 2.1.9.9.9.9 2.1v11.15q-.5-.1-.975-.125Q36.55 22 36 22h-.5q-.25 0-.5.05V11H9v23.75L10.75 33h13.3q-.05.25-.05.475V34q0 .55.025 1.025.025.475.125.975H12Zm8-23v-3h16v3Zm0 8.5v-3h10v3Zm20.5 14v-6h-6v-3h6v-6h3v6h6v3h-6v6Z" />
    </SvgIcon>
  );
};

export default ChatAddIcon;
