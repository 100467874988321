import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
  type UseMutationResult,
  type UseQueryResult,
  type UseInfiniteQueryResult,
} from '@tanstack/react-query';

import type { AxiosResponse, AxiosError } from 'axios';

import type {
  AddNewRule,
  PinUnpinPayload,
  HandleDeletePayload,
  Handle,
  AccountType,
  FeedResponse,
  SearchItem,
  UseInfiniteQueryAllTweetsByHandleIdOptions,
  UseInfiniteQueryFeedByEntityIdOptions,
  HandlesByCompanyIdResponse,
  UseInfiniteFulltextSearch,
} from '../models';

import Settings from '../constants/settings';
import {
  addRule,
  getAllTweetsByHandleId,
  pinUnpin,
  deleteHandle,
  getRecentHandlesByEntityId,
  getRecentPinnedHandlesByEntityId,
  getHandlesWithoutTwitsByEntityId,
  getFeedEntityId,
  getFulltextSearch,
} from '../api/tweets';

export const useInfiniteQueryAllTweetsByHandleId = (
  options: UseInfiniteQueryAllTweetsByHandleIdOptions,
): UseInfiniteQueryResult<HandlesByCompanyIdResponse, AxiosError> => {
  const { handleId, isStockTwits, tickerId, pageNumberInitial, jwt } = options;

  return useInfiniteQuery(
    ['all-tweets-infinite-by-handle-id', handleId],
    ({ pageParam = pageNumberInitial }) =>
      getAllTweetsByHandleId({
        handleId,
        isStockTwits,
        tickerId,
        pageNumber: pageParam,
        jwt,
      }),
    {
      enabled: Boolean(handleId),
      refetchInterval: Settings.REFETCH_INTERVAL_HANDLES,
      getNextPageParam: lastPage =>
        isStockTwits
          ? (lastPage as HandlesByCompanyIdResponse).nextStockTwitsPage ?? undefined
          : (lastPage as HandlesByCompanyIdResponse).nextTweetsPage ?? undefined,
    },
  );
};

export const useInfiniteFulltextSearch = (
  options: UseInfiniteFulltextSearch,
): UseInfiniteQueryResult<FeedResponse<SearchItem>, AxiosError> => {
  const { pageNumberInitial, keyword, timestamp, ...restOptions } = options;

  return useInfiniteQuery(
    ['fulltext-search', keyword, timestamp],
    ({ pageParam = pageNumberInitial }) =>
      getFulltextSearch({
        pageNumber: pageParam,
        keyword,
        timestamp,
        ...restOptions,
      }),
    {
      enabled: (Boolean(keyword) && Boolean(timestamp)) || Boolean(keyword),
      refetchInterval: Settings.REFETCH_INTERVAL_HANDLES,
      getNextPageParam: lastPage =>
        (lastPage as FeedResponse<SearchItem>)?.nextFeedPage ?? undefined,
    },
  );
};

export const useQueryRecentHandlesByEntityId = (
  id: string,
  jwt: string,
): UseQueryResult<Array<Handle>, AxiosError> => {
  return useQuery(['all-recent-handles', id], () => getRecentHandlesByEntityId(id, jwt), {
    enabled: Boolean(id),
  });
};

export const useQueryRecentPinnedHandlesByEntityId = (
  id: string,
  jwt: string,
): UseQueryResult<Array<Handle>, AxiosError> => {
  return useQuery(
    ['all-recent-pinned-handles', id],
    () => getRecentPinnedHandlesByEntityId(id, jwt),
    {
      enabled: Boolean(id),
    },
  );
};

export const useQueryHandlesWithoutTwitsByEntityId = (
  id: string | undefined,
  jwt: string,
): UseQueryResult<Array<Handle>, AxiosError> => {
  return useQuery(
    ['all-handles-without-twits', id],
    () => {
      if (id) {
        return getHandlesWithoutTwitsByEntityId(id, jwt);
      }
    },
    {
      enabled: Boolean(id),
    },
  );
};

export const useInfiniteQueryFeedByEntityId = (
  options: UseInfiniteQueryFeedByEntityIdOptions,
): UseInfiniteQueryResult<FeedResponse, AxiosError> => {
  const { id, pageNumberInitial, jwt } = options;

  const queryClient = useQueryClient();

  return useInfiniteQuery(
    ['feed-infinite-by-entity-id', id],
    ({ pageParam = pageNumberInitial }) =>
      getFeedEntityId({
        id,
        pageNumber: pageParam,
        jwt,
      }),
    {
      refetchInterval: Settings.REFETCH_INTERVAL_HANDLES,
      enabled: Boolean(id),
      getNextPageParam: lastPage => (lastPage as FeedResponse)?.nextFeedPage ?? undefined,
      onSuccess: () => {
        queryClient.refetchQueries(['all-handles-without-twits', id]);
      },
    },
  );
};

export const useMutationAddRule = (
  jwt: string,
): UseMutationResult<AxiosResponse<any>, AxiosError, AddNewRule> => {
  const queryClient = useQueryClient();

  return useMutation((data: AddNewRule) => addRule(data, jwt), {
    onSuccess: (_d, variables, _c) => {
      queryClient.refetchQueries(['all-handles-infinite-by-company-id', variables.companyId]);
      queryClient.refetchQueries(['all-recent-handles', variables.companyId]);
      queryClient.refetchQueries(['all-handles-without-twits', variables.companyId]);
      queryClient.refetchQueries(['feed-infinite-by-entity-id', variables.companyId]);
    },
  });
};

export const useMutationPinUnpin = (
  jwt: string,
): UseMutationResult<any, AxiosError, PinUnpinPayload & { companyId: string }> => {
  const queryClient = useQueryClient();

  return useMutation((data: PinUnpinPayload & { companyId: string }) => pinUnpin(data, jwt), {
    onSuccess: (_d, variables, _c) => {
      queryClient.refetchQueries(['all-handles-infinite-by-company-id', variables.companyId]);
      queryClient.refetchQueries(['all-recent-pinned-handles', variables.companyId]);
      queryClient.refetchQueries(['all-handles-without-twits', variables.companyId]);
      queryClient.refetchQueries(['all-tweets-infinite-by-handle-id', variables.handleId]);
    },
  });
};

export const useMutationDeleteHandle = (
  entityId: string,
  jwt: string,
): UseMutationResult<AxiosResponse<string>, AxiosError, HandleDeletePayload> => {
  const queryClient = useQueryClient();

  return useMutation((data: HandleDeletePayload) => deleteHandle(data, jwt), {
    onSuccess: (_d, variables, _c) => {
      queryClient.refetchQueries(['all-handles-infinite-by-company-id', entityId]);
      queryClient.refetchQueries(['all-handles-without-twits', entityId]);

      queryClient.removeQueries(['feed-infinite-by-entity-id', entityId]);
      queryClient.removeQueries(['all-recent-handles', entityId]);
      queryClient.removeQueries(['all-recent-pinned-handles', entityId]);

      queryClient.removeQueries(['all-tweets-infinite-by-handle-id', variables.handleId]);
    },
  });
};

export const useMutationDeleteHandlesFromTable = (
  companyId: string,
  accountType: AccountType,
  jwt: string,
): UseMutationResult<
  Array<unknown>,
  AxiosError,
  { selectedRows: Array<string>; handles: Array<Handle> }
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { selectedRows: Array<string>; handles: Array<Handle> }) => {
      const deleteRequests = new Set();

      data.selectedRows.forEach(handleId => {
        const handle = data.handles.find(handleItem => handleItem.id === Number(handleId));

        if (handle) {
          deleteRequests.add(
            deleteHandle(
              {
                authorNames: handle.username,
                companyId: accountType === 'COMPANY' ? companyId : undefined,
                handleId: handle.id,
                userId: accountType === 'USER' ? handle?.userDTO?.id : undefined,
              },
              jwt,
            ),
          );
        }
      });

      return Promise.all(Array.from(deleteRequests));
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['all-handles-infinite-by-company-id', companyId]);
        queryClient.refetchQueries(['all-handles-without-twits', companyId]);

        queryClient.removeQueries(['feed-infinite-by-entity-id', companyId]);
        queryClient.removeQueries(['all-recent-handles', companyId]);
        queryClient.removeQueries(['all-recent-pinned-handles', companyId]);
      },
    },
  );
};
