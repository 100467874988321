import type { AxiosError } from 'axios';

export const HttpStatusCode = {
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
} as const;

// https://github.com/axios/axios#handling-errors
const catchError = (error: AxiosError<any>): void => {
  if ((error as AxiosError).response) {
    const message = error.response?.data.message;

    throw new Error(message);
  } else if ((error as AxiosError).request) {
    // user was deleted from DB
    if (error.code === 'ERR_NETWORK' && error.message === 'Network Error') {
      localStorage.clear();
      window.location.reload();
    }
    // @TODO Handle (error as AxiosError).request
  } else {
    const message = `
      ${error.name}: ${error.message}
      \n\n-----
      ${error.stack}
    `;

    throw new Error(message);
  }
};

export default catchError;
