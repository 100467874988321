import { type FC, type ChangeEventHandler, useState, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import type { EmptyFunction, FooterElement, FunctionWithArg } from '../../../models';

import TextField from '../../common/form-fields/TextField';
import ButtonLoading from '../../common/buttons/ButtonLoading';
import DialogBase from '../base/DialogBase';

export interface EditCompanyInfoFields {
  email: string;
}

interface DialogEditCompanyProps {
  companyName: string;
  companyNickname: string;
  companyEmail: string;
  companyImageUrl: string;
  avatarLetters: string;
  onConfirm: FunctionWithArg<EditCompanyInfoFields>;
  onClose: EmptyFunction;
  isLoading: boolean;
  error: string;
}

const footer: FooterElement = options => {
  const { onConfirm, isDisabled, isLoading } = options;

  return (
    <ButtonLoading
      fullWidth
      size="large"
      onClick={onConfirm}
      disabled={isDisabled}
      isLoading={isLoading}
    >
      Save now
    </ButtonLoading>
  );
};

const DialogEditCompany: FC<DialogEditCompanyProps> = props => {
  const {
    companyName,
    companyNickname,
    companyEmail,
    companyImageUrl,
    avatarLetters,
    onConfirm,
    isLoading,
    error,
    onClose,
  } = props;

  const [stateFrom, setStateFrom] = useState<EditCompanyInfoFields>({
    email: companyEmail,
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setStateFrom({
      ...stateFrom,
      [target.name]: target.value,
    });
  };

  const isDisabled = useMemo(() => {
    return isLoading || stateFrom.email === companyEmail;
  }, [isLoading, stateFrom, companyEmail]);

  const handleConfirm = () => {
    onConfirm(stateFrom);
  };

  return (
    <DialogBase
      footer={footer({
        onConfirm: handleConfirm,
        onClose,
        isLoading,
        isDisabled,
      })}
      onClose={onClose}
      error={error}
    >
      <Stack spacing={{ xs: '4px', md: 2 }} marginTop={{ xs: 2, md: 3 }}>
        <Avatar
          alt={`${companyName} avatar`}
          src={companyImageUrl}
          sx={{ textTransform: 'uppercase', width: '90px', height: '90px', margin: '0 auto 12px' }}
        >
          {avatarLetters}
        </Avatar>
        <Box paddingLeft="13px">
          <Typography variant="body2" sx={{ fontSize: '0.75em' }}>
            Name
          </Typography>
          {companyName}
        </Box>
        <Box paddingLeft="13px">
          <Typography variant="body2" sx={{ fontSize: '0.75em' }}>
            Username
          </Typography>
          @{companyNickname}
        </Box>
        <TextField
          label="Email address"
          name="email"
          value={stateFrom.email}
          onChange={handleChange}
        />
      </Stack>
    </DialogBase>
  );
};

export default DialogEditCompany;
