import type { FC } from 'react';

import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

const DeleteIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 14 16">
      <path d="M2.834 15.5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177V3H.334V1.333h4.167V.5h5v.833h4.166V3h-.833v10.833c0 .459-.163.851-.49 1.178-.326.326-.718.489-1.177.489H2.834ZM11.167 3H2.834v10.833h8.333V3Zm-6.666 9.167h1.666v-7.5H4.501v7.5Zm3.333 0h1.667v-7.5H7.834v7.5Z" />
    </SvgIcon>
  );
};

export default DeleteIcon;
