import type { FC } from 'react';

import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { EmptyFunction } from '../../../models';
import AgencyComponent from './Agency';

interface AgencyCardProfileProps {
  companyName: string;
  companyNickname: string;
  imageUrl: string;
  editButton: boolean;
  onEditProfile?: EmptyFunction;
}

const AgencyCardProfile: FC<AgencyCardProfileProps> = props => {
  const { companyName, companyNickname, imageUrl, editButton, onEditProfile } = props;
  const theme = useTheme();

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: 'none',
        backgroundColor: theme.palette.textLight.light2,
      }}
    >
      <CardContent>
        <AgencyComponent
          agencyBoxSmall
          companyName={companyName}
          companyNickname={companyNickname}
          imageUrl={imageUrl}
          editButton={editButton}
          onEditProfile={onEditProfile}
        />
      </CardContent>
    </Card>
  );
};

export default AgencyCardProfile;
