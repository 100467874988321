import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const httpBuilder = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  delete: axiosInstance.delete,
  put: axiosInstance.put,
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    return Promise.reject(error);
  },
);

export const headersAuthorization = (jwt: string) => ({
  headers: {
    Authorization: `Bearer ${jwt}`,
  },
});

export default httpBuilder;
