import type { FC } from 'react';

import type { EmptyFunction, FunctionWithArg } from '../../../models';

import SearchFieldV2, { SearchFieldV2Props } from './SearchFieldV2';
import { SearchFieldMobileDrawer } from './styled-components';

interface SearchFieldMobileProps
  extends Pick<
  SearchFieldV2Props,
    'options' | 'onSearch' | 'onChangeDateRange' | 'onChangeFilter' | 'dateRange'
  > {
  isOpen: boolean;
  onOpen: FunctionWithArg<boolean>;
  onCloseViaDrawer: EmptyFunction;
}

const SearchFieldMobile: FC<SearchFieldMobileProps> = props => {
  const {
    options,
    onSearch,
    onCloseViaDrawer,
    isOpen,
    onOpen,
    onChangeDateRange,
    onChangeFilter,
    dateRange,
  } = props;

  return (
    <SearchFieldMobileDrawer anchor="top" open={isOpen} onClose={onCloseViaDrawer}>
      <SearchFieldV2
        options={options}
        onOpen={onOpen}
        isOpen={isOpen}
        onChangeDateRange={onChangeDateRange}
        dateRange={dateRange}
        onChangeFilter={onChangeFilter}
        onSearch={onSearch}
      />
    </SearchFieldMobileDrawer>
  );
};

export default SearchFieldMobile;
