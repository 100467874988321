import type { FC, MouseEventHandler } from 'react';

import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

import type { EmptyFunction, Handle } from '../../../models';

import ButtonIcon from '../buttons/ButtonIcon';
import IconsHandleComponent from '../icons-handle/IconsHandle';
import { TwitterHandleCardContainer, TwitterHandleInfoIconsContainer } from './styled-components';

interface TwitterHandleCardProps {
  handle: Handle;
  onPin?: EmptyFunction;
  withPinButton?: boolean;
}

const TwitterHandleCard: FC<TwitterHandleCardProps> = props => {
  const { handle, onPin, withPinButton } = props;
  const isStockTwits =
    (handle?.stockTwitResponsesDTO === null && handle.tweetModelDTOS.length === 0) ||
    Boolean(handle?.stockTwitResponsesDTO?.length);

  const handleClick: MouseEventHandler = event => {
    event.stopPropagation();
    event.preventDefault();

    if (onPin) {
      onPin();
    }
  };

  return (
    <TwitterHandleCardContainer>
      {withPinButton ? (
        <Box
          sx={{
            textAlign: 'right',
            marginTop: 2,
            marginRight: 2,
          }}
        >
          <ButtonIcon color={handle.pinned ? 'secondary' : 'primary'} onClick={handleClick}>
            <PushPinOutlinedIcon />
          </ButtonIcon>
        </Box>
      ) : null}

      <CardContent data-username={handle.username}>
        {/* @TODO Add new prop to BE for `badgeContent` */}
        <Badge color="success" badgeContent={0}>
          <Avatar
            alt={`${handle.username} avatar`}
            src={handle.profileImageUrl}
            sx={{ width: 60, height: 60, marginBottom: '10px' }}
          />
        </Badge>

        <Typography variant="h4" align="center" noWrap>
          {handle.tweetHandleName}
        </Typography>

        <Typography variant="subtitle1" align="center" noWrap>
          {isStockTwits ? `#${handle.username}` : `@${handle.username}`}
        </Typography>

        <TwitterHandleInfoIconsContainer>
          <IconsHandleComponent
            unread={Number(handle.tweetNumber)}
            published={Number(handle.retweetNumber)}
            chat={Number(handle.replyNumber)}
          />
        </TwitterHandleInfoIconsContainer>
      </CardContent>
    </TwitterHandleCardContainer>
  );
};

export default TwitterHandleCard;
