import type { FC } from 'react';

import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

import { BadgeAdminIconContainer } from './styled-components';

const BadgeAdminIcon: FC = () => {
  return (
    <BadgeAdminIconContainer>
      <MilitaryTechOutlinedIcon />
    </BadgeAdminIconContainer>
  );
};

export default BadgeAdminIcon;
