import type { FC } from 'react';

import CardMedia from '@mui/material/CardMedia';

import type { EmptyFunction, JSXElement, FooterElement } from '../../../models';

import { FlexContainer } from '../../common/styled-components/containers';
import ButtonLoading from '../../common/buttons/ButtonLoading';
import DialogBase from '../base/DialogBase';

interface DialogHandleProfileShowImageProps {
  onClose: EmptyFunction;
  imageTitle: string;
  imageUrl: string;
}

export interface DialogImageData {
  imageTitle: string;
  imageUrl: string;
}

const header = (title: string): JSXElement => <>{title}</>;

const footer: FooterElement = options => {
  const { onConfirm } = options;

  return (
    <ButtonLoading size="large" onClick={onConfirm}>
      Close
    </ButtonLoading>
  );
};

const DialogHandleProfileShowImage: FC<DialogHandleProfileShowImageProps> = props => {
  const { onClose, imageTitle, imageUrl } = props;

  return (
    <DialogBase
      fullWidth
      header={header(imageTitle)}
      footer={footer({ onConfirm: onClose })}
      onClose={onClose}
    >
      <FlexContainer>
        <CardMedia
          component="img"
          loading="lazy"
          src={imageUrl}
          alt={`media item ${imageTitle}`}
          sx={{ objectFit: 'contain' }}
        />
      </FlexContainer>
    </DialogBase>
  );
};

export default DialogHandleProfileShowImage;
