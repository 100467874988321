import type { FC, MutableRefObject, ChangeEvent } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/system/Box';
import ButtonBase from '@mui/material/ButtonBase';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

import type { EmptyFunction, FunctionWithArg } from '../../../models';

import ButtonIcon from '../../common/buttons/ButtonIcon';
import ErrorMessage from '../error-message/ErrorMessage';
import FileUploadInput from './FileUploadInput';

import { UploadImageContainer } from './styled-components';

interface UploadImageProps {
  alt: string;
  avatarLetters: string;
  files: File | null;
  inputProps: {
    onChange: FunctionWithArg<ChangeEvent<HTMLInputElement>>;
    ref: MutableRefObject<HTMLInputElement | null>;
  };
  openSelection: EmptyFunction;
  src?: string;
  errorFiles?: string;
}

const UploadImage: FC<UploadImageProps> = props => {
  const { src, alt, avatarLetters, files, inputProps, errorFiles, openSelection } = props;

  return (
    <Box textAlign="center">
      <UploadImageContainer>
        <ButtonBase disableRipple disableTouchRipple onClick={openSelection}>
          <Avatar
            src={files ? URL.createObjectURL(files) : src}
            onLoad={() => (files ? URL.revokeObjectURL(files.name) : null)}
            alt={alt}
            sx={{ textTransform: 'uppercase', width: '90px', height: '90px' }}
          >
            {avatarLetters}
          </Avatar>
        </ButtonBase>
        <ButtonIcon color="primary" onClick={openSelection}>
          <AddPhotoAlternateOutlinedIcon />
        </ButtonIcon>
      </UploadImageContainer>

      <FileUploadInput {...inputProps} />

      <ErrorMessage>{errorFiles}</ErrorMessage>
    </Box>
  );
};

export default UploadImage;
