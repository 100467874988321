import type { FC } from 'react';

import format from 'date-fns/format';

import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import ButtonIcon from '../../components/common/buttons/ButtonIcon';

import { urlify } from '../../utils/urlify';
import Settings from '../../constants/settings';

import type { StockTwit, FunctionWithArg } from '../../models';

import HandleProfileFeedItemMedia from '../handle-profile/HandleProfileFeedItemMedia';
import {
  HandleProfilePageActionContainer,
  HandleProfilePageIconContainer,
  HandleProfilePageInfoContainer,
  HandleProfilePageTextContainer,
  HandleProfilePageTweetActionsContainer,
  HandleProfilePageTweetContainer,
  HandleProfilePageTweetInfoContainer,
  HandleProfilePageTweetTypeContainer,
} from '../handle-profile/styled-components';

import { ProfileDateTimeContainer, ProfileDateTimeSeparator } from '../styled-components';

import { FeedItemCommonProps } from './FeedItem';
import { TwitterContainer } from './styled-components';

interface FeedItemStockTwitsProps extends Omit<FeedItemCommonProps, 'data' | 'onOpenExportDialog'> {
  data: StockTwit;
  onOpenExportDialog: FunctionWithArg<StockTwit>;
}

const FeedItemStockTwits: FC<FeedItemStockTwitsProps> = props => {
  const {
    data: { stockTwit, stockTwitTimestamp, tickerId, tweetText, imageUrl, ...restData },
    avatarSrc,
    onOpenExportDialog,
    // @TODO uncomment when BE is ready
    // onOpenFolderDialog,
  } = props;

  const handleOpenDialog = (data: StockTwit) => (): void => {
    onOpenExportDialog(data);
  };

  return (
    <HandleProfilePageInfoContainer>
      <HandleProfilePageTweetTypeContainer>
        <HandleProfilePageIconContainer className="handle-icon-container">
          <Avatar
            alt={`${stockTwit.username} avatar`}
            src={stockTwit.avatarUrlHttps || avatarSrc}
          />
        </HandleProfilePageIconContainer>
      </HandleProfilePageTweetTypeContainer>

      <HandleProfilePageTweetContainer>
        <HandleProfilePageActionContainer>
          <HandleProfilePageTweetInfoContainer>
            <ProfileDateTimeContainer>
              <Typography className="handle-text-container" noWrap>
                {stockTwit.username}
              </Typography>

              <ProfileDateTimeSeparator />

              <Typography variant="body1" sx={{ fontSize: '12px' }}>
                {format(new Date(Number(stockTwitTimestamp)), Settings.DATE_SHORT)}
              </Typography>

              <ProfileDateTimeSeparator />

              <Typography variant="body1" sx={{ fontSize: '12px', flexShrink: '0' }}>
                {format(new Date(Number(stockTwitTimestamp)), Settings.TIME_AM_PM)}
              </Typography>
            </ProfileDateTimeContainer>

            <ProfileDateTimeContainer>
              <Typography variant="body2" sx={{ fontSize: '12px' }}>
                Posted on <TwitterContainer>StockTwits</TwitterContainer>
              </Typography>

              <ProfileDateTimeSeparator />

              <Typography variant="body2" sx={{ fontSize: '12px' }}>
                <Link
                  href={`https://stocktwits.com/symbol/${tickerId}`}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                  sx={{ fontSize: '12px' }}
                >
                  #{tickerId}
                </Link>
              </Typography>
            </ProfileDateTimeContainer>
          </HandleProfilePageTweetInfoContainer>

          <HandleProfilePageTweetActionsContainer>
            {/* @TODO uncomment when BE is ready
            <ButtonIcon color="primary" onClick={onOpenFolderDialog}>
              <FolderOpenIcon />
            </ButtonIcon> */}

            <ButtonIcon
              color="primary"
              onClick={handleOpenDialog({
                ...restData,
                stockTwit,
                stockTwitTimestamp,
                tickerId,
                tweetText,
                imageUrl,
              })}
            >
              <FileDownloadOutlinedIcon />
            </ButtonIcon>
          </HandleProfilePageTweetActionsContainer>
        </HandleProfilePageActionContainer>

        <HandleProfilePageTextContainer variant="body1">
          {urlify(tweetText)}
        </HandleProfilePageTextContainer>

        <HandleProfileFeedItemMedia
          data={
            imageUrl
              ? [
                  {
                    mediaKey: null,
                    height: 100,
                    width: 100,
                    previewImageUrl: imageUrl,
                    type: 'photo',
                    url: imageUrl,
                  },
                ]
              : []
          }
        />
      </HandleProfilePageTweetContainer>
    </HandleProfilePageInfoContainer>
  );
};

export default FeedItemStockTwits;
