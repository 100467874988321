import { useMemo } from 'react';

import type { Handle, HandlesStats } from '../models';

const useHandlesStats = (allHandles: Array<Handle>) => {
  return useMemo(() => {
    const stats: HandlesStats = {
      replyNumber: 0,
      tweetNumber: 0,
      retweetNumber: 0,
    };

    if (allHandles.length) {
      allHandles?.forEach(handle => {
        stats.replyNumber = stats.replyNumber + handle.replyNumber;
        stats.tweetNumber = stats.tweetNumber + handle.tweetNumber;
        stats.retweetNumber = stats.retweetNumber + handle.retweetNumber;
      });

      return stats;
    }

    return stats;
  }, [allHandles]);
};

export default useHandlesStats;
