import type { FC, ReactNode } from 'react';

import Tooltip from '@mui/material/Tooltip';

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

import Button from '../buttons/Button';
import ButtonIcon from '../buttons/ButtonIcon';

import type { EmptyFunction, JSXElement } from '../../../models';

interface ButtonAddHandlesProps {
  onOpenDialog: EmptyFunction;
  tooltipText: string | NonNullable<ReactNode>;
  superAdmin?: boolean;
  isDisabled?: boolean;
}

interface TooltipWithButtonProps {
  tooltipText: string | NonNullable<ReactNode>;
  children: JSXElement | Array<JSXElement>;
}

type ButtonIconAddhandlesProps = Omit<ButtonAddHandlesProps, 'tooltipText' | 'superAdmin'>;

const ButtonIconAddhandles: FC<ButtonIconAddhandlesProps> = ({ isDisabled, onOpenDialog }) => {
  return (
    <ButtonIcon color="primary" disabled={isDisabled || false} onClick={onOpenDialog}>
      <PersonAddAltOutlinedIcon fontSize="small" />
    </ButtonIcon>
  );
};

const TooltipWithButton: FC<TooltipWithButtonProps> = props => {
  const { tooltipText, children } = props;

  return (
    <Tooltip title={tooltipText} arrow>
      <span>{children}</span>
    </Tooltip>
  );
};

export const ButtonAddHandlesMobile: FC<ButtonAddHandlesProps> = props => {
  const { isDisabled, onOpenDialog, tooltipText, superAdmin } = props;

  if (superAdmin) {
    return null;
  }

  if (isDisabled) {
    return (
      <TooltipWithButton tooltipText={tooltipText}>
        <ButtonIconAddhandles onOpenDialog={onOpenDialog} isDisabled={isDisabled} />
      </TooltipWithButton>
    );
  }

  return <ButtonIconAddhandles onOpenDialog={onOpenDialog} isDisabled={isDisabled} />;
};

export const ButtonAddHandles: FC<ButtonAddHandlesProps> = props => {
  const { isDisabled, onOpenDialog, tooltipText, superAdmin } = props;

  if (superAdmin) {
    return null;
  }

  if (isDisabled) {
    return (
      <TooltipWithButton tooltipText={tooltipText}>
        <Button
          disabled={isDisabled || false}
          startIcon={<PersonAddAltOutlinedIcon />}
          onClick={onOpenDialog}
        >
          Add new handles
        </Button>
      </TooltipWithButton>
    );
  }

  return (
    <Button
      disabled={isDisabled || false}
      startIcon={<PersonAddAltOutlinedIcon />}
      onClick={onOpenDialog}
    >
      Add new handles
    </Button>
  );
};
