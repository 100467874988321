import type {
  SigninResponse,
  HomePageResponse,
  HomePageData,
  Handle,
  FeedDataWithPagination,
} from '../models';

import Settings from '../constants/settings';

import {
  useQueryRecentHandlesByEntityId,
  useQueryRecentPinnedHandlesByEntityId,
  useQueryHandlesWithoutTwitsByEntityId,
  useInfiniteQueryFeedByEntityId,
} from '../queries/tweets';
import { useQueryEntityInfoById } from '../queries/entity';

interface UseHomePagePayload {
  data: HomePageData;
  recentHandles: HomePageResponse<Array<Handle>>;
  recentPinnedHandles: HomePageResponse<Array<Handle>>;
  handlesWithoutTwits: HomePageResponse<Array<Handle>>;
  feed: FeedDataWithPagination;
}

export type UseHomePageData = UseHomePagePayload['data'];

type UseHomePageDataOptions = SigninResponse;

const useHomePageData = (options: UseHomePageDataOptions): UseHomePagePayload => {
  const { companyId, userId, accountType, jwt } = options;
  const id = accountType === 'COMPANY' ? companyId : userId;

  const entityInfo = useQueryEntityInfoById(id, accountType, jwt);
  const recentHandles = useQueryRecentHandlesByEntityId(id, jwt);
  const recentPinnedHandles = useQueryRecentPinnedHandlesByEntityId(id, jwt);
  const handlesWithoutTwits = useQueryHandlesWithoutTwitsByEntityId(id, jwt);
  const feed = useInfiniteQueryFeedByEntityId({
    id,
    pageNumberInitial: Settings.DEFAULT_PAGE_NUMBER,
    jwt,
  });

  return {
    data: {
      entityInfo: entityInfo?.data,
      isError: entityInfo.isError,
      isLoading: entityInfo.isLoading,
      isRefetching: entityInfo.isRefetching,
      refetch: entityInfo.refetch,
    },

    recentHandles: {
      data: recentHandles?.data,
      isError: recentHandles.isError,
      isLoading: recentHandles.isLoading,
      isRefetching: recentHandles.isRefetching,
      refetch: recentHandles.refetch,
    },

    recentPinnedHandles: {
      data: recentPinnedHandles?.data,
      isError: recentPinnedHandles.isError,
      isLoading: recentPinnedHandles.isLoading,
      isRefetching: recentPinnedHandles.isRefetching,
      refetch: recentPinnedHandles.refetch,
    },

    handlesWithoutTwits: {
      data: handlesWithoutTwits?.data,
      isError: handlesWithoutTwits.isError,
      isLoading: handlesWithoutTwits.isLoading,
      isRefetching: handlesWithoutTwits.isRefetching,
      refetch: handlesWithoutTwits.refetch,
    },

    feed: {
      data: feed?.data,
      isError: feed.isError,
      isLoading: feed.isLoading,
      isRefetching: feed.isRefetching,
      refetch: feed.refetch,
      pagination: {
        isFetchingNextPage: feed.isFetchingNextPage,
        isFetchingPreviousPage: feed.isFetchingPreviousPage,
        fetchNextPage: feed.fetchNextPage,
        fetchPreviousPage: feed.fetchPreviousPage,
        hasNextPage: feed.hasNextPage,
        hasPreviousPage: feed.hasPreviousPage,
      },
    },
  };
};

export default useHomePageData;
