import { type FC, type SyntheticEvent, useState } from 'react';

import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import useTabProps from '../../hooks/use-tab-props';
import useAdminUsersTableData from '../../hooks/use-admin-users-table-data';
import useRowsNew from '../../hooks/use-rows-new';

import type { EmptyFunction, FunctionWithArg, User, CompanyInfo } from '../../models';

import LabelCount from '../../components/common/label-count/LabelCount';
import TabPanel from '../../components/common/tabs/TabPanelComponent';

import { useSuperAdminPageContext } from './SuperAdminPageProvider';

import HandlesTable from '../handles/HandlesTable';
import { TabsContainer } from '../styled-components';
import { columnsUserAll } from './columns';

interface SuperAdminTabUserProps {
  setSelectedRows: FunctionWithArg<Array<string>>;
  data: Array<User> | undefined;
  dataCompanies: Array<CompanyInfo> | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: EmptyFunction;
}

const SuperAdminTabUser: FC<SuperAdminTabUserProps> = props => {
  const { setSelectedRows, data, dataCompanies, isLoading, isError, refetch } = props;

  const [tab, setTab] = useState(0);
  const { setClickRow } = useSuperAdminPageContext();
  const tabProps = useTabProps(3);
  const rowsAll = useAdminUsersTableData(data, dataCompanies);
  const rowsNew = useRowsNew(rowsAll);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setSelectedRows([]);
  };

  if (data) {
    return (
      <Box>
        <TabsContainer value={tab} onChange={handleChange} aria-label="handles tabs">
          <Tab label="All" {...tabProps[0]} />

          {rowsNew.length ? (
            <Tab
              label="New"
              icon={<LabelCount amount={rowsNew.length} />}
              iconPosition="end"
              {...tabProps[1]}
            />
          ) : null}

          <Tab disabled label="Admin" {...tabProps[2]} />
        </TabsContainer>

        <TabPanel value={tab} index={0}>
          <HandlesTable
            rows={rowsAll}
            columns={columnsUserAll}
            setSelectedRowsIds={setSelectedRows}
            onRowClick={setClickRow}
            isLoading={isLoading}
            isError={isError}
            onRefetch={refetch}
          />
        </TabPanel>

        {rowsNew.length ? (
          <TabPanel value={tab} index={1}>
            <HandlesTable
              rows={rowsNew}
              columns={columnsUserAll}
              setSelectedRowsIds={setSelectedRows}
              onRowClick={setClickRow}
              isLoading={isLoading}
              isError={isError}
              onRefetch={refetch}
            />
          </TabPanel>
        ) : null}
      </Box>
    );
  }

  return <Typography p={3}>Is empty</Typography>;
};

export default SuperAdminTabUser;
