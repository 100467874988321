import { jsPDF } from 'jspdf';

import format from 'date-fns/format';

import '../fonts/ProductSansRegular-normal';

import Settings from '../constants/settings';

import type { EmptyFunction, Tweet, FunctionWithArg, StockTwit } from '../models';

import type { ExportHandlesFields } from '../components/dialogs/export-handles-info/DialogExportHandlesInfo';

type Fields = Record<
  keyof Omit<ExportHandlesFields, 'total'>,
  { checked: boolean; title: string; value: string }
>;

interface GeneratePdfOptions {
  exportData: ExportHandlesFields;
  handleItem: Tweet;
  username: string;
  cb: EmptyFunction;
  isStockTwits?: boolean;
}

const generatePdf: FunctionWithArg<GeneratePdfOptions> = options => {
  const { exportData, handleItem, username, cb, isStockTwits } = options;

  const jsPdf = new jsPDF();

  const fields: Fields = {
    date: {
      checked: exportData.date,
      title: 'Date:',
      value: format(
        new Date(Number(handleItem.tweetFieldsModelDTO.createdAt)),
        Settings.DATE_SHORT,
      ),
    },
    time: {
      checked: exportData.time,
      title: 'Time:',
      value: format(
        new Date(Number(handleItem.tweetFieldsModelDTO.createdAt)),
        Settings.TIME_AM_PM,
      ),
    },
    tweetId: {
      checked: exportData.tweetId,
      title: isStockTwits ? 'Ticker ID:' : 'Tweet ID:',
      value: handleItem.tweetFieldsModelDTO.conversationId,
    },
    authorId: {
      checked: exportData.authorId,
      title: 'Author ID:',
      value: `@${username}`,
    },
  };

  const allFields = Object.keys(fields) as Array<keyof Fields>;
  const text = allFields.reduce((prevValue, currValue) => {
    if (exportData.total) {
      return prevValue + `${fields[currValue].title} ${fields[currValue].value}\n`;
    }

    return fields[currValue].checked
      ? prevValue + `${fields[currValue].title} ${fields[currValue].value}\n`
      : prevValue;
  }, '\n');

  if (text) {
    const filename = `${username}-id-${handleItem.tweetFieldsModelDTO.conversationId}-${handleItem.tweetFieldsModelDTO.createdAt}`;
    const fontSize = 16;
    const fontFamily = 'ProductSansRegular';
    const lineWidth = 190;
    const textPositionX = 10;
    const textPositionY = 10;
    const newlines = /\r?\n|\r/gm;

    jsPdf.setFont(fontFamily).setFontSize(fontSize);

    const splitText = jsPdf.splitTextToSize(
      `Text: ${handleItem.tweetFieldsModelDTO.text.replace(newlines, '').trim()}${text}`,
      lineWidth,
    );

    jsPdf.text(exportData.total ? splitText : text, textPositionX, textPositionY);

    jsPdf.save(filename, { returnPromise: true }).then(cb);
  }
};

export const convertStockTwitsToHandleItem = (stockTwit: StockTwit): Tweet => {
  const authorId = 'StockTwits';
  const type = 'photo';

  return {
    id: stockTwit?.id,
    expansionsFieldsModelDTO: {
      attachmentsMediaKeys: null,
      authorId,
    },
    mediaFieldsModelDTO: stockTwit?.imageUrl
      ? [
          {
            mediaKey: null,
            height: 100,
            width: 100,
            previewImageUrl: stockTwit.imageUrl,
            type,
            url: stockTwit.imageUrl,
          },
        ]
      : [],
    tweetFieldsModelDTO: {
      attachmentsModel: {
        media_keys: null,
        pollIds: null,
      },
      contextAnnotations: null,
      conversationId: String(stockTwit.serverId),
      createdAt: stockTwit.stockTwitTimestamp,
      entitiesModel: {
        annotations: null,
        cashtags: null,
        hashtags: null,
        mentions: null,
        urls: null,
      },
      inReplyToUserId: null,
      referencedTweets: {
        referencedTweetId: null,
        type: null,
      },
      text: stockTwit.tweetText,
    },
    userFieldsModelDTO: {
      description: '',
      location: null,
      pinnedTweetId: null,
      profileImageUrl: stockTwit.imageUrl,
      publicMetrics: '',
      url: null,
      username: authorId,
    },
  };
};

export default generatePdf;
