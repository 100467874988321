import {
  type FC,
  type ChangeEventHandler,
  type FocusEventHandler,
  useMemo,
  type FormEventHandler,
} from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useAuth } from '../../context/auth.context';
import useEffectAuthNavigateToDashboard from '../../context/use-effect-auth-navigate-to-dashboard';

import { Routes } from '../../constants/routes';

import PasswordInputComponent from '../../components/common/form-fields/PasswordInput';
import LogoComponent from '../../components/common/logo/Logo';
import TextField from '../../components/common/form-fields/TextField';
import DividerOr from '../../components/common/divider-or/DividerOr';
import ErrorMessage from '../../components/common/error-message/ErrorMessage';

import useSigninPageLoginReducer, { InitialStateKeys } from './SigninPageLogin.state';

import {
  PageContainer,
  PageMainColumn,
  PageSecondaryColumnVariant2,
  PageContentWrapper,
  PageLogoWrapper,
  LoginButtonContainer,
  HeadlineContainer,
  FormContainer,
} from '../styled-components';

const SigninPageLogin: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatchEvent } = useSigninPageLoginReducer();

  useEffectAuthNavigateToDashboard(auth.authInfo.jwt);

  const disableLogin = useMemo(() => {
    const fields = Object.keys(state) as Array<InitialStateKeys>;

    return fields.some(field => !state[field].value);
  }, [state]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    dispatchEvent({
      type: 'change',
      fieldName: target.name,
      fieldValue: target.value,
    });
  };

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({ target }) => {
    dispatchEvent({
      type: 'blur',
      fieldName: target.name,
      fieldValue: target.value,
    });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    auth.signin(
      {
        email: state.username.value.toLocaleLowerCase(),
        password: state.password.value,
      },
      () => {
        navigate(Routes.home.route, {
          state: {
            from: location,
            routeName: Routes.home.routeName,
          },
          replace: true,
        });
      },
    );
  };

  const handleCreate = (): void => {
    auth.resetError('signin');

    navigate(Routes.root.route, {
      state: {
        from: location,
        routeName: Routes.root.routeName,
      },
    });
  };

  if (!auth.authInfo.jwt) {
    return (
      <PageContainer>
        <PageMainColumn>
          <PageContentWrapper>
            <PageLogoWrapper>
              <LogoComponent />
            </PageLogoWrapper>

            <HeadlineContainer>
              <Typography variant="h3" gutterBottom>
                Log in your account
              </Typography>
            </HeadlineContainer>

            <form method="POST" noValidate onSubmit={handleSubmit}>
              <FormContainer>
                <TextField
                  name="username"
                  label="Email address"
                  helperText="Required field"
                  error={state.username.error}
                  success={state.username.success}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <PasswordInputComponent
                  name="password"
                  label="Password"
                  helperText="Required field"
                  error={state.password.error}
                  success={state.password.success}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage>{auth.signinError}</ErrorMessage>

                <Link href="#" underline="hover">
                  Forgot password?
                </Link>
              </FormContainer>

              <Stack spacing={2}>
                <LoginButtonContainer
                  variant="contained"
                  size="large"
                  type="submit"
                  endIcon={<ArrowForwardIcon fontSize="small" />}
                  disabled={disableLogin}
                >
                  Log in
                </LoginButtonContainer>

                <div>
                  <DividerOr />
                </div>

                <Button variant="outlined" size="large" onClick={handleCreate} color="secondary">
                  Create an account
                </Button>
              </Stack>
            </form>
          </PageContentWrapper>
        </PageMainColumn>
        <PageSecondaryColumnVariant2 />
      </PageContainer>
    );
  }

  return null;
};

export default SigninPageLogin;
