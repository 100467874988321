import type { FC } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import type { EmptyFunction, FooterElement, CompanyInfoTable, HandlesStats } from '../../../models';

import { useAvatarLettersCompany, useAvatarLettersUser } from '../../../hooks/use-avatar-letters';

import ButtonLoading from '../../common/buttons/ButtonLoading';
import AgencyCardProfile from '../../common/card-agency-handle/AgencyCardProfile';
import IconsHandleWithColors from '../../common/icons-handle/IconsHandleWithColors';

import DialogBase from '../base/DialogBase';

import { StatsContainer } from '../../../pages/styled-components';
import {
  BadgeMembership,
  BadgeMembershipPrimary,
} from '../../../pages/super-admin/styled-components';
import { DialogProfileContainer, DialogProfileInfoContainer } from '../base/styled-components';

interface DialogPreviewAgencyProfileProps {
  selectedRow: CompanyInfoTable;
  stats: HandlesStats;
  onClose: EmptyFunction;
  onConfirmDelete: EmptyFunction;
}

const footer: FooterElement = options => {
  const { onConfirm, isDisabled, isLoading } = options;

  return (
    <Stack spacing={{ xs: 1, md: 2 }} sx={{ flexGrow: 1, marginTop: '-28px' }}>
      {/* @TODO Uncomment when BE is ready */}
      {/* <ButtonLoading fullWidth size="large">
        Upgrade the membership
      </ButtonLoading> */}

      <ButtonLoading
        fullWidth
        variant="outlined"
        size="large"
        onClick={onConfirm}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        Delete the account
      </ButtonLoading>
    </Stack>
  );
};

const membershipType = {
  BASIC: 'Basic',
  PRO: 'Pro',
};

const DialogPreviewAgencyProfile: FC<DialogPreviewAgencyProfileProps> = props => {
  const { selectedRow, stats, onClose, onConfirmDelete } = props;
  const { id, username, fullName, agencyName, accountType, membership } = selectedRow;
  const avatarLettersUser = useAvatarLettersUser(fullName || '');
  const avatarLettersCompany = useAvatarLettersCompany(agencyName);
  const avatarLetters = accountType === 'COMPANY' ? avatarLettersCompany : avatarLettersUser;

  return (
    <DialogBase
      footer={footer({
        onConfirm: onConfirmDelete,
        isDisabled: false,
      })}
      onClose={onClose}
    >
      <Stack>
        <DialogProfileContainer>
          <Avatar
            alt={fullName ? `${fullName} avatar` : 'avatar image'}
            src={username.avatar || ''}
            sx={{ textTransform: 'uppercase', width: '60px', height: '60px' }}
          >
            {avatarLetters}
          </Avatar>

          <DialogProfileInfoContainer>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Typography variant="h4" noWrap>
                {fullName}
              </Typography>

              {membership === 'PRO' ? (
                <BadgeMembershipPrimary>{membershipType[membership]}</BadgeMembershipPrimary>
              ) : (
                <BadgeMembership>{membershipType[membership]}</BadgeMembership>
              )}
            </Stack>

            <Typography variant="body2" noWrap>
              @{username.name}
            </Typography>
          </DialogProfileInfoContainer>
        </DialogProfileContainer>

        <StatsContainer>
          <IconsHandleWithColors
            unread={stats.tweetNumber}
            published={stats.retweetNumber}
            chat={stats.replyNumber}
          />
        </StatsContainer>

        {id && accountType === 'COMPANY' ? (
          <Box marginBottom="20px">
            <AgencyCardProfile
              companyName={agencyName || ''}
              companyNickname={username.name}
              imageUrl={username.avatar || ''}
              editButton={false}
            />
          </Box>
        ) : null}
      </Stack>
    </DialogBase>
  );
};

export default DialogPreviewAgencyProfile;
