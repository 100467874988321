import type { FC } from 'react';

import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import { common } from '@mui/material/colors';

import type { EmptyFunction } from '../../../models';

import { useAvatarLettersCompany } from '../../../hooks/use-avatar-letters';
import ButtonIconComponent from '../buttons/ButtonIcon';

import { FlexContainer, FlexColumnContainer } from '../styled-components/containers';
import { AgencyIconContainer, AgencyTypography } from './styled-components';

export interface AgencyComponentProps {
  companyName: string;
  companyNickname: string;
  imageUrl: string;
  editButton?: boolean;
  onEditProfile?: EmptyFunction;
  agencyBoxSmall?: boolean;
  isStockTwits?: boolean;
}

const AgencyComponent: FC<AgencyComponentProps> = props => {
  const {
    companyName,
    companyNickname,
    imageUrl,
    editButton,
    onEditProfile,
    agencyBoxSmall,
    isStockTwits,
  } = props;
  const avatarLetters = useAvatarLettersCompany(companyName);

  const handleEditProfile = () => {
    onEditProfile && onEditProfile();
  };

  return (
    <Grid
      container
      spacing={2}
      wrap="nowrap"
      classes={agencyBoxSmall && { root: 'agency-box-small' }}
    >
      {companyName ? (
        <Grid item>
          <AgencyIconContainer alt={companyName} src={imageUrl}>
            {avatarLetters}
          </AgencyIconContainer>
        </Grid>
      ) : null}

      <Grid item zeroMinWidth sx={editButton ? { width: '100%' } : null}>
        <FlexContainer sx={editButton ? { justifyContent: 'space-between' } : null}>
          <FlexColumnContainer sx={{ minWidth: 0 }}>
            {companyName ? <AgencyTypography noWrap>{companyName}</AgencyTypography> : null}

            <AgencyTypography variant="body2" noWrap>
              {isStockTwits ? `#${companyNickname}` : `@${companyNickname}`}
            </AgencyTypography>
          </FlexColumnContainer>

          {editButton ? (
            <ButtonIconComponent
              aria-label="Edit company profile button"
              color="primary"
              onClick={handleEditProfile}
              sx={{ backgroundColor: common.white }}
            >
              <EditIcon aria-label="Edit company profile icon" />
            </ButtonIconComponent>
          ) : null}
        </FlexContainer>
      </Grid>
    </Grid>
  );
};

export default AgencyComponent;
