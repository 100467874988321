import httpBuilder, { headersAuthorization } from '../configs/axios';
import catchError from '../configs/catch-errors';
import { ApiUsers } from '../constants/api';
import type { User, UserUpdate, UserProfileUpdatePayload } from '../models';

export const getUsers = (jwt: string) => {
  return httpBuilder
    .get<Array<User>>(ApiUsers.all, headersAuthorization(jwt))
    .then(response => response.data);
};

export const getUserById = (id: string, jwt: string) => {
  return httpBuilder
    .get<User>(ApiUsers.byId(id), headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const updateUserById = (
  id: string,
  data: UserUpdate | UserProfileUpdatePayload,
  jwt: string,
) => {
  return httpBuilder
    .put<User>(ApiUsers.byId(id), data, headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};

export const deleteUserById = (id: string, jwt: string) => {
  return httpBuilder
    .delete<User>(ApiUsers.byId(id), headersAuthorization(jwt))
    .then(response => response.data)
    .catch(catchError);
};
