import type { FC } from 'react';
import { LabelContainer } from './styled-components';

interface LabelCountProps {
  amount: number;
}

const LabelCount: FC<LabelCountProps> = props => {
  const { amount } = props;

  return <LabelContainer>{amount}</LabelContainer>;
};

export default LabelCount;
