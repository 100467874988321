import { type ChangeEventHandler, type FC, useState } from 'react';

import Stack from '@mui/material/Stack';

import type { EmptyFunction, JSXElement, FooterElement } from '../../../models';

import Button from '../../common/buttons/Button';
import TextField from '../../common/form-fields/TextField';
import RadioGroupColors from '../../common/form-fields/RadioGroupColors';

import DialogBase from '../base/DialogBase';

interface DialogAddNewFolderProps {
  onClose: EmptyFunction;
}

const header = (): JSXElement => <>Add new folder</>;

const footer: FooterElement = options => {
  const { onConfirm, isDisabled } = options;

  return (
    <Button fullWidth size="large" onClick={onConfirm} disabled={isDisabled}>
      Add now
    </Button>
  );
};

const DialogAddNewFolder: FC<DialogAddNewFolderProps> = props => {
  const { onClose } = props;
  const [handleName, setHandleName] = useState('');
  const [color, setColor] = useState('');

  const onSelectOption = (value: string): void => {
    setColor(value);
  };

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setHandleName(target.value);
  };

  const handleConfirm = () => {
    console.warn('handleConfirm');
    console.warn('color', color);
  };

  return (
    <DialogBase
      header={header()}
      footer={footer({
        onConfirm: handleConfirm,
        isDisabled: !handleName,
      })}
      onClose={onClose}
      error={/* TODO  addFolderMutation.error?.message ||  */ ''}
    >
      <Stack spacing={{ xs: 1, md: 2 }}>
        <TextField label="Folder name" value={handleName} onChange={handleChangeName} />
        <RadioGroupColors label="Select folder color" onSelectOption={onSelectOption} />
      </Stack>
    </DialogBase>
  );
};

export default DialogAddNewFolder;
