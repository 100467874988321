import { type FC, useState, Fragment } from 'react';

import type { Tweet, StockTwit, FeedDataWithPagination } from '../../models';

import { getDifferenceInDaysWithText } from '../../utils/date';
import generatePdf, { convertStockTwitsToHandleItem } from '../../utils/generate-pdf';

import DialogExportHandlesInfo, {
  type ExportHandlesFields,
} from '../../components/dialogs/export-handles-info/DialogExportHandlesInfo';
import ButtonLoading from '../../components/common/buttons/ButtonLoading';
import InProgressInline from '../../components/common/in-progress/InProgressInline';
import FeedTimeAgoDivider from '../../components/common/feed-time-ago-divider/FeedTimeAgoDivider';

import FeedItemTwitter from './FeedItemTwitter';
import FeedItemStockTwits from './FeedItemStockTwits';
import { FeedContainer } from './styled-components';

interface FeedProps {
  data: FeedDataWithPagination;
}

const Feed: FC<FeedProps> = ({ data }) => {
  const [exportHandlesInfoDialog, setExportHandlesInfoDialog] = useState(false);
  const [username, setUsername] = useState('');
  const [handleItemTweet, setHandleItemTweet] = useState<Tweet>();
  const [stockTwitItem, setStockTwitItem] = useState<StockTwit>();
  const [isStockTwits, setIsStockTwits] = useState(false);

  const { data: feedData, pagination } = data;

  const handleOpenExportDialog = (tweet: Tweet) => {
    setExportHandlesInfoDialog(true);
    setHandleItemTweet(tweet);
    setIsStockTwits(false);
  };

  const handleOpenExportDialogStockTwits = (data: StockTwit) => {
    setExportHandlesInfoDialog(true);
    setStockTwitItem(data);
    setIsStockTwits(true);
  };

  const handleFetchNextPage = (): void => {
    pagination.fetchNextPage();
  };

  const handleCloseExportDialog = (): void => {
    setExportHandlesInfoDialog(false);
  };

  const handleOpenFolderDialog = (): void => {
    console.warn('@TODO implement open folder dialog');
  };

  if (data.isLoading) {
    return <InProgressInline size={20} />;
  }

  if (feedData?.pages.length) {
    const handleConfirmExportDialog = (exportData: ExportHandlesFields): void => {
      if (isStockTwits) {
        if (stockTwitItem) {
          generatePdf({
            exportData,
            handleItem: convertStockTwitsToHandleItem(stockTwitItem),
            username: 'StockTwits',
            cb: () => setExportHandlesInfoDialog(false),
            isStockTwits: true,
          });
        }
      } else {
        if (handleItemTweet) {
          generatePdf({
            exportData,
            handleItem: handleItemTweet,
            username,
            cb: () => setExportHandlesInfoDialog(false),
          });
        }
      }
    };

    return (
      <FeedContainer>
        {feedData.pages.map(pageItem => {
          return pageItem.feedDTOS.map((feedItem, feedItemIndex, feedItemArray) => {
            const prevFeed = feedItemArray[feedItemIndex - 1];
            const prevFeedDiff = prevFeed
              ? getDifferenceInDaysWithText(Number(prevFeed.timestamp))
              : undefined;
            const currDiff = getDifferenceInDaysWithText(Number(feedItem.timestamp));

            if (feedItem.stockTwit) {
              return (
                <Fragment key={`fragment-${feedItem.stockTwitTimestamp}-${feedItemIndex}`}>
                  <FeedTimeAgoDivider
                    show={prevFeedDiff !== currDiff}
                    timestamp={feedItem.stockTwitTimestamp}
                  />

                  <FeedItemStockTwits
                    author="StockTwits"
                    key={`${feedItem.stockTwitResponseId}-${feedItem.stockTwitTimestamp}`}
                    data={{
                      id: feedItem.stockTwitResponseId,
                      serverId: feedItem.serverId,
                      stockTwit: feedItem.stockTwit,
                      stockTwitTimestamp: feedItem.stockTwitTimestamp,
                      tickerId: feedItem.tickerId,
                      tweetText: feedItem.tweetText,
                      imageUrl: feedItem.imageUrl,
                    }}
                    handleName="StockTwits"
                    avatarSrc={feedItem.profileImageUrl}
                    onOpenExportDialog={handleOpenExportDialogStockTwits}
                    onOpenFolderDialog={handleOpenFolderDialog}
                  />
                </Fragment>
              );
            }

            return (
              <Fragment key={`${feedItem.tweetId}-${feedItem.tweetFieldsModelDTO.createdAt}`}>
                <FeedTimeAgoDivider
                  show={prevFeedDiff !== currDiff}
                  timestamp={feedItem.tweetFieldsModelDTO.createdAt}
                />

                <FeedItemTwitter
                  data={{
                    id: feedItem.tweetId,
                    expansionsFieldsModelDTO: feedItem.expansionsFieldsModelDTO,
                    mediaFieldsModelDTO: feedItem.mediaFieldsModelDTO,
                    tweetFieldsModelDTO: feedItem.tweetFieldsModelDTO,
                    userFieldsModelDTO: feedItem.userFieldsModelDTO,
                  }}
                  author={feedItem.username}
                  handleName={feedItem.handleName}
                  avatarSrc={feedItem.profileImageUrl}
                  onOpenExportDialog={data => {
                    setUsername(feedItem.username);
                    handleOpenExportDialog(data);
                  }}
                  onOpenFolderDialog={handleOpenFolderDialog}
                />
              </Fragment>
            );
          });
        })}

        {pagination.hasNextPage ? (
          <ButtonLoading
            fullWidth
            onClick={handleFetchNextPage}
            disabled={!pagination.hasNextPage || pagination.isFetchingNextPage}
            isLoading={pagination.isFetchingNextPage}
          >
            Load more
          </ButtonLoading>
        ) : null}

        {exportHandlesInfoDialog ? (
          <DialogExportHandlesInfo
            onClose={handleCloseExportDialog}
            onConfirm={handleConfirmExportDialog}
          />
        ) : null}
      </FeedContainer>
    );
  }

  return null;
};

export default Feed;
