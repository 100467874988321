import { useState, type FC, type SyntheticEvent } from 'react';

import { set } from 'date-fns';

import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import Tab from '@mui/material/Tab';

import { FunctionWithArg } from '../../../models';
import useTabProps from '../../../hooks/use-tab-props';

import SelectComponent from '../form-fields/Select';
import TabPanel from '../tabs/TabPanelComponent';
import { CustomTabsSecondary } from '../../../pages/styled-components';

interface TimeTabsComponentProps {
  onSetDatestamp: FunctionWithArg<Date>;
  subDate: Date;
  timeRange: Array<string>;
}

type PartOfDay = 'AM' | 'PM';

const timeOptions = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
];

const setTimeAndParse = (date: Date, time: string, partOfDay: PartOfDay): Date => {
  const [hours, minutes] = time.split(':');

  const newDateWithTime = set(date, {
    hours: partOfDay === 'PM' ? Number(hours) + 12 : Number(hours),
    minutes: Number(minutes),
    seconds: 0,
  });

  return newDateWithTime;
};

const TimeTabsComponent: FC<TimeTabsComponentProps> = props => {
  const { onSetDatestamp, subDate, timeRange } = props;

  const tabProps = useTabProps(2);
  const [valueTab, setValueTab] = useState(timeRange[1].length ? 1 : 0);
  const [valueAM, setValueAM] = useState(timeRange[0]);
  const [valuePM, setValuePM] = useState(timeRange[1]);

  const handleChangeCustomTabs = (_event: SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const onSelectOption = (value: unknown, partOfDay: PartOfDay): void => {
    onSetDatestamp(setTimeAndParse(subDate, value as string, partOfDay));

    if (partOfDay === 'AM') {
      setValueAM(value as string);
    } else {
      setValuePM(value as string);
    }
  };

  return (
    <Stack spacing={1} sx={{ margin: 2 }}>
      <div>
        <Typography variant="subtitle2">Time</Typography>

        <TabPanel value={valueTab} index={0}>
          <SelectComponent
            options={timeOptions}
            onChange={value => onSelectOption(value, 'AM')}
            label="Select time"
            value={valueAM}
          />
        </TabPanel>

        <TabPanel value={valueTab} index={1}>
          <SelectComponent
            options={timeOptions}
            onChange={value => onSelectOption(value, 'PM')}
            label="Select time"
            value={valuePM}
          />
        </TabPanel>
      </div>

      <CustomTabsSecondary
        variant="fullWidth"
        value={valueTab}
        onChange={handleChangeCustomTabs}
        aria-label="custom tabs"
        sx={{ '.MuiTabs-flexContainer': { width: 'auto' } }}
      >
        <Tab label="AM" {...tabProps[0]} />
        <Tab label="PM" {...tabProps[1]} />
      </CustomTabsSecondary>
    </Stack>
  );
};

export default TimeTabsComponent;
