import { styled } from '@mui/material/styles';
import { common } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { flexGrow1, flexShrink0 } from '../../components/common/styled-components/primitives';

export const HomePageColumnTitle = styled(Typography)`
  display: none;

  @media (min-width: 900px) {
    display: block;
    margin-bottom: 10px;
  }
`;

export const HomePageColumnCard = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    overflow: 'hidden',
    backgroundColor: common.white,
    borderRadius: '16px',
    boxShadow: '0 1px 1px rgb(0 0 0 / 25%)',
  },
}));

export const HomePageBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginBottom: '26px',
  },
}));

export const HomePageColumnContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  margin: '-16px -16px 0',
  padding: '16px 32px',
  borderTop: `1px solid ${theme.palette.textLight.light1}`,
  backgroundColor: common.white,
  boxShadow: '0 1px 1px rgb(0 0 0 / 25%)',
  zIndex: 1,

  '&.empty-pinned-handles': {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    flexShrink0,
    position: 'sticky',
    top: '146px',
    margin: '0',
    padding: '0',
    width: '270px',
    height: 'fit-content',
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&.empty-pinned-handles': {
      display: 'block',
    },
  },
}));

export const TwitterContainer = styled('span')(({ theme }) => ({
  color: theme.palette.tweet.main,
}));

export const HomePageMainContainer = styled('div')`
  ${flexGrow1};
  padding: 92px 0 20px;

  &.empty-pinned-handles {
    padding-top: 6px;
  }

  @media (min-width: 900px) {
    padding: 0 30px;
    box-sizing: content-box;
  }
`;

export const FeedContainer = styled('div')`
  @media (min-width: 900px) {
    max-width: 446px;
    margin: 0 auto;
  }

  @media (min-width: 1024px) {
    max-width: 335px;
  }

  @media (min-width: 1080px) {
    max-width: 440px;
  }

  @media (min-width: 1100px) {
    max-width: 466px;
  }
`;
