import { useMemo } from 'react';

export const useAvatarLettersUser = (name: string) => {
  return useMemo(() => {
    const nameParts = name.split(' ').map(item => item.trim());

    if (nameParts.length > 1) {
      return `${nameParts[0][0]}${nameParts[1][0]}`;
    }

    return name[0];
  }, [name]);
};

export const useAvatarLettersCompany = (name: string) => {
  return useMemo(() => {
    if (name.length > 1) {
      return name.slice(0, 1);
    }

    return name;
  }, [name]);
};
