import type { FC } from 'react';

import type { GridRenderCellParams } from '@mui/x-data-grid';

import { getJoinedDate } from '../../utils/date';

const RenderJoinedDate: FC<GridRenderCellParams> = ({ value }) => {
  return <>{getJoinedDate(value)}</>;
};

export default RenderJoinedDate;
