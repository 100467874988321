export const Routes = {
  home: {
    id: 'home',
    route: '/home',
    routeName: 'Dashboard',
  },
  handles: {
    id: 'handles',
    route: '/home/handles',
    routeName: 'Handles list',
  },
  settings: {
    id: 'settings',
    route: '/home/settings',
    routeName: 'Settings',
  },
  support: {
    id: 'support',
    route: '/home/support',
    routeName: 'Support',
  },
  profile: {
    id: 'profile',
    route: '/home/profile',
    routeName: 'Profile',
  },
  login: {
    id: 'login',
    route: '/login',
    routeName: 'Login',
  },
  root: {
    id: 'root',
    route: '/',
    routeName: 'Registration',
  },
  handlesAll: {
    id: 'handlesAll',
    route: '/home/handles-all',
    routeName: 'All Handles',
  },
  handlesPinned: {
    id: 'handlesPinned',
    route: '/home/handles-pinned',
    routeName: 'Pinned Handles',
  },
  handleById: {
    id: 'handleById',
    route: (id: number) => `/home/handles/${id}`,
    routeName: 'Handles profile',
  },
} as const;
