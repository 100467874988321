import { useMemo } from 'react';

import { Routes } from '../../../constants/routes';

export const useTabsRoutes = () => {
  return useMemo(
    () => [Routes.home.route, Routes.handles.route, Routes.settings.route, Routes.support.route],
    [],
  );
};

export const useTabsRoutesSuperAdmin = () => {
  return useMemo(() => [Routes.home.route, Routes.settings.route, Routes.support.route], []);
};
