import type { FC } from 'react';

import { Route } from 'react-router-dom';

import { SentryRoutes } from '../../sentry-init';

import type {
  AuthInfo,
  User,
  HandlesStats,
  FeedDataWithPagination,
  HomePageData,
  RecentHandlesHome,
  CompanyInfo,
} from '../../models';

import useImageUrl from '../../hooks/use-image-url';

import DashboardPage2 from '../dashboard-2/DashboardPage';
import HandlesPage from '../handles/HandlesPage';
import ProfilePage from '../profile/ProfilePage';
import SettingsPage from '../settings/SettingsPage';
import SupportPage from '../support/SupportPage';
import ErrorPageBackToDashboard from '../error/ErrorPageBackToDashboard';
import HandlesAllPinnedPage from '../handles-all-pinned/HandlesAllPinnedPage';
import HandleProfilePage from '../handle-profile/HandleProfilePage';

interface HomeRoutesProps {
  data: HomePageData;
  recentHandles: RecentHandlesHome;
  recentPinnedHandles: RecentHandlesHome;
  handlesWithoutTwits: RecentHandlesHome;
  authInfo: AuthInfo;
  handlesStats: HandlesStats;
  profilePageUser: User;
  feed: FeedDataWithPagination;
}

const HomeRoutes: FC<HomeRoutesProps> = props => {
  const {
    data: { entityInfo },
    recentHandles,
    recentPinnedHandles,
    handlesWithoutTwits,
    authInfo,
    handlesStats,
    profilePageUser,
    feed,
  } = props;

  const imageUrl = useImageUrl(
    authInfo.userId,
    (entityInfo as CompanyInfo | User)?.accountType === 'COMPANY'
      ? (entityInfo as CompanyInfo)?.usersList
      : [],
  );

  if (entityInfo && recentHandles.data && recentPinnedHandles.data && handlesWithoutTwits.data) {
    return (
      <SentryRoutes>
        <Route
          index
          element={
            <DashboardPage2
              entityId={entityInfo.accountType === 'COMPANY' ? authInfo.companyId : authInfo.userId}
              accountType={entityInfo.accountType}
              usersList={
                entityInfo.accountType === 'COMPANY' ? (entityInfo as CompanyInfo).usersList : []
              }
              companyName={
                entityInfo.accountType === 'COMPANY' ? (entityInfo as CompanyInfo).companyName : ''
              }
              companyNickname={
                entityInfo.accountType === 'COMPANY'
                  ? (entityInfo as CompanyInfo).name
                  : (entityInfo as User).username
              }
              handles={handlesWithoutTwits.data || []}
              handlesStats={handlesStats}
              imageUrl={
                entityInfo.accountType === 'COMPANY' ? imageUrl : (entityInfo as User).imageUrl
              }
              recentHandles={recentHandles}
              recentPinnedHandles={recentPinnedHandles}
              handlesWithoutTwits={handlesWithoutTwits}
              feed={feed}
              jwt={authInfo.jwt}
            />
          }
        />
        <Route
          path="/handles"
          element={
            <HandlesPage
              accountType={entityInfo.accountType}
              handles={handlesWithoutTwits.data || []}
              entityId={entityInfo.accountType === 'COMPANY' ? authInfo.companyId : authInfo.userId}
              jwt={authInfo.jwt}
            />
          }
        />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route
          path="/profile"
          element={
            <ProfilePage
              user={profilePageUser}
              companyEmail={entityInfo.accountType === 'COMPANY' ? entityInfo.email : ''}
              companyName={
                entityInfo.accountType === 'COMPANY' ? (entityInfo as CompanyInfo).companyName : ''
              }
              companyNickname={
                entityInfo.accountType === 'COMPANY'
                  ? (entityInfo as CompanyInfo).name
                  : (entityInfo as User).username
              }
              imageUrl={
                entityInfo.accountType === 'COMPANY' ? imageUrl : (entityInfo as User).imageUrl
              }
              handlesStats={handlesStats}
            />
          }
        />
        <Route
          path="/handles-all"
          element={<HandlesAllPinnedPage handles={handlesWithoutTwits.data || []} />}
        />
        <Route
          path="/handles-pinned"
          element={
            <HandlesAllPinnedPage
              handles={handlesWithoutTwits.data?.filter(handle => handle.pinned) || []}
            />
          }
        />
        <Route
          path="/handles/:id"
          element={
            <HandleProfilePage
              handles={handlesWithoutTwits.data || []}
              entityId={entityInfo.accountType === 'COMPANY' ? authInfo.companyId : authInfo.userId}
              jwt={authInfo.jwt}
            />
          }
        />
        <Route path="*" element={<ErrorPageBackToDashboard />} />
      </SentryRoutes>
    );
  }

  return null;
};

export default HomeRoutes;
