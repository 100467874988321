export const FOLDER_COLORS = [
  '#2e79ea',
  '#36ae7c',
  '#ff7f3f',
  '#dd2763',
  '#ffaa21',
  '#0f1e35',
  '#009ee3',
  '#f34d16',
];
