import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // https://react-query.tanstack.com/guides/window-focus-refetching
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
