import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Container from '@mui/system/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';

import type { Handle } from '../../models';

import { Routes } from '../../constants/routes';

import TwitterHandleCard from '../../components/common/card-twitter-handle/TwitterHandleCard';
import { HomePageBasicContainer, HandlesGrid } from '../styled-components';
import useHomePageData from '../../hooks/use-home-page-data';
import { useAuth } from '../../context/auth.context';

interface HandlesAllPinnedPageProps {
  handles: Array<Handle>;
}

const HandlesAllPinnedPage: FC<HandlesAllPinnedPageProps> = ({ handles }) => {
  const { authInfo } = useAuth();
  const { data } = useHomePageData(authInfo);

  return (
    <HomePageBasicContainer>
      <Container maxWidth="lg">
        {data ? (
          <Grid container columnSpacing={{ xs: 2, md: 3.75 }} columns={4}>
            {handles.map((handle, handleIndex) => (
              <HandlesGrid key={`${handleIndex}-${handle.id}`}>
                <Link
                  to={Routes.handleById.route(handle.id)}
                  underline="none"
                  component={RouterLink}
                  state={{
                    routeName: Routes.handleById.routeName,
                  }}
                >
                  <TwitterHandleCard handle={handle} />
                </Link>
              </HandlesGrid>
            ))}
          </Grid>
        ) : null}
      </Container>
    </HomePageBasicContainer>
  );
};

export default HandlesAllPinnedPage;
