import { useMemo } from 'react';

import type { CompanyInfo, CompanyInfoTable } from '../models';

const useAdminCompaniesTableData = (data: Array<CompanyInfo> | undefined) => {
  return useMemo<Array<CompanyInfoTable>>(() => {
    if (data?.length) {
      return data.map(
        ({ id, name, email, companyName, trackHandlesAmount, usersList, accountType }) => {
          const user = usersList.find(
            userItem => userItem.accountType === 'COMPANY' && userItem.authority === 'ROLE_ADMIN',
          );

          return {
            id: user?.id || '',
            username: {
              avatar: user?.imageUrl || '',
              name: companyName,
            },
            fullName: user?.fullname || name,
            email,
            agencyName: companyName,
            date: user?.joinDate || -1,
            membership: user?.membershipType || 'BASIC',
            trackAmount: trackHandlesAmount,
            expires: 'n days',
            status: false,
            admin: user?.authority === 'ROLE_ADMIN' || false,
            user: user,
            companyId: id,
            accountType,
          };
        },
      );
    }

    return [];
  }, [data]);
};

export default useAdminCompaniesTableData;
