import type { FC, MouseEventHandler } from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

const ButtonIconComponent: FC<IconButtonProps> = props => {
  const { size, 'aria-label': ariaLabel, color, sx, onClick, children, ...restProps } = props;

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <IconButton
      color={color || 'error'}
      onClick={handleClick}
      aria-label={ariaLabel}
      size={size || 'medium'}
      sx={sx || {}}
      {...restProps}
    >
      {children}
    </IconButton>
  );
};

export default ButtonIconComponent;
