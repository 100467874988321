import type { FC } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { common } from '@mui/material/colors';

const InProgress: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  return (
    <Backdrop
      sx={{
        color: common.white,
        zIndex: ({ zIndex }) => zIndex.drawer + 10,
        backgroundColor: 'rgba(15, 30, 53, .3)',
      }}
      open={isOpen}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default InProgress;
