import { type FC, type ChangeEventHandler, type SyntheticEvent, useState, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';

import { useMutationAddRule } from '../../../queries/tweets';
import { CustomTabsHighlighted } from '../../../pages/styled-components';

import useTabProps from '../../../hooks/use-tab-props';

import type { EmptyFunction, JSXElement, FooterElement } from '../../../models';

import TabPanel from '../../common/tabs/TabPanelComponent';
import TextField from '../../common/form-fields/TextField';
import TextFieldTwitterId from '../../common/form-fields/TextFieldTwitterId';
import ButtonLoading from '../../common/buttons/ButtonLoading';
import SearchTickerId from '../../common/search-ticker-id/SearchTickerId';

import DialogBase from '../base/DialogBase';

interface DialogAddNewHandlesProps {
  onClose: EmptyFunction;
  companyName: string;
  entityId: string;
  profileNickname: string;
  jwt: string;
}

const header = (): JSXElement => <>Add new handles</>;

const footer: FooterElement = options => {
  const { onConfirm, isDisabled, isLoading } = options;

  return (
    <ButtonLoading
      fullWidth
      size="large"
      onClick={onConfirm}
      disabled={isDisabled}
      isLoading={isLoading}
    >
      Add now
    </ButtonLoading>
  );
};

const DialogAddNewHandles: FC<DialogAddNewHandlesProps> = props => {
  const { onClose, companyName, entityId, profileNickname, jwt } = props;

  const [handleName, setHandleName] = useState('');
  const [itemId, setItemId] = useState('');
  const [tickerId, setTickerId] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  const tabProps = useTabProps(2);
  const addRule = useMutationAddRule(jwt);

  const isDisabled = useMemo(() => {
    if (currentTab === 0) {
      return !handleName || !itemId || addRule.isLoading;
    }

    return !handleName || !tickerId || addRule.isLoading;
  }, [currentTab, handleName, itemId, addRule.isLoading, tickerId]);

  const handleConfirm = () => {
    if (currentTab === 0) {
      addRule.mutate(
        {
          companyName,
          companyId: entityId,
          handleName,
          rules: itemId.split(',').map(item => item.trim()),
          username: profileNickname,
          isStockTwits: false,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    } else {
      addRule.mutate(
        {
          companyName,
          companyId: entityId,
          handleName,
          tickerId,
          username: profileNickname,
          isStockTwits: true,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    }
  };

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setHandleName(target.value);
  };

  const handleChangeTickerId = (value: string) => {
    setTickerId(value);
  };

  const handleItemId: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setItemId(target.value.replace(/^@/, ''));
  };

  const handleTabsChange = (_event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <DialogBase
      header={header()}
      footer={footer({
        onConfirm: handleConfirm,
        isDisabled,
        isLoading: addRule.isLoading,
      })}
      onClose={onClose}
      error={addRule.error?.message || ''}
    >
      <CustomTabsHighlighted value={currentTab} onChange={handleTabsChange} aria-label="tabs">
        <Tab label="Twitter" {...tabProps[0]} disabled={addRule.isLoading} />
        <Tab label="StockTwits" {...tabProps[1]} disabled={addRule.isLoading} />
      </CustomTabsHighlighted>

      <TabPanel value={currentTab} index={0}>
        <Stack spacing={{ xs: 1, md: 2 }}>
          <TextField
            label="Name"
            value={handleName}
            onChange={handleChangeName}
            disabled={addRule.isLoading}
          />
          <TextFieldTwitterId
            label="Twitter ID"
            value={itemId}
            onChange={handleItemId}
            disabled={addRule.isLoading}
          />
        </Stack>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <Stack spacing={{ xs: 1, md: 2 }}>
          <TextField
            label="Name"
            value={handleName}
            onChange={handleChangeName}
            disabled={addRule.isLoading}
          />
          <SearchTickerId onChangeTickerId={handleChangeTickerId} />
        </Stack>
      </TabPanel>
    </DialogBase>
  );
};

export default DialogAddNewHandles;
