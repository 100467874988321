import type { FC, MouseEventHandler } from 'react';

import type { GridRenderCellParams } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

import type { CompanyInfoTable } from '../../models';

import ButtonEditComponent from '../../components/common/buttons/ButtonEdit';
import { useSuperAdminPageContext } from './SuperAdminPageProvider';

const RenderTrackNumber: FC<GridRenderCellParams<number, CompanyInfoTable>> = ({ value, row }) => {
  const { showEditTrackNumberDialog, setTrackAmount, setRowData } = useSuperAdminPageContext();

  const onOpenDialog: MouseEventHandler = event => {
    event.stopPropagation();

    setTrackAmount(Number(value));
    setRowData(row);
    showEditTrackNumberDialog(true);
  };

  return (
    <>
      <Typography sx={{ marginRight: 1 }}>{value}</Typography>

      <ButtonEditComponent onClick={onOpenDialog} />
    </>
  );
};

export default RenderTrackNumber;
