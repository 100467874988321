import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';

import type { StockTwitsInfo } from '../../../models';

export const StyledAutocomplete = styled(Autocomplete<StockTwitsInfo>)`
  .MuiFilledInput-root {
    padding-top: 11px;
  }

  .MuiAutocomplete-endAdornment .MuiButtonBase-root {
    background-color: transparent;
  }

  .MuiFormLabel-root {
    transform: translate(12px, 13px) scale(1);

    &.Mui-focused,
    &.MuiFormLabel-filled {
      transform: translate(12px, 4px) scale(0.75);
    }
  }
`;

export const MuiAutocompleteOption = styled('li')`
  &.MuiAutocomplete-option {
    display: block;
  }
`;
