import type { FC } from 'react';

import type { Tweet } from '../../models';

import FeedItem, { type FeedItemCommonProps } from './FeedItem';

interface FeedItemTwitterProps extends Omit<FeedItemCommonProps, 'data'> {
  data: Tweet;
}

const FeedItemTwitter: FC<FeedItemTwitterProps> = props => {
  const { data, author, handleName, avatarSrc, onOpenExportDialog, onOpenFolderDialog } = props;

  return (
    <FeedItem
      data={data}
      author={author}
      handleName={handleName}
      avatarSrc={avatarSrc}
      onOpenExportDialog={onOpenExportDialog}
      onOpenFolderDialog={onOpenFolderDialog}
    />
  );
};

export default FeedItemTwitter;
