import { createContext, FC, ReactNode, useContext } from 'react';

import type {
  EmptyFunction,
  FunctionWithTwoArgs,
  Signin,
  Signout,
  Company,
  AuthInfo,
  FunctionWithArg,
} from '../models';

import {
  useMutationRegisterCompany,
  useMutationLogin,
  useMutationLogout,
} from '../queries/company';

import useAuthInfoLocalStorage from '../hooks/use-auth-info-local-storage';

type authSection = 'signup' | 'signin' | 'signout';

interface Auth {
  authInfo: AuthInfo;
  signin: FunctionWithTwoArgs<Signin, EmptyFunction>;
  signout: (value: Signout, value1?: EmptyFunction) => void;
  signup: FunctionWithTwoArgs<Company, EmptyFunction>;
  signupError: string;
  signinError: string;
  signoutError: string;
  resetError: FunctionWithArg<authSection>;
}

const AuthContext = createContext({} as Auth);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { authInfo, setAuthInfo, removeJwtToken } = useAuthInfoLocalStorage();
  const registerCompany = useMutationRegisterCompany();
  const login = useMutationLogin();
  const logout = useMutationLogout(authInfo.jwt);

  const signin = (newUserData: Signin, callback: EmptyFunction): void => {
    login.mutate(newUserData, {
      onSuccess: data => {
        setAuthInfo(data);
        callback();
      },
    });
  };

  const resetError = (authSection: authSection): void => {
    const methods = {
      signup: registerCompany,
      signin: login,
      signout: logout,
    };

    if (methods[authSection].isError) {
      methods[authSection].reset();
    }
  };

  const signout = (userData: Signout, callback?: EmptyFunction): void => {
    logout.mutate(userData, {
      onSuccess: () => {
        removeJwtToken();

        if (callback) {
          callback();
        }
      },
    });
  };

  const signup = (data: any, callback: EmptyFunction): void => {
    registerCompany.mutate(data, {
      onSuccess: () => {
        callback();
      },
    });
  };

  const signupError = registerCompany.error?.message || '';
  const signinError = login.error?.message || '';
  const signoutError = logout.error?.message || '';

  const value: Auth = {
    authInfo,
    signup,
    signin,
    signout,
    signupError,
    signinError,
    signoutError,
    resetError,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): Auth => {
  return useContext(AuthContext);
};

export default AuthProvider;
