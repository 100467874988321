import Typography from '@mui/material/Typography';
import type { DefaultComponentProps } from '@mui/material/OverridableComponent';

import type { ListChildComponentProps } from 'react-window';

import type { StockTwitsInfo } from '../../../models';

import { MuiAutocompleteOption } from './styled-components';

const createRenderRow = (padding: number) => {
  return (props: ListChildComponentProps) => {
    const { data, index, style } = props;

    const dataSet = data[index] as [DefaultComponentProps<any>, StockTwitsInfo, string];
    const sx = {
      ...style,
      top: (style.top as number) + padding,
    };

    return (
      <MuiAutocompleteOption {...dataSet[0]} sx={sx}>
        {dataSet[1].tickerId}

        <Typography variant="body2" sx={{ fontSize: '0.75em' }}>
          {dataSet[1].description}
        </Typography>
      </MuiAutocompleteOption>
    );
  };
};

export default createRenderRow;
