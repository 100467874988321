import type { FC } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Box from '@mui/material/Box';

import type { User, HandlesStats } from '../../../models';
import Button from '../buttons/Button';
import IconsHandleComponent from '../icons-handle/IconsHandle';
import GroupAvatars from '../group-avatars/GroupAvatars';

import AgencyComponent from './Agency';
import { AgencyHandleInfoIconsContainer, AgencyButtonWrapper } from './styled-components';

interface AgencyHandleCardProps {
  usersList: Array<User>;
  companyName: string;
  companyNickname: string;
  handlesStats: HandlesStats;
  imageUrl: string | null;
  size?: 'small' | 'large';
}

const AgencyHandleCard: FC<AgencyHandleCardProps> = props => {
  const { size, usersList, companyName, companyNickname, handlesStats, imageUrl } = props;

  return (
    <Card>
      <CardContent>
        <Stack direction="row">
          <AgencyComponent
            companyName={companyName}
            companyNickname={companyNickname}
            imageUrl={imageUrl || ''}
          />

          {size === 'large' && (
            <AgencyButtonWrapper>
              <Button
                variant="outlined"
                startIcon={<ManageAccountsOutlinedIcon fontSize="small" />}
              >
                Manage handles
              </Button>
            </AgencyButtonWrapper>
          )}
        </Stack>

        {handlesStats ? (
          <AgencyHandleInfoIconsContainer>
            <IconsHandleComponent
              unread={handlesStats.tweetNumber}
              published={handlesStats.retweetNumber}
              chat={handlesStats.replyNumber}
            />
          </AgencyHandleInfoIconsContainer>
        ) : null}

        <Box sx={{ marginTop: 2 }}>
          <GroupAvatars size={size || 'small'} usersList={usersList} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AgencyHandleCard;
