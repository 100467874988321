import { type FC, type MouseEvent, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { common } from '@mui/material/colors';

import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

import type { Handle, FunctionWithArg, EmptyFunction } from '../../../models';

import { Routes } from '../../../constants/routes';

import DialogDelete from '../../dialogs/delete/DialogDelete';
import AgencyComponent from '../card-agency-handle/Agency';
import ButtonIcon from '../buttons/ButtonIcon';
import ButtonDelete from '../buttons/ButtonDelete';

import { TweeterHandlesList, TweeterHandlesTitleContainer } from './styled-components';

interface TweeterHandlesListComponentProps {
  handles: Array<Handle>;
  isLoading: boolean;
  onDelete: FunctionWithArg<Handle>;
  onPinUnpin: FunctionWithArg<Handle>;
  onSwitchHandle?: EmptyFunction;
}

const TweeterHandlesListComponent: FC<TweeterHandlesListComponentProps> = props => {
  const { handles, onDelete, onPinUnpin, isLoading, onSwitchHandle } = props;
  const [deleteHandleDialog, setDeleteHandleDialog] = useState(false);
  const [handleToDelete, setHandleToDelete] = useState<Handle>();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (id: number): void => {
    navigate(Routes.handleById.route(id), {
      state: {
        from: location,
        routeName: Routes.handleById.routeName,
        handleId: id,
      },
      replace: true,
    });

    if (onSwitchHandle) {
      onSwitchHandle();
    }
  };

  const handleOpenDialogDeleteHandle = (
    event: MouseEvent<HTMLButtonElement>,
    handle: Handle,
  ): void => {
    event.stopPropagation();

    setHandleToDelete(handle);
    setDeleteHandleDialog(true);
  };

  const onCloseDialogDeleteHandle = (): void => {
    setDeleteHandleDialog(false);
  };

  const handleConfirmDialogDeleteHandle = (): void => {
    handleToDelete && onDelete(handleToDelete);
    setDeleteHandleDialog(false);
  };

  const handlePinUnpinWrapped = (event: MouseEvent<HTMLButtonElement>, handle: Handle) => {
    event.stopPropagation();

    onPinUnpin(handle);
  };

  return (
    <div>
      <TweeterHandlesTitleContainer>
        <Typography variant="h6" color="text.secondary">
          Handles
        </Typography>
      </TweeterHandlesTitleContainer>

      <TweeterHandlesList dense>
        {handles.map((handle, handleIndex) => (
          <div key={`${handleIndex}-${handle.id}`}>
            <Divider component="li" />

            <ListItem
              disablePadding
              secondaryAction={
                <>
                  <ButtonDelete
                    onClick={event => handleOpenDialogDeleteHandle(event, handle)}
                    sx={{ backgroundColor: { md: common.white } }}
                    disabled={isLoading}
                  />

                  <ButtonIcon
                    color={handle.pinned ? 'secondary' : 'primary'}
                    onClick={event => handlePinUnpinWrapped(event, handle)}
                    disabled={isLoading}
                  >
                    <PushPinOutlinedIcon />
                  </ButtonIcon>
                </>
              }
              onClick={() => handleClick(handle.id)}
            >
              <ListItemButton>
                <AgencyComponent
                  companyName={handle.tweetHandleName}
                  companyNickname={handle.username}
                  imageUrl={handle.profileImageUrl}
                  isStockTwits={handle.stockTwits}
                />
              </ListItemButton>
            </ListItem>

            {deleteHandleDialog ? (
              <DialogDelete
                title="Do you want to delete the handle?"
                onClose={onCloseDialogDeleteHandle}
                onConfirm={handleConfirmDialogDeleteHandle}
                isLoading={isLoading}
                error=""
              />
            ) : null}
          </div>
        ))}
      </TweeterHandlesList>
    </div>
  );
};

export default TweeterHandlesListComponent;
