import type { FC } from 'react';

import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

import { AvatarToPinContainer } from './styled-components';

const TwitterHandleAvatarToPin: FC = () => {
  return (
    <AvatarToPinContainer>
      <PushPinOutlinedIcon />
    </AvatarToPinContainer>
  );
};

export default TwitterHandleAvatarToPin;
