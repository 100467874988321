import type { FC } from 'react';

import type { IconButtonProps } from '@mui/material/IconButton';

import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

import ButtonIconComponent from './ButtonIcon';

const ButtonAdminComponent: FC<IconButtonProps> = props => {
  const { 'aria-label': ariaLabel, ...restProps } = props;

  return (
    <ButtonIconComponent {...restProps} aria-label="admin" color="warning">
      <MilitaryTechOutlinedIcon aria-label={ariaLabel || 'admin'} />
    </ButtonIconComponent>
  );
};

export default ButtonAdminComponent;
