import {
  type FC,
  useState,
  type MouseEventHandler,
  type ChangeEvent,
  type FocusEventHandler,
} from 'react';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FilledInput, { FilledInputProps } from '@mui/material/FilledInput';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import { HelperText, StyledFormHelperText } from './styled-components';

export interface PasswordInputProps extends FilledInputProps {
  label: string;
  name?: string;
  helperText?: string;
  success?: boolean;
}

interface State {
  password: string;
  showPassword: boolean;
}

let fieldId = 0;

const PasswordInputComponent: FC<PasswordInputProps> = props => {
  const { label, error, success, name, helperText, onChange, onBlur } = props;

  const [filled, setFilled] = useState(false);
  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });

    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    if (onBlur) {
      onBlur(event);
    }

    if (Boolean(event.target.value)) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  };

  const handleClickShowPassword = (): void => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
  };

  return (
    <FormControl
      variant="filled"
      size="small"
      error={error || false}
      classes={filled ? { root: 'text-filled' } : {}}
    >
      <InputLabel htmlFor={`passwordInput${fieldId++}`}>{label}</InputLabel>

      <FilledInput
        autoComplete="off"
        id={`passwordInput${fieldId}`}
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange('password')}
        onBlur={handleBlur}
        name={name || `password-input-${fieldId}`}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              color="info"
            >
              {success ? (
                <CheckCircleOutlineOutlinedIcon color="success" />
              ) : values.showPassword ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        }
      />

      <Fade in={error || false}>
        <StyledFormHelperText id={`passwordInputErrorText${fieldId}`} error={error || false}>
          <HelperText error={error || false}>
            {helperText || 'Oho! You missed this state.'}
          </HelperText>
        </StyledFormHelperText>
      </Fade>
    </FormControl>
  );
};

export default PasswordInputComponent;
