import { lighten, styled } from '@mui/material/styles';
import { Divider, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { common } from '@mui/material/colors';

import {
  FlexColumnContainer,
  FlexContainer,
} from '../../components/common/styled-components/containers';
import {
  alignItemsCenter,
  flexShrink0,
  justifyContentSpaceBetween,
  justifyContentCenter,
  flexGrow1,
} from '../../components/common/styled-components/primitives';

import { ProfilePageButtonsContainer } from '../styled-components';

export const HandleProfilePageButtonsContainer = styled(ProfilePageButtonsContainer)`
  gap: 16px 14px;
`;

export const HandleProfilePageTweetTypeContainer = styled(FlexColumnContainer)`
  ${alignItemsCenter};
  margin-right: 14px;
`;

export const HandleProfilePageIconContainer = styled(FlexColumnContainer)`
  ${alignItemsCenter};
  ${justifyContentCenter};
  ${flexShrink0};
  width: 36px;
  height: 36px;
  border-radius: 6px;
`;

export const HandleProfilePageDivider = styled(Divider)`
  margin-top: 6px;
  height: calc(100% - 28px);
  background-color: #ee93b1;
`;

export const HandleProfilePageInfoContainer = styled(FlexContainer)(({ theme }) => ({
  marginBottom: '20px',

  '.handle-text-container': {
    flexShrink: '1',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: 'calc(100vw - 32px)',
  },

  '&.handle-tweet': {
    '.handle-icon-container': {
      backgroundColor: lighten(`${theme.palette.tweet.main}`, 0.9),

      '.MuiSvgIcon-root': {
        color: theme.palette.tweet.main,
      },
    },
    '.handle-text-container': {
      color: theme.palette.tweet.main,
    },
  },

  '&.handle-retweet': {
    '.handle-icon-container': {
      backgroundColor: lighten(`${theme.palette.retweet.main}`, 0.9),

      '.MuiSvgIcon-root': {
        color: theme.palette.retweet.main,
      },
    },
    '.handle-text-container': {
      color: theme.palette.retweet.main,
    },
  },

  '&.handle-reply': {
    '.handle-icon-container': {
      backgroundColor: lighten(`${theme.palette.comment.main}`, 0.9),

      '.MuiSvgIcon-root': {
        color: theme.palette.comment.main,
      },
    },
    '.handle-text-container': {
      color: theme.palette.comment.main,
    },
  },
}));

export const HandleProfilePageTweetInfoContainer = styled(FlexColumnContainer)`
  min-width: 0;
`;

export const HandleProfilePageTweetActionsContainer = styled(FlexContainer)`
  ${flexShrink0};
`;

export const HandleProfilePageActionContainer = styled(FlexContainer)`
  ${alignItemsCenter};
  ${justifyContentSpaceBetween};

  .MuiIconButton-root {
    margin-left: 16px;
  }
`;

export const HandleProfilePageTextContainer = styled(Typography)`
  margin: 7px 0 10px;
  word-wrap: break-word;
`;

export const HandleProfilePageTweetContainer = styled(FlexColumnContainer)`
  ${flexGrow1};
  min-width: 0;
`;

export const PaperWithArrow = styled(Paper)`
  position: relative;
  margin-bottom: 10px;
  padding: 8px;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-width: 8px 8px 0 8px;
    border-color: ${common.white} transparent transparent transparent;
    border-style: solid;
    filter: drop-shadow(0px 1px 1px #a3a3a3);
  }
`;
