import { lighten, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';

export const TableDataGridContainer = styled(DataGrid)(({ theme }) => ({
  padding: '13px 16px 16px',
  border: 'none',
  color: theme.palette.text.primary,

  [`.${gridClasses.columnHeader}`]: {
    color: theme.palette.text.secondary,

    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },

  [`.${gridClasses.columnHeaders}`]: {
    border: 'none',
  },

  [`.${gridClasses.columnHeaderTitle}`]: {
    fontSize: '0.75em',
    fontWeight: '400',
  },

  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
    overflow: 'visible',
  },

  [`.${gridClasses.columnSeparator}`]: {
    display: 'none',
  },

  [`.${gridClasses.cell}`]: {
    borderBottom: 'none',

    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },

  '.MuiCheckbox-root': {
    marginLeft: '-16px',
  },

  '.MuiLink-root': {
    display: 'flex',
    color: theme.palette.text.primary,
  },

  [`.${gridClasses.row}`]: {
    borderRadius: '8px',

    '&.even': {
      backgroundColor: theme.palette.background.default,
    },

    '&:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.textLight.light3,
    },

    ' &.Mui-selected': {
      backgroundColor: theme.palette.textLight.light3,
      boxShadow: 'inset 0 0 0 1px #ee93b1',

      '&:hover, &.Mui-hovered': {
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
      },
    },
  },
}));

export const TableAvatarContainer = styled(Avatar)<{ square?: boolean }>`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  font-size: 0.75em;
  text-transform: uppercase;
  border-radius: ${({ square }) => (square ? '4px' : '50%')};
`;
