import type { FC } from 'react';

import Grid from '@mui/material/Grid';

import { IconsHandleComponentProps } from './IconsHandle';
import {
  IconHandleContainer,
  MarkunreadMailboxIconColorContainer,
  PublishedWithChangesIconColorContainer,
  ChatAddIconColorContainer,
} from './styled-components';

const IconsHandleWithColors: FC<IconsHandleComponentProps> = props => {
  const { unread, published, chat } = props;

  return (
    <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <IconHandleContainer>
          <MarkunreadMailboxIconColorContainer />
          {unread}
        </IconHandleContainer>
      </Grid>
      <Grid item>
        <IconHandleContainer>
          <PublishedWithChangesIconColorContainer />
          {published}
        </IconHandleContainer>
      </Grid>
      <Grid item>
        <IconHandleContainer>
          <ChatAddIconColorContainer />
          {chat}
        </IconHandleContainer>
      </Grid>
    </Grid>
  );
};

export default IconsHandleWithColors;
