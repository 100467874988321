import { styled } from '@mui/material/styles';
import common from '@mui/material/colors/common';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { flexGrow1 } from '../../components/common/styled-components/primitives';

export const BadgeAdminIconContainer = styled('div')`
  display: inline-flex;
  padding: 4px;
  color: ${common.white};
  border-radius: 4px;
  background: #ffaa21;

  .MuiSvgIcon-root {
    font-size: 1em;
  }
`;

export const ActionStackContainer = styled(Stack)`
  flex-wrap: wrap;
  width: 100%;

  .MuiTypography-root {
    ${flexGrow1}
  }

  @media (max-width: 900px) {
    .MuiTabs-root {
      width: 100%;
      margin: 14px 0 24px;

      .MuiTabs-flexContainer {
        width: auto;
      }
    }
  }
`;

export const BadgeMembership = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  padding: '2px 4px',
  fontSize: '12px',
  borderRadius: '4px',
  background: theme.palette.textLight.light1,
}));

export const BadgeMembershipPrimary = styled(BadgeMembership)(({ theme }) => ({
  color: common.white,
  background: theme.palette.primary.main,
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '4px 5px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.success.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    width: 8,
    height: 8,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    backgroundColor: theme.palette.text.disabled,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
