import { useState, type FC, type ChangeEventHandler, type SyntheticEvent } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';

import type { FunctionWithArg, ExpandedPanel, FunctionWithTwoArgs } from '../../../models';

import theme from '../../../theme';
import TypeFilterCheckList, { checkList } from './TypeFilterCheckList';
import { AccordionStyled } from './styled-components';

interface TypeFilterProps {
  onSetType: FunctionWithArg<Array<string>>;
  expandedPanel: ExpandedPanel;
  onExpandPanel: FunctionWithArg<ExpandedPanel, FunctionWithTwoArgs<SyntheticEvent, boolean>>;
}

const TypeFilter: FC<TypeFilterProps> = props => {
  const { onSetType, expandedPanel, onExpandPanel } = props;

  const smallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const [stateForm, setStateForm] = useState(checkList);

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const newStateForm = stateForm.map(item => {
      if (item.title === target.name) {
        return { ...item, checked: target.checked };
      }

      return item;
    });

    setStateForm(newStateForm);
    onSetType([stateForm[0].title]);
  };

  return (
    <>
      {smallScreens ? (
        <AccordionStyled expanded={expandedPanel === 'panel3'} onChange={onExpandPanel('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="date-filter"
            id="date-filter"
          >
            <Groups3OutlinedIcon sx={{ marginRight: 1 }} />
            <Typography>Handles type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ margin: '8px 16px 16px 27px' }}>
              <TypeFilterCheckList stateForm={stateForm} handleChange={handleChange} />
            </Box>
          </AccordionDetails>
        </AccordionStyled>
      ) : (
        <Box sx={{ margin: '0 0 10px 11px' }}>
          <TypeFilterCheckList stateForm={stateForm} handleChange={handleChange} />
        </Box>
      )}
    </>
  );
};

export default TypeFilter;
