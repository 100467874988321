import { type FC, type SyntheticEvent, useState } from 'react';

import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';

import useTabProps from '../../hooks/use-tab-props';
import { useAuth } from '../../context/auth.context';
import useEffectAuthNavigateToDashboard from '../../context/use-effect-auth-navigate-to-dashboard';

import Logo from '../../components/common/logo/Logo';
import TabPanel from '../../components/common/tabs/TabPanelComponent';

import SignupFormComponent from './SignupForm';

import {
  PageContainer,
  PageMainColumn,
  PageSecondaryColumn,
  PageContentWrapper,
  PageLogoWrapper,
  HeadlineContainer,
  CustomTabs,
} from '../styled-components';

const SignupPage: FC = () => {
  const [value, setValue] = useState(0);
  const tabProps = useTabProps(2);
  const auth = useAuth();

  useEffectAuthNavigateToDashboard(auth.authInfo.jwt);

  const handleTabsChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    auth.resetError('signup');
  };

  if (!auth.authInfo.jwt) {
    return (
      <PageContainer>
        <PageMainColumn>
          <PageContentWrapper>
            <PageLogoWrapper>
              <Logo />
            </PageLogoWrapper>

            <HeadlineContainer>
              <Typography variant="h3" gutterBottom>
                Create your new account
              </Typography>
            </HeadlineContainer>

            <CustomTabs value={value} onChange={handleTabsChange} aria-label="tabs">
              <Tab label="Company" {...tabProps[0]} />
              <Tab label="Personal" {...tabProps[1]} />
            </CustomTabs>

            <TabPanel value={value} index={0}>
              <SignupFormComponent accountType="COMPANY" />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <SignupFormComponent accountType="USER" optional />
            </TabPanel>
          </PageContentWrapper>
        </PageMainColumn>

        <PageSecondaryColumn />
      </PageContainer>
    );
  }

  return null;
};

export default SignupPage;
