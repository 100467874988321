import { type FC, type SyntheticEvent, useMemo, useState } from 'react';

import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { useMutationDeleteHandlesFromTable } from '../../queries/tweets';
import useTabProps from '../../hooks/use-tab-props';
import useHandlesTableData from '../../hooks/use-handles-table-data';

import type { Handle, AccountType } from '../../models';

import { isToday } from '../../utils/date';
import LabelCount from '../../components/common/label-count/LabelCount';
import ButtonDelete from '../../components/common/buttons/ButtonDelete';
import InProgress from '../../components/common/in-progress/InProgress';
import TabPanel from '../../components/common/tabs/TabPanelComponent';
import ErrorMessage from '../../components/common/error-message/ErrorMessage';
import DialogDelete from '../../components/dialogs/delete/DialogDelete';

import HandlesTable from './HandlesTable';
import { columns } from './columns-handle';

import {
  HomePageBasicContainer,
  PaperContainer,
  TabsContainer,
  TitleContainer,
} from '../styled-components';

interface HandlesPageProps {
  accountType: AccountType;
  handles: Array<Handle>;
  entityId: string;
  jwt: string;
}

const HandlesPage: FC<HandlesPageProps> = ({ accountType, handles, entityId, jwt }) => {
  const [value, setValue] = useState(0);
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
  const tabProps = useTabProps(2);
  const deleteHandles = useMutationDeleteHandlesFromTable(entityId, accountType, jwt);
  const newHandles = useMemo(
    () => handles.filter(handle => isToday(handle.createdDate)),
    [handles],
  );

  const rowsHandles = useHandlesTableData(handles);
  const rowsNewHandles = useHandlesTableData(newHandles);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelectedRows([]);
  };

  const handleConfirmDialogDeleteUsers = (): void => {
    deleteHandles.mutate(
      {
        selectedRows,
        handles,
      },
      {
        onSuccess: () => {
          setSelectedRows([]);
          setDeleteUsersDialog(false);
        },
      },
    );
  };

  const onOpenDialogDeleteUsers = (): void => {
    setDeleteUsersDialog(true);
  };

  const onCloseDialogDeleteUsers = (): void => {
    setDeleteUsersDialog(false);
  };

  if (deleteHandles.isLoading) {
    return <InProgress isOpen={deleteHandles.isLoading} />;
  }

  return (
    <HomePageBasicContainer>
      <Container maxWidth="lg">
        <PaperContainer>
          <TitleContainer>
            <Typography variant="h4">
              {accountType === 'COMPANY'
                ? 'Handles monitored across the company'
                : 'Handles monitored by person'}
            </Typography>
            {selectedRows.length ? <ButtonDelete onClick={onOpenDialogDeleteUsers} /> : null}
          </TitleContainer>

          {deleteUsersDialog ? (
            <DialogDelete
              title="Do you want to delete the handle(s)?"
              onClose={onCloseDialogDeleteUsers}
              onConfirm={handleConfirmDialogDeleteUsers}
              isLoading={deleteHandles.isLoading}
              error={deleteHandles.error?.message || ''}
            />
          ) : null}

          <ErrorMessage>{deleteHandles.error?.message || ''}</ErrorMessage>

          {handles.length ? (
            <Box>
              <TabsContainer value={value} onChange={handleChange} aria-label="handles tabs">
                <Tab label="All" {...tabProps[0]} />

                {newHandles.length > 0 ? (
                  <Tab
                    label="New"
                    icon={<LabelCount amount={newHandles.length} />}
                    iconPosition="end"
                    {...tabProps[1]}
                  />
                ) : null}
              </TabsContainer>

              <TabPanel value={value} index={0}>
                <HandlesTable
                  rows={rowsHandles}
                  columns={columns}
                  setSelectedRowsIds={setSelectedRows}
                />
              </TabPanel>

              {newHandles.length > 0 ? (
                <TabPanel value={value} index={1}>
                  <HandlesTable
                    rows={rowsNewHandles}
                    columns={columns}
                    setSelectedRowsIds={setSelectedRows}
                  />
                </TabPanel>
              ) : null}
            </Box>
          ) : (
            <Typography p={3}>Is empty</Typography>
          )}
        </PaperContainer>
      </Container>
    </HomePageBasicContainer>
  );
};

export default HandlesPage;
