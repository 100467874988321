import type { FC } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

/*
  @TODO Implement images for user in beta
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'; */

import format from 'date-fns/format';

import Settings from '../../../constants/settings';
import { Routes } from '../../../constants/routes';

import type { SearchItem } from '../../../models';
import {
  StyledCard,
  UsernameDateTimeContainer,
  UsernameDateTimeSeparator,
  StyledCardActions,
  StyledCardContent,
} from './styled-components';

const HandlesOption: FC<{ data: SearchItem }> = ({ data }) => {
  const { handleName, timestamp, username, profileImageUrl, handleId } = data;

  const date = format(new Date(timestamp), Settings.DATE_SHORT);
  const time = format(new Date(timestamp), Settings.TIME_AM_PM);

  return (
    <Link
      to={Routes.handleById.route(handleId)}
      underline="none"
      component={RouterLink}
      state={{
        routeName: Routes.handleById.routeName,
        handleId: handleId,
      }}
    >
      <StyledCard>
        <CardMedia
          component="img"
          sx={{ width: 40, height: 40, borderRadius: '50%' }}
          image={profileImageUrl}
          alt={`Image for ${handleName}`}
        />

        <StyledCardContent>
          <Typography variant="body2" color="text.primary">
            {handleName}
          </Typography>

          <UsernameDateTimeContainer>
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              @{username}
            </Typography>

            <UsernameDateTimeSeparator />

            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              {date}
            </Typography>

            <UsernameDateTimeSeparator />

            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              {time}
            </Typography>
          </UsernameDateTimeContainer>
        </StyledCardContent>

        <StyledCardActions disableSpacing>
          {/*
        @TODO Implement images for user in beta
        <IconButton>
          <LocationSearchingIcon aria-label="Go to profile" />
        </IconButton>

        {isAdded ? (
          <IconButton color="error">
            <DeleteOutlineOutlinedIcon aria-label="Remove from the handles" />
          </IconButton>
        ) : (
          <IconButton color="primary">
            <PersonAddAltIcon aria-label="Add to the handles" />
          </IconButton>
        )} */}
        </StyledCardActions>
      </StyledCard>
    </Link>
  );
};

export default HandlesOption;
