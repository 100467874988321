import { forwardRef } from 'react';

import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => {
  return (
    <MUICheckbox
      {...props}
      checkedIcon={<CheckBoxOutlinedIcon />}
      indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
      ref={ref}
    />
  );
});

export default Checkbox;
