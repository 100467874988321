import type { AxiosError } from 'axios';

import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import type { AccountType, CompanyInfo, User } from '../models';

import { getEntityInfo } from '../api/entity';

export const useQueryEntityInfoById = (
  id: string,
  accountType: AccountType,
  jwt: string,
): UseQueryResult<CompanyInfo | User, AxiosError> => {
  return useQuery(['entity-info', id, accountType], () => getEntityInfo(id, accountType, jwt), {
    enabled: Boolean(id),
  });
};
