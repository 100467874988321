import type { FC, ReactElement } from 'react';

import Container from '@mui/material/Container';
import Stack from '@mui/system/Stack';
import type { PopperUnstyledProps } from '@mui/base/PopperUnstyled';

import { StyledPopperComponent } from './styled-components';

interface PopperComponentProps extends PopperUnstyledProps {
  open: boolean;
  anchorEl?: any;
  container?: any;
  idEl?: string;
  disablePortal?: boolean;
  dateTimeFilterElement?: ReactElement;
  typeFilterElement?: ReactElement;
}

const PopperComponent: FC<PopperComponentProps> = props => {
  const {
    disablePortal: _d,
    anchorEl,
    container,
    open,
    dateTimeFilterElement,
    typeFilterElement,
    children,
    ...other
  } = props;

  if (open) {
    return (
      <StyledPopperComponent open={open} anchorEl={anchorEl} container={container} {...other}>
        <Container maxWidth="lg">
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 0, md: 1 }}
            sx={{
              padding: '16px 0',
              '.MuiAutocomplete-noOptions': {
                display: 'none',
              },
            }}
            margin={{ xs: '0 -16px', md: 0 }}
          >
            {dateTimeFilterElement}
            <div>
              {typeFilterElement}

              {/* Fix for the wrong type of children */}
              <>{children}</>
            </div>
          </Stack>
        </Container>
      </StyledPopperComponent>
    );
  }

  return null;
};

export default PopperComponent;
