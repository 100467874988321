import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { common } from '@mui/material/colors';

import { displayFlex, flexColumn } from '../styled-components/primitives';

export const TwitterHandleCardContainer = styled(Card)`
  ${displayFlex}
  ${flexColumn}

  &:hover {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  }

  .MuiCardContent-root {
    text-align: center;
  }
`;

export const TwitterHandleInfoIconsContainer = styled('div')`
  padding: 10px 10px 0;
`;

export const TwitterHandleToPinCardContainer = styled(TwitterHandleCardContainer)`
  height: 100%;
  align-items: center;

  .MuiButtonBase-root {
    min-height: 100%;
  }
`;

export const ToPinContainer = styled(Avatar)(
  ({ theme }) => `
    margin: 0 auto;
    width: 36px;
    height: 36px;
    color: ${theme.palette.primary.main};
    background: ${theme.palette.textLight.light2};
    svg {
      font-size: 1em;
    }

    .MuiButtonBase-root:hover & {
      color: ${common.white};
      background-color: ${theme.palette.primary.main};
      transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  `,
);
