import type { FC } from 'react';

import Typography from '@mui/material/Typography';

import type { JSXElement } from '../../models';

import ErrorIconComponent from './ErrorIcon';

interface ErrorPageProps {
  backToElement: JSXElement;
  infoMessage?: string;
}

const ErrorPage: FC<ErrorPageProps> = ({ backToElement, infoMessage }) => {
  return (
    <>
      <ErrorIconComponent viewBox="0 0 91 121" sx={{ width: '91px', height: '121px' }} />

      <Typography variant="h3" sx={{ mt: 5, mb: 4 }}>
        {infoMessage || "Page can't be found"}
      </Typography>

      {backToElement}
    </>
  );
};

export default ErrorPage;
