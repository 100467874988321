import { type ChangeEventHandler, type FC, useMemo, useState, type FormEventHandler } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';

import type { EmptyFunction, JSXElement, FooterElement, FunctionWithArg } from '../../../models';

import Button from '../../common/buttons/Button';

import DialogBase from '../base/DialogBase';

import { CheckboxCustom, DialogFormGroupContainer } from '../base/styled-components';

export interface ExportHandlesFields {
  tweetId: boolean;
  authorId: boolean;
  date: boolean;
  time: boolean;
  total: boolean;
}

interface DialogExportHandlesInfoProps {
  onClose: EmptyFunction;
  onConfirm: FunctionWithArg<ExportHandlesFields>;
}

const header = (): JSXElement => <>Export handles information</>;

const footer: FooterElement = options => {
  const { onConfirm, isDisabled } = options;

  return (
    <Button fullWidth size="large" onClick={onConfirm} disabled={isDisabled}>
      Export PDF now
    </Button>
  );
};

const DialogExportHandlesInfo: FC<DialogExportHandlesInfoProps> = props => {
  const { onClose, onConfirm } = props;

  const [stateFrom, setStateFrom] = useState<ExportHandlesFields>({
    tweetId: false,
    authorId: false,
    date: false,
    time: false,
    total: false,
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setStateFrom({
      ...stateFrom,
      [target.name]: target.checked,
    });
  };

  const isDisabled = useMemo(() => {
    const fields = Object.keys(stateFrom) as Array<keyof ExportHandlesFields>;

    return fields.every(field => !Boolean(stateFrom[field]));
  }, [stateFrom]);

  const handleConfirm = () => {
    onConfirm(stateFrom);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    onConfirm(stateFrom);
  };

  return (
    <DialogBase
      header={header()}
      footer={footer({
        onConfirm: handleConfirm,
        isDisabled,
      })}
      onClose={onClose}
    >
      <form method="POST" noValidate onSubmit={handleSubmit}>
        <DialogFormGroupContainer>
          <FormControlLabel
            control={
              <CheckboxCustom checked={stateFrom.tweetId} onChange={handleChange} name="tweetId" />
            }
            label="Tweet ID"
          />

          <FormControlLabel
            control={
              <CheckboxCustom
                checked={stateFrom.authorId}
                onChange={handleChange}
                name="authorId"
              />
            }
            label="Author ID"
          />

          <FormControlLabel
            control={
              <CheckboxCustom checked={stateFrom.date} onChange={handleChange} name="date" />
            }
            label="Date"
          />

          <FormControlLabel
            control={
              <CheckboxCustom checked={stateFrom.time} onChange={handleChange} name="time" />
            }
            label="Time"
          />

          <FormControlLabel
            control={
              <CheckboxCustom checked={stateFrom.total} onChange={handleChange} name="total" />
            }
            label="Total tweet"
          />
        </DialogFormGroupContainer>
      </form>
    </DialogBase>
  );
};

export default DialogExportHandlesInfo;
