import type { FC } from 'react';

import Typography from '@mui/material/Typography';
import type { GridRenderCellParams } from '@mui/x-data-grid';

import { useAvatarLettersCompany, useAvatarLettersUser } from '../../hooks/use-avatar-letters';

import { TableAvatarContainer } from '../handles/styled-components';

interface RenderAvatarProps {
  avatarLetters: string;
  entityName: string;
  avatarSrc: string;
}

const RenderAvatar: FC<RenderAvatarProps> = props => {
  const { avatarLetters, entityName, avatarSrc } = props;

  return (
    <>
      <TableAvatarContainer src={avatarSrc}>{avatarLetters}</TableAvatarContainer>

      <Typography noWrap>@{entityName}</Typography>
    </>
  );
};

const RenderCompanyAvatar: FC<Omit<RenderAvatarProps, 'avatarLetters'>> = ({
  entityName,
  avatarSrc,
}) => {
  const avatarLetters = useAvatarLettersCompany(entityName);

  return (
    <RenderAvatar entityName={entityName} avatarSrc={avatarSrc} avatarLetters={avatarLetters} />
  );
};

const RenderUserAvatar: FC<Omit<RenderAvatarProps, 'avatarLetters'>> = ({
  entityName,
  avatarSrc,
}) => {
  const avatarLetters = useAvatarLettersUser(entityName);

  return (
    <RenderAvatar entityName={entityName} avatarSrc={avatarSrc} avatarLetters={avatarLetters} />
  );
};

export const RenderCellCompanyAvatar: FC<GridRenderCellParams> = ({ value }) => {
  return <RenderCompanyAvatar entityName={value.name} avatarSrc={value.avatar || ''} />;
};

export const RenderCellUserAvatar: FC<GridRenderCellParams> = ({ value }) => {
  return <RenderUserAvatar entityName={value.name} avatarSrc={value.avatar || ''} />;
};
