import { Reducer, useReducer } from 'react';

import {
  StateField,
  ActionField,
  ActionBlur,
  ActionChange,
  DispatchEventOptions,
} from '../signup/SignupPage.state';

interface InitialState {
  username: StateField;
  password: StateField;
}

const initialState: InitialState = {
  username: {
    value: '',
    error: false,
    success: false,
  },
  password: {
    value: '',
    error: false,
    success: false,
  },
};

const reducer: Reducer<
  InitialState,
  ActionChange<ActionField<InitialState>> | ActionBlur<ActionField<InitialState>>
> = (state, action) => {
  switch (action.type) {
    case 'blur': {
      const { field } = action;

      return {
        ...state,
        [field.name]: {
          ...state[field.name],
          error: !field.value,
          success: !!field.value,
        },
      };
    }

    case 'change': {
      const { field } = action;

      const newState = {
        ...state,
        [field.name]: {
          ...state[field.name],
          value: field.value,
        },
      };

      return newState;
    }

    default:
      throw new Error();
  }
};

export type InitialStateKeys = keyof InitialState;

const useSigninPageLoginReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchEvent = (options: DispatchEventOptions): void => {
    dispatch({
      type: options.type,
      field: {
        name: options.fieldName as InitialStateKeys,
        value: options.fieldValue,
      },
    });
  };

  return {
    state,
    dispatch,
    dispatchEvent,
  };
};

export default useSigninPageLoginReducer;
