import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Drawer from '@mui/material/Drawer';
import { common } from '@mui/material/colors';

import ButtonIconComponent from '../components/common/buttons/ButtonIcon';

import {
  FlexContainer,
  FlexColumnContainer,
} from '../components/common/styled-components/containers';
import {
  justifyContentCenter,
  justifyContentSpaceBetween,
  flexGrow1,
  alignItemsCenter,
  flexColumn,
  flexRow,
  flexShrink0,
} from '../components/common/styled-components/primitives';

import { TOP_BAR_HEIGHT_SIZES } from '../constants/top-bar-sizes.const';

import imageSignUp from '../images/signup-bg.jpg';
import imageSignIn from '../images/signin-bg.jpg';

export const PageContainer = styled(FlexContainer)`
  height: 100vh;
  width: 100vw;
`;

export const PageMainColumn = styled(FlexContainer)`
  ${justifyContentCenter}
  ${flexGrow1}
  padding: 24px;
  text-align: center;
  margin-top: 75px;

  @media (min-width: 900px) {
    text-align: left;
    margin-top: 142px;
  }
`;

export const PageSecondaryColumn = styled('div')`
  display: none;
  width: 50%;
  background-image: url(${imageSignUp});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 900px) {
    display: block;
  }
`;

export const PageSecondaryColumnVariant2 = styled(PageSecondaryColumn)`
  background-image: url(${imageSignIn});
`;

export const PageContentWrapper = styled(FlexColumnContainer)`
  ${flexGrow1}

  @media (min-width: 900px) {
    max-width: 370px;
  }
`;

export const PageLogoWrapper = styled('div')`
  width: 177px;
  margin: 0 auto 75px;

  svg {
    vertical-align: top;
  }

  @media (min-width: 900px) {
    margin: 0 0 57px;
    width: 217px;
  }
`;

export const LoginButtonContainer = styled(Button)`
  .MuiButton-endIcon {
    visibility: hidden;
    opacity: 0;
    width: 0;
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) ';
  }

  &:hover .MuiButton-endIcon {
    width: auto;
    visibility: visible;
    opacity: 1;
  }
`;

export const FormContainer = styled(FlexColumnContainer)`
  margin-bottom: 16px;

  .MuiFormControl-root {
    margin-bottom: 4px;
  }

  @media (min-width: 900px) {
    margin-bottom: 24px;

    .MuiFormControl-root {
      margin-bottom: 12px;
    }
  }
`;

export const HeadlineContainer = styled('div')`
  margin-bottom: 13px;

  @media (min-width: 900px) {
    margin-bottom: 20px;
  }
`;

export const HomePageBasicContainer = styled(Box)`
  padding: 16px 0;

  @media (min-width: 900px) {
    padding: 34px 0 48px;
  }
`;

export const PageErrorContainer = styled(FlexContainer)`
  ${flexColumn}
  ${flexGrow1}
  ${alignItemsCenter}
  ${justifyContentCenter}
  height: 80vh;
`;

export const PageErrorWithToolbalContainer = styled(PageErrorContainer)`
  height: calc(100vh - ${TOP_BAR_HEIGHT_SIZES.MOB_SIZE});

  @media (min-width: 900px) {
    height: calc(100vh - ${TOP_BAR_HEIGHT_SIZES.WEB_SIZE});
  }
`;

export const PaperContainer = styled(Paper)`
  border-radius: 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
`;

export const TitleContainer = styled(FlexContainer)`
  ${alignItemsCenter}
  ${justifyContentSpaceBetween}
  padding: 16px 22px 0 24px;
  min-height: 52px;

  .MuiTypography-h4 {
    line-height: 1.5;
  }
`;

export const TabsContainer = styled(Tabs)`
  padding-left: 12px;
  box-shadow: inset 0 -2px 0 0 #f5f6f7;

  .MuiTab-root {
    margin: 0 12px;
    padding-left: 0;
    padding-right: 0;
    min-width: 0;
  }
`;

export const StatsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: '16px 0',
  borderTop: `1px solid ${theme.palette.textLight.light2}`,
  borderBottom: `1px solid ${theme.palette.textLight.light2}`,

  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(3),
    padding: '24px 0',
  },

  '.MuiGrid-root': {
    maxWidth: '220px',
  },
}));

export const HandlesGrid = styled(Grid)`
  ${flexShrink0}
  margin-bottom: 34px;
  width: 290px;
`;

export const ProfilePageWrapper = styled(FlexColumnContainer)`
  ${flexColumn};

  @media (min-width: 900px) {
    ${flexRow};
  }
`;

export const ProfilePageMainContainer = styled('div')`
  ${flexGrow1};
  padding: 20px 0;

  @media (min-width: 900px) {
    padding: 0 30px;
    max-width: 600px;
  }

  @media (min-width: 1024px) {
    max-width: 390px;
  }

  @media (min-width: 1100px) {
    max-width: 450px;
  }

  @media (min-width: 1250px) {
    max-width: 500px;
  }

  @media (min-width: 1400px) {
    max-width: 600px;
  }
`;

export const ProfilePagePaperContainer = styled(Paper)(({ theme }) => ({
  margin: '-16px -16px 0',
  padding: '16px 24px',
  boxShadow: 'none',
  position: 'static',
  borderTop: `1px solid ${theme.palette.textLight.light1}`,
  borderRadius: '0',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '-24px',
    marginRight: '-24px',
  },

  [theme.breakpoints.up('md')]: {
    flexShrink0,
    position: 'sticky',
    top: '146px',
    margin: '0',
    padding: '0',
    width: '270px',
    height: 'fit-content',
    borderRadius: '16px',
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.25)',
  },

  [theme.breakpoints.up('lg')]: {
    width: '370px',
  },
}));

export const ProfilePageBoxContainer = styled(Box)`
  @media (min-width: 900px) {
    padding: 30px 24px;
  }
`;

export const ProfilePageUserContainer = styled(FlexContainer)`
  ${alignItemsCenter};
  position: relative;
  margin-bottom: 10px;

  @media (min-width: 900px) {
    ${flexColumn};
  }
`;

export const ProfilePageUserInfoContainer = styled('div')`
  margin-left: 16px;
  width: 100%;

  @media (min-width: 900px) {
    margin: 10px 0 0;
    text-align: center;
  }
`;

export const ProfilePageDescriptionContainer = styled(Typography)`
  margin-bottom: 18px;

  @media (min-width: 900px) {
    width: 240px;
    margin: 0 auto 18px;
    text-align: center;
    word-break: break-word;
  }
`;

export const ProfilePageButtonsContainer = styled(FlexContainer)`
  gap: 8px 14px;
  margin-bottom: 20px;

  .MuiButtonBase-root.MuiButton-sizeMedium {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  @media (min-width: 900px) {
    ${flexColumn};

    .MuiButtonBase-root.MuiButton-sizeMedium {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

export const ProfilePageButtonCog = styled(ButtonIconComponent)`
  margin-top: -34px;
  padding: 4px;
  font-size: 1em;
  border-radius: 4px;

  @media (min-width: 900px) {
    position: absolute;
    top: 20px;
    right: -8px;
    width: 24px;
    height: 24px;
  }
`;

export const ProfileDateTimeSeparator = styled('div')(({ theme }) => ({
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  margin: '0 6px',
}));

export const ProfileDateTimeContainer = styled(FlexContainer)(({ theme }) => ({
  alignItems: 'center',

  '.handle-tweet': {
    color: theme.palette.tweet.main,
  },

  '.handle-retweet': {
    color: theme.palette.retweet.main,
  },

  '.handle-reply': {
    color: theme.palette.comment.main,
  },
}));

export const ProfilePageMediaContainer = styled(Card)`
  border-radius: 8px;
`;

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: '42px',
  minHeight: '40px',

  '.MuiTabs-flexContainer': {
    width: 'fit-content',
    background: common.white,
    borderRadius: '8px',
  },

  '.MuiTab-root': {
    minHeight: '40px',
    minWidth: '65px',
    padding: '8px 24px',

    '&.Mui-selected': {
      color: common.white,
      background: theme.palette.primary.main,
      borderRadius: '8px',
    },
  },

  '.MuiTabs-indicator': {
    display: 'none',
  },
}));

export const CustomTabsHighlighted = styled(CustomTabs)(({ theme }) => ({
  '.MuiTabs-flexContainer': {
    background: theme.palette.textLight.light3,
  },
}));

export const CustomTabsSecondary = styled(Tabs)(({ theme }) => ({
  minHeight: '36px',

  '.MuiTabs-flexContainer': {
    width: 'fit-content',
    background: '#f5f6f7',
    borderRadius: '8px',
  },

  '.MuiTab-root': {
    minHeight: '36px',
    minWidth: '90px',
    padding: '8px 10px',

    '&.Mui-selected': {
      color: common.white,
      background: theme.palette.text.primary,
      borderRadius: '8px',
    },
  },

  '.MuiTabs-indicator': {
    display: 'none',
  },
}));

export const TwitterHandlesSidebar = styled(Drawer)(({ theme }) => ({
  flex: '0 0 auto',
  width: '320px',

  '@media (max-width: 1023px)': {
    display: 'none',
  },

  [theme.breakpoints.up('xl')]: {
    width: '160px',
  },

  '& .MuiDrawer-paper': {
    top: '116px',
    width: '341px',
    padding: '0 24px 24px',
    height: 'calc(100% - 116px)',
    border: 'none',

    [theme.breakpoints.down('lg')]: {
      width: '320px',
    },
  },
}));
