import type { FC } from 'react';

import type { GridRenderCellParams } from '@mui/x-data-grid';

import BadgeAdminIcon from './BadgeAdminIcon';

const RenderAdminIcon: FC<GridRenderCellParams> = ({ value }) => {
  if (value) {
    return <BadgeAdminIcon />;
  }

  // @mui/x-data-grid converts `null` to some unacceptable value and render `value` which is `false` in this case as a string
  return <></>;
};

export default RenderAdminIcon;
