import type { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import type { IconButtonProps } from '@mui/material/IconButton';

import ButtonIconComponent from './ButtonIcon';

const ButtonCloseComponent: FC<IconButtonProps> = props => {
  const { 'aria-label': ariaLabel, size, ...restProps } = props;

  return (
    <ButtonIconComponent {...restProps} aria-label={ariaLabel || 'close'} size={size}>
      <CloseIcon aria-label={ariaLabel || 'close'} fontSize={size || 'medium'} />
    </ButtonIconComponent>
  );
};

export default ButtonCloseComponent;
