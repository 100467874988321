import {
  type ReactElement,
  type HTMLAttributes,
  forwardRef,
  createContext,
  useContext,
} from 'react';

import { VariableSizeList } from 'react-window';

import createRenderRow from './render-row';
import useResetCache from './use-reset-cache';
import useItemData from './use-item-data';

const LISTBOX_PADDING = 8; // px

const renderRow = createRenderRow(LISTBOX_PADDING);

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...other } = props;

  const itemData = useItemData(children as Array<ReactElement | string | number>);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }

    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default ListboxComponent;
