import { type FC, useState, useCallback, type ChangeEventHandler } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import type { FunctionWithArg } from '../../../models';

import { FOLDER_COLORS } from '../../../constants/folder-colors.const';

import { StyledColorsFormControl, StyledRadio } from './styled-components';

export interface RadioGroupColorsProps {
  label: string;
  onSelectOption?: FunctionWithArg<string>;
}

const RadioGroupColors: FC<RadioGroupColorsProps> = props => {
  const { label, onSelectOption } = props;
  const [selectedValue, setSelectedValue] = useState(FOLDER_COLORS[0]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSelectedValue(event.target.value);
      onSelectOption && onSelectOption(event.target.value);
    },
    [onSelectOption],
  );

  const controlProps = useCallback(
    (item: string) => {
      return {
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button',
        inputProps: { 'aria-label': item },
      };
    },
    [handleChange, selectedValue],
  );

  return (
    <StyledColorsFormControl>
      <FormLabel id="colors-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="colors-radio-buttons-group-label"
        defaultValue={FOLDER_COLORS[0]}
        name="radio-buttons-group"
      >
        {FOLDER_COLORS.map(option => (
          <FormControlLabel
            key={option}
            value={option}
            control={
              <StyledRadio
                {...controlProps(option)}
                checkedIcon={<CheckCircleOutlineOutlinedIcon />}
                sx={{
                  color: option,
                  '.MuiSvgIcon-root': {
                    backgroundColor: option,
                  },
                }}
              />
            }
            label=""
          />
        ))}
      </RadioGroup>
    </StyledColorsFormControl>
  );
};

export default RadioGroupColors;
