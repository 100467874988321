import type { FC } from 'react';

import type { GridRenderCellParams } from '@mui/x-data-grid';

import { BadgeMembership, BadgeMembershipPrimary } from './styled-components';

const membershipType = {
  BASIC: 'Basic',
  PRO: 'Pro',
};

type RenderMembershipProps = GridRenderCellParams<keyof typeof membershipType>;

const RenderMembership: FC<RenderMembershipProps> = ({ value }) => {
  if (value === 'PRO') {
    return <BadgeMembershipPrimary>{membershipType[value]}</BadgeMembershipPrimary>;
  }

  if (value === 'BASIC') {
    return <BadgeMembership>{membershipType[value]}</BadgeMembership>;
  }

  return null;
};

export default RenderMembership;
