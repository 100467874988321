import { type FC, type ChangeEventHandler, type ReactNode, useRef, useState, useMemo } from 'react';

import type { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import type { FunctionWithArg, StockTwitsInfo } from '../../../models';

import { useQueryStockTwitsInfoSearch } from '../../../queries/stocktwits';
import { useAuth } from '../../../context/auth.context';

import { StyledAutocomplete } from './styled-components';
import ListboxComponent from './ListboxComponent';

const SEARCH_DELAY = 1000;
const DEFAULT_SEARCH_QUERY = '';

interface SearchTickerIdProps {
  onChangeTickerId: FunctionWithArg<string>;
}

const SearchTickerId: FC<SearchTickerIdProps> = ({ onChangeTickerId }) => {
  const [inputValue, setInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState(DEFAULT_SEARCH_QUERY);

  const { authInfo } = useAuth();
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const { data: options, isLoading } = useQueryStockTwitsInfoSearch({
    jwt: authInfo.jwt,
    keyword: searchQuery,
  });

  const inputDisabled = useMemo(() => {
    return Boolean(searchQuery) && isLoading;
  }, [isLoading, searchQuery]);

  const handleChangeInput: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({
    target,
  }) => {
    setInputValue(target.value);

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      setSearchQuery(target.value);
    }, SEARCH_DELAY);
  };

  const handleChange = (
    _event: any,
    newValue: StockTwitsInfo | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (newValue) {
      onChangeTickerId(newValue.tickerId);
    }

    if (reason === 'selectOption' && newValue) {
      onChangeTickerId(newValue.tickerId);
      setInputValue(newValue.tickerId);
    }

    if (reason === 'clear') {
      onChangeTickerId('');
    }
  };

  return (
    <StyledAutocomplete
      id="searchTicketsIdAutocompleteInput"
      options={options ? [...options] : []}
      loading={isLoading}
      onChange={handleChange}
      renderTags={() => null}
      ListboxComponent={ListboxComponent}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          variant="filled"
          label="Ticker ID"
          value={inputValue}
          disabled={inputDisabled}
          onChange={handleChangeInput}
        />
      )}
      renderOption={(props, option) => [props, option] as ReactNode}
      getOptionLabel={option => option.tickerId}
    />
  );
};

export default SearchTickerId;
