import {
  type FC,
  forwardRef,
  type ReactElement,
  useMemo,
  useState,
  type MouseEvent,
  type MouseEventHandler,
  useEffect,
} from 'react';

import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import type { ZIndex } from '@mui/material/styles/zIndex';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

import Button from '../buttons/Button';
import ButtonClose from '../buttons/ButtonClose';
import AgencyComponent from '../card-agency-handle/Agency';

import type { EmptyFunction } from '../../../models';

import { useAvatarLettersUser } from '../../../hooks/use-avatar-letters';

import { Routes } from '../../../constants/routes';
import { useAuth } from '../../../context/auth.context';

import {
  NavigationAgencyWrapper,
  NavigationSwipeableDrawerCloseWrapper,
  NavigationSwipeableDrawerContainer,
} from './styled-components';
import BadgePromo from './BadgePromo';

interface ListItemLinkProps {
  primary: string;
  to: string;
  routeName: string;
  icon?: ReactElement;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

interface NavigationMobileProps {
  profileNickname: string;
  companyName: string;
  email: string;
  imageUrl: string;
  profileClick: EmptyFunction;
  proBadge?: boolean;
  adminBadge?: boolean;
  superAdmin?: boolean;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, isSelected, isDisabled, onClick, routeName } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} role="link" state={{ routeName }} {...itemProps} />;
      }),
    [to, routeName],
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={isSelected}
        onClick={onClick}
        disabled={isDisabled}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const NavigationMobile: FC<NavigationMobileProps> = props => {
  const {
    profileNickname,
    companyName,
    email,
    imageUrl,
    profileClick,
    proBadge,
    adminBadge,
    superAdmin,
  } = props;

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(location.pathname);
  const avatarLetters = useAvatarLettersUser(profileNickname);

  useEffect(() => {
    if (selectedIndex !== location.pathname) {
      setSelectedIndex(location.pathname);
    }
  }, [location.pathname, selectedIndex]);

  const { signout } = useAuth();

  const handleClick = (_event: MouseEvent<HTMLAnchorElement>) => {
    signout({
      email: email,
      username: profileNickname,
    });
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleListItemClick = (_event: MouseEvent<HTMLAnchorElement>, index: string): void => {
    setSelectedIndex(index);
  };

  const handleProfileClick = () => {
    setOpen(false);
    profileClick();
  };

  return (
    <div>
      <Button
        startIcon={location.state?.routeName === 'Dashboard' ? <MenuOutlinedIcon /> : <ArrowBack />}
        variant="text"
        color="inherit"
        size="large"
        id="dashboard-button"
        aria-controls={open ? 'dashboard-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={toggleDrawer(true)}
        sx={{ fontSize: '1.5em', lineHeight: '1.15', paddingLeft: 0 }}
      >
        {location.state?.routeName}
      </Button>

      <NavigationSwipeableDrawerContainer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ zIndex: theme => (theme.zIndex as ZIndex).drawer + 100 }}
      >
        <NavigationSwipeableDrawerCloseWrapper>
          <ButtonClose onClick={toggleDrawer(false)} />
        </NavigationSwipeableDrawerCloseWrapper>

        <Box>
          <ButtonBase disableRipple disableTouchRipple onClick={handleProfileClick}>
            <Avatar
              alt={companyName}
              src={imageUrl}
              sx={{ textTransform: 'uppercase', width: 60, height: 60, marginBottom: '10px' }}
            >
              {avatarLetters}
            </Avatar>
          </ButtonBase>

          <Grid sx={{ margin: '8px 0 18px' }}>
            <Typography variant="h4" noWrap>
              {companyName}

              {proBadge ? <BadgePromo title="Yearly pro" /> : null}
              {adminBadge ? <BadgePromo title="Admin" /> : null}
              {superAdmin ? <BadgePromo title="Super admin" superAdmin /> : null}
            </Typography>
            <Typography variant="body2">{profileNickname}</Typography>
          </Grid>

          <NavigationAgencyWrapper>
            <AgencyComponent
              agencyBoxSmall
              companyName={companyName}
              companyNickname={profileNickname}
              imageUrl={imageUrl}
            />
          </NavigationAgencyWrapper>

          <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
              <ListItemLink
                to={Routes.profile.route}
                primary={Routes.profile.routeName}
                icon={<AccountCircleOutlinedIcon />}
                onClick={event => handleListItemClick(event, Routes.profile.route)}
                isSelected={selectedIndex === Routes.profile.route}
                routeName={Routes.profile.routeName}
              />

              <ListItemLink
                to={Routes.home.route}
                primary={Routes.home.routeName}
                icon={<DashboardCustomizeOutlinedIcon />}
                onClick={event => handleListItemClick(event, Routes.home.route)}
                isSelected={selectedIndex === Routes.home.route}
                routeName={Routes.home.routeName}
              />

              {superAdmin ? null : (
                <>
                  <ListItemLink
                    to={Routes.handles.route}
                    primary={Routes.handles.routeName}
                    icon={<Groups3OutlinedIcon />}
                    onClick={event => handleListItemClick(event, Routes.handles.route)}
                    isSelected={selectedIndex === Routes.handles.route}
                    routeName={Routes.handles.routeName}
                  />

                  <ListItemLink
                    to={Routes.support.route}
                    primary={Routes.support.routeName}
                    icon={<PrivacyTipOutlinedIcon />}
                    onClick={event => handleListItemClick(event, Routes.support.route)}
                    isSelected={selectedIndex === Routes.support.route}
                    routeName={Routes.support.routeName}
                    isDisabled
                  />
                </>
              )}
            </List>
          </Box>
        </Box>

        <Box>
          <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
              {superAdmin ? null : (
                <ListItemLink
                  to={Routes.settings.route}
                  primary={Routes.settings.routeName}
                  icon={<SettingsOutlinedIcon />}
                  onClick={event => handleListItemClick(event, Routes.settings.route)}
                  isSelected={selectedIndex === Routes.settings.route}
                  routeName={Routes.settings.routeName}
                  isDisabled
                />
              )}

              <ListItemLink
                to={Routes.root.route}
                primary="Logout"
                icon={<LogoutOutlinedIcon />}
                onClick={handleClick}
                routeName={Routes.root.routeName}
              />
            </List>
          </Box>

          <Stack spacing={2}>
            {!proBadge ? (
              <Button variant="outlined" startIcon={<LocalPoliceOutlinedIcon />}>
                Upgrade to pro
              </Button>
            ) : null}

            <Button variant="outlined" startIcon={<ConfirmationNumberOutlinedIcon />}>
              Give a promo code
            </Button>
          </Stack>
        </Box>
      </NavigationSwipeableDrawerContainer>
    </div>
  );
};

export default NavigationMobile;
