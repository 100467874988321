import type { FC, SyntheticEvent } from 'react';

import Container from '@mui/system/Container';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import useTabProps from '../../hooks/use-tab-props';
import useHandlesStats from '../../hooks/use-handles-stats';
import { useAuth } from '../../context/auth.context';

import { useQueryCompanyInfoAll, useMutationCompaniesDeleteFromTable } from '../../queries/company';
import {
  useQueryUsers,
  useMutationUsersDeleteFromTable,
  useMutationUsersUpdateFromTable,
  useMutationUpdateUser,
} from '../../queries/users';
import { useQueryHandlesWithoutTwitsByEntityId } from '../../queries/tweets';

import TabPanel from '../../components/common/tabs/TabPanelComponent';
import ErrorMessage from '../../components/common/error-message/ErrorMessage';
import InProgress from '../../components/common/in-progress/InProgress';
import ButtonDelete from '../../components/common/buttons/ButtonDelete';
import ButtonAdmin from '../../components/common/buttons/ButtonAdmin';

import DialogAdmin from '../../components/dialogs/admin/DialogAdmin';
import DialogDelete from '../../components/dialogs/delete/DialogDelete';
import DialogPreviewAgencyProfile from '../../components/dialogs/preview-profile/DialogPreviewAgencyProfile';
import DialogEditTrackNumber from '../../components/dialogs/edit-track-number/DialogEditTrackNumber';

import theme from '../../theme';
import {
  CustomTabsSecondary,
  HomePageBasicContainer,
  PaperContainer,
  TitleContainer,
} from '../styled-components';

import useAdminUsers from './use-admin-users';
import useAdminPageState from './use-admin-page-state';
import SuperAdminTabUser from './SuperAdminTabUser';
import SuperAdminTabCompany from './SuperAdminTabCompany';
import { ActionStackContainer } from './styled-components';
import SuperAdminPageProvider from './SuperAdminPageProvider';
import useHandleTrackAmount from './use-handle-track-amount';

const SuperAdminPage: FC = () => {
  const { authInfo } = useAuth();
  const {
    data: dataUsers,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    refetch: refetchUsers,
  } = useQueryUsers(authInfo.jwt);
  const {
    data: dataCompanies,
    isLoading: isLoadingCompanies,
    isError: isErrorCompanies,
    refetch: refetchCompanies,
  } = useQueryCompanyInfoAll(authInfo.jwt);
  const smallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const tabProps = useTabProps(2);
  const deleteCompanies = useMutationCompaniesDeleteFromTable(authInfo.jwt);
  const deleteUsers = useMutationUsersDeleteFromTable(authInfo.jwt);
  const updateUsersFromTable = useMutationUsersUpdateFromTable(authInfo.jwt);
  const updateUser = useMutationUpdateUser(authInfo.jwt);

  const {
    state,
    setSelectedUsersRows,
    setSelectedCompaniesRows,
    setCurrentTab,
    setShowDeleteUsersDialog,
    setShowDeleteCompaniesDialog,
    setShowAdminDialog,
    setClickRow,
    setShowEditTrackNumberDialog,
    setTrackAmount,
    setRowData,
  } = useAdminPageState();

  const handleTrackAmount = useHandleTrackAmount(
    authInfo.jwt,
    state.rowData,
    setShowEditTrackNumberDialog,
  );

  const handlesWithoutTwits = useQueryHandlesWithoutTwitsByEntityId(
    state.clickRow?.id,
    authInfo.jwt,
  );
  const handlesStats = useHandlesStats(handlesWithoutTwits.data || []);

  const { isAllAdmins, isAllNonAdmins, isMixedAdmins } = useAdminUsers(
    dataUsers || [],
    state.selectedUsersRows,
  );

  const handleChangeCustomTabs = (_event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setSelectedUsersRows([]);
    setSelectedCompaniesRows([]);
  };

  const onOpenAdminDialog = (): void => {
    setShowAdminDialog(true);
  };

  const onCloseAdminDialog = (): void => {
    setShowAdminDialog(false);
  };

  const onOpenDialogDeleteCompanies = (): void => {
    setShowDeleteCompaniesDialog(true);
  };

  const onCloseDialogDeleteCompanies = (): void => {
    setShowDeleteCompaniesDialog(false);
  };

  const onOpenDialogDeleteUsers = (): void => {
    setShowDeleteUsersDialog(true);
  };

  const onCloseDialogDeleteUsers = (): void => {
    setShowDeleteUsersDialog(false);
  };

  const handleConfirmDialogDeleteUsers = (): void => {
    if (state.clickRow?.id) {
      deleteUsers.mutate([state.clickRow.id], {
        onSuccess: () => {
          setSelectedUsersRows([]);
          setShowDeleteUsersDialog(false);
          setClickRow(null);
        },
      });
    } else {
      deleteUsers.mutate(state.selectedUsersRows, {
        onSuccess: () => {
          setSelectedUsersRows([]);
          setShowDeleteUsersDialog(false);
        },
      });
    }
  };

  const handleConfirmDialogDeleteCompanies = (): void => {
    if (state.clickRow?.companyId) {
      deleteCompanies.mutate([state.clickRow?.companyId], {
        onSuccess: () => {
          setSelectedCompaniesRows([]);
          setShowDeleteCompaniesDialog(false);
          setClickRow(null);
        },
      });
    } else {
      deleteCompanies.mutate(state.selectedCompaniesRows, {
        onSuccess: () => {
          setSelectedCompaniesRows([]);
          setShowDeleteCompaniesDialog(false);
          setClickRow(null);
        },
      });
    }
  };

  const handleConfirmDialogAdminUpdateUsers = (): void => {
    updateUsersFromTable.mutate(
      {
        selectedRows: state.selectedUsersRows,
        payload: { authorities: isAllAdmins ? ['ROLE_USER'] : ['ROLE_ADMIN'] },
      },
      {
        onSuccess: () => {
          setSelectedUsersRows([]);
          setShowAdminDialog(false);
        },
      },
    );
  };

  if (deleteCompanies.isLoading || deleteUsers.isLoading || updateUsersFromTable.isLoading) {
    return (
      <InProgress
        isOpen={
          deleteCompanies.isLoading || deleteUsers.isLoading || updateUsersFromTable.isLoading
        }
      />
    );
  }

  return (
    <SuperAdminPageProvider
      onClickRow={setClickRow}
      onShowEditTrackNumberDialog={setShowEditTrackNumberDialog}
      onChangeTrackAmount={setTrackAmount}
      onRowData={setRowData}
    >
      <HomePageBasicContainer>
        <Container maxWidth="lg">
          <PaperContainer>
            <TitleContainer>
              <ActionStackContainer direction="row" spacing={{ xs: 2, md: 3 }}>
                <Typography variant="h4">
                  {state.currentTab === 0 ? 'Platform users' : 'Platform companies'}
                </Typography>

                {state.selectedUsersRows.length ? (
                  <>
                    {isAllNonAdmins || isAllAdmins ? (
                      <ButtonAdmin onClick={onOpenAdminDialog} />
                    ) : null}

                    {isMixedAdmins ? (
                      <Tooltip
                        title="Please, select users with the same type (admin or default)"
                        arrow
                      >
                        <span>
                          <ButtonAdmin disabled={isMixedAdmins} />
                        </span>
                      </Tooltip>
                    ) : null}

                    <ButtonDelete onClick={onOpenDialogDeleteUsers} />
                  </>
                ) : null}

                {state.showDeleteUsersDialog ? (
                  <DialogDelete
                    title="Do you want to delete the user(s)?"
                    onClose={onCloseDialogDeleteUsers}
                    onConfirm={handleConfirmDialogDeleteUsers}
                    isLoading={deleteUsers.isLoading}
                    error={deleteUsers.error?.message || ''}
                  />
                ) : null}

                {state.selectedCompaniesRows.length ? (
                  <ButtonDelete onClick={onOpenDialogDeleteCompanies} />
                ) : null}

                {state.showDeleteCompaniesDialog ? (
                  <DialogDelete
                    title="Do you want to delete the company(es)?"
                    onClose={onCloseDialogDeleteCompanies}
                    onConfirm={handleConfirmDialogDeleteCompanies}
                    isLoading={deleteCompanies.isLoading}
                    error={deleteCompanies.error?.message || ''}
                  />
                ) : null}

                {state.showAdminDialog ? (
                  <DialogAdmin
                    title={
                      isAllAdmins
                        ? 'Do you want to make user(s) non-admin?'
                        : 'Do you want to make user(s) admin?'
                    }
                    buttonText={isAllAdmins ? 'Yes, make non-admin now' : 'Yes, make admin now'}
                    onClose={onCloseAdminDialog}
                    onConfirm={handleConfirmDialogAdminUpdateUsers}
                    isLoading={updateUsersFromTable.isLoading}
                    error={updateUsersFromTable.error?.message || ''}
                  />
                ) : null}

                {(state.clickRow?.id || state.clickRow?.companyId) &&
                !handlesWithoutTwits.isLoading ? (
                  <DialogPreviewAgencyProfile
                    selectedRow={state.clickRow}
                    onClose={() => {
                      setClickRow(null);
                    }}
                    onConfirmDelete={
                      state.clickRow.accountType === 'COMPANY'
                        ? onOpenDialogDeleteCompanies
                        : onOpenDialogDeleteUsers
                    }
                    stats={handlesStats}
                  />
                ) : null}

                {state.showEditTrackNumberDialog ? (
                  <DialogEditTrackNumber
                    onClose={() => {
                      setShowEditTrackNumberDialog(false);
                    }}
                    isLoading={updateUser.isLoading}
                    error={updateUser.error?.message || ''}
                    trackNumber={state.trackAmount}
                    onConfirm={handleTrackAmount}
                  />
                ) : null}

                <CustomTabsSecondary
                  variant={smallScreens ? 'fullWidth' : 'standard'}
                  value={state.currentTab}
                  onChange={handleChangeCustomTabs}
                  aria-label="custom tabs"
                >
                  <Tab label="Users" {...tabProps[0]} />
                  <Tab label="Companies" {...tabProps[1]} />
                </CustomTabsSecondary>
              </ActionStackContainer>
            </TitleContainer>

            <ErrorMessage>
              {deleteUsers.error?.message ||
                deleteCompanies.error?.message ||
                updateUsersFromTable.error?.message}
            </ErrorMessage>

            <TabPanel value={state.currentTab} index={0}>
              <SuperAdminTabUser
                setSelectedRows={setSelectedUsersRows}
                data={dataUsers}
                dataCompanies={dataCompanies}
                isLoading={isLoadingUsers}
                isError={isErrorUsers}
                refetch={refetchUsers}
              />
            </TabPanel>

            <TabPanel value={state.currentTab} index={1}>
              <SuperAdminTabCompany
                setSelectedRows={setSelectedCompaniesRows}
                data={dataCompanies}
                isLoading={isLoadingCompanies}
                isError={isErrorCompanies}
                refetch={refetchCompanies}
              />
            </TabPanel>
          </PaperContainer>
        </Container>
      </HomePageBasicContainer>
    </SuperAdminPageProvider>
  );
};

export default SuperAdminPage;
