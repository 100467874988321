import type { FC } from 'react';

import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';

import { PromoContainer } from './styled-components';

interface BadgePromoProps {
  title: string;
  superAdmin?: boolean;
}

const BadgePromo: FC<BadgePromoProps> = props => {
  const { title, superAdmin } = props;

  return (
    <PromoContainer>
      {superAdmin ? <BoltOutlinedIcon /> : <LocalPoliceOutlinedIcon sx={{ fontSize: '1em' }} />}
      {title}
    </PromoContainer>
  );
};

export default BadgePromo;
