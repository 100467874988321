import { type FC, useRef, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Box from '@mui/system/Box';
import Backdrop from '@mui/material/Backdrop';

import TopBar from './TopBar';
import NavigationPanel from './NavigationPanel';

import type {
  FunctionWithArg,
  Handle,
  EmptyFunction,
  PaginatedResponseData,
  SearchItem,
} from '../../../models';

import { Routes } from '../../../constants/routes';
import Settings from '../../../constants/settings';

import useAuthInfoLocalStorage from '../../../hooks/use-auth-info-local-storage';

import { useInfiniteFulltextSearch } from '../../../queries/tweets';

interface NavbarProps {
  profileNickname: string;
  companyName: string;
  entityId: string;
  email: string;
  imageUrl: string | null;
  handles: Array<Handle>;
  onRefetch: EmptyFunction;
  disableAddNewHandlesButton?: boolean;
  proBadge?: boolean;
  adminBadge?: boolean;
  superAdmin?: boolean;
  onOpen?: FunctionWithArg<boolean>;
}

const Navbar: FC<NavbarProps> = props => {
  const {
    profileNickname,
    companyName,
    entityId,
    email,
    imageUrl,
    proBadge,
    adminBadge,
    superAdmin,
    handles,
    onRefetch,
    disableAddNewHandlesButton,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [datetime, setDatetime] = useState<number | undefined>();
  const [_filterType, setFilterType] = useState<Array<string>>([]);
  const { authInfo } = useAuthInfoLocalStorage();

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteFulltextSearch({
    pageNumberInitial: Settings.DEFAULT_PAGE_NUMBER,
    jwt: authInfo.jwt,
    keyword: searchQuery,
    timestamp: datetime,
    adminSearch: adminBadge || superAdmin || false,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const anchorElRef = useRef(null);

  const handleProfileClick = () => {
    navigate(Routes.profile.route, {
      state: {
        from: location,
        routeName: Routes.profile.routeName,
      },
    });
  };

  const dashboardClick = () => {
    navigate(Routes.home.route, {
      state: {
        from: location,
        routeName: Routes.home.routeName,
      },
    });
  };

  const handleCloseSearch = (): void => {
    setSearchQuery('');
    setDatetime(undefined);
  };

  const handleBackdropClick = (): void => {
    setIsOpen(false);
    setSearchQuery('');
    setDatetime(undefined);
  };

  return (
    <div ref={anchorElRef}>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
          height: '66px',
        }}
      >
        <TopBar
          profileNickname={profileNickname}
          imageUrl={imageUrl || ''}
          profileClick={handleProfileClick}
          dashboardClick={dashboardClick}
          companyName={companyName}
          anchorElRef={anchorElRef}
          options={data || ({} as PaginatedResponseData<SearchItem>)}
          proBadge={proBadge}
          adminBadge={adminBadge}
          superAdminBadge={superAdmin}
          isOpen={isOpen}
          onOpen={setIsOpen}
          onSearch={setSearchQuery}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          onChangeDateRange={setDatetime}
          dateRange={datetime}
          onChangeFilter={setFilterType}
          handles={handles}
        />
      </Box>

      <Box sx={{ height: { xs: '60px', md: '50px' } }}>
        <NavigationPanel
          profileNickname={profileNickname}
          profileClick={handleProfileClick}
          proBadge={proBadge}
          adminBadge={adminBadge}
          superAdmin={superAdmin}
          email={email}
          imageUrl={imageUrl || ''}
          companyName={companyName}
          entityId={entityId}
          handles={handles}
          disableAddNewHandlesButton={disableAddNewHandlesButton}
          onRefetch={onRefetch}
          onSearch={setSearchQuery}
          options={data || ({} as PaginatedResponseData<SearchItem>)}
          jwt={authInfo.jwt}
          onCloseSearch={handleCloseSearch}
          onChangeDateRange={setDatetime}
          dateRange={datetime}
          onChangeFilter={setFilterType}
        />
      </Box>

      <Backdrop
        open={isOpen}
        sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
        onClick={handleBackdropClick}
      />
    </div>
  );
};

export default Navbar;
