import type { FC } from 'react';

import Container from '@mui/system/Container';
import Typography from '@mui/material/Typography';

import { HomePageBasicContainer } from '../styled-components';

const SettingsPage: FC = () => {
  return (
    <HomePageBasicContainer>
      <Container maxWidth="lg">
        <Typography variant="h4">SettingsPage page will be here soon</Typography>
      </Container>
    </HomePageBasicContainer>
  );
};

export default SettingsPage;
